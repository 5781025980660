import { Asserter } from '../common/Asserter';
import { Event } from './Event';
import { IEvent } from './IEvent';
import { IModel } from './IModel';
import { IObservableCollection } from './IObservableCollection';


class ObservableCollection<TItem extends IModel> implements IObservableCollection<TItem>
{
	constructor(items: Readonly<TItem[]>)
	{
		this._onItemChanged = this._onItemChanged.bind(this);

		this._items = [...items];

		this._items.forEach(i => i.onChanged.subscribe(this._onItemChanged));
	}

	get onChanged(): IEvent<this>
	{
		return this._onChanged;
	}

	get onAdded(): IEvent<this, TItem>
	{
		return this._onAdded;
	}

	get onRemoved(): IEvent<this, TItem>
	{
		return this._onRemoved;
	}

	get items(): Readonly<TItem[]>
	{
		return this._items;
	}

	add(item: TItem): void
	{
		this._add(item);
		this._notifyChanged();
	}

	remove(item: TItem): void
	{
		this._remove(item);
		this._notifyChanged();
	}

	/**
	 * Wenn man diese Methode verwendet, sollte man sich darüber im Klaren sein,
	 * dass für jedes Item ein onRemoved-Event getriggert wird.
	 */
	clear(): void
	{
		this._clear();
		this._notifyChanged();
	}

	reset(items: Readonly<TItem[]>): void
	{
		this._clear();
		items.forEach(item => this._add(item));
		this._notifyChanged();
	}

	private _add(item: TItem): void
	{
		item.onChanged.subscribe(this._onItemChanged);
		this._items.push(item);

		this._notifyAdded(item);
	}

	private _remove(item: TItem): void
	{
		const idx = this._items.indexOf(item);
		Asserter.assert(idx !== -1, 'unknown item');
		this._items.splice(idx, 1);
		item.onChanged.unsubscribe(this._onItemChanged);

		this._notifyRemoved(item);
	}

	private _clear(): void
	{
		// Achtung, hier müssen wir auf eine Kopie operieren, weil _remove() this._items verändert.
		[...this._items].forEach(item => this._remove(item));
	}

	private _notifyAdded(item: TItem): void
	{
		this._onAdded.notify(this, item);
	}

	private _notifyRemoved(item: TItem): void
	{
		this._onRemoved.notify(this, item);
	}

	private _onItemChanged(item: IModel): void
	{
		this._notifyChanged();
	}

	private _notifyChanged(): void
	{
		this._onChanged.notify(this, undefined);
	}

	private readonly _items: TItem[];
	private readonly _onChanged = new Event<this>();
	private readonly _onAdded = new Event<this, TItem>();
	private readonly _onRemoved = new Event<this, TItem>();
}


export { ObservableCollection };
