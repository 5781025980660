import { PureComponent, ReactNode } from 'react';
import Container from 'react-bootstrap/Container';
import { Navigate } from 'react-router-dom';
import { Asserter } from '../common/Asserter';
import { FetchGuard } from '../common/FetchGuard';
import { IBackend } from '../common/IBackend';
import { Nullable } from '../common/Optional';
import { RouterData, withRouter } from '../common/withRouter';
import { BusyWithModel } from '../components/Busy';
import { RegistrationView } from '../components/RegistrationView';
import { IRegistrationModel } from '../models/IRegistrationModel';
import { RegistrationModel } from '../models/RegistrationModel';


type ConnectedProps = {
	backend: IBackend;
	router: RouterData;
};


type ConnectedState = {
	registration: Nullable<IRegistrationModel>;
	notFound: boolean;
};


/**
 * Sucht anhand der Router-Informationen die gewünschte Meldung raus und rendert sie.
 */
class RegistrationDetailsRoute extends PureComponent<ConnectedProps, ConnectedState>
{
	constructor(props: ConnectedProps)
	{
		super(props);

		this.state = {
			registration: null,
			notFound: false
		};

		this._renderContent = this._renderContent.bind(this);
	}

	render(): ReactNode
	{
		return <BusyWithModel isBusy={this._fetchGuard.isBusy} render={this._renderContent} className="my-4" />;
	}

	async componentDidMount(): Promise<void>
	{
		return this._fetchGuard.fetch(() => this._fetchRegistration());
	}

	private async _fetchRegistration(): Promise<void>
	{
		Asserter.assert(this.props.router.params.id !== undefined, 'missing router param for registrationId');

		const regId = this.props.router.params.id;
		const params = new URLSearchParams(this.props.router.location.search);
		const email = params.get('email');
		// const lang = _determineLang(regId);

		let registration: IRegistrationModel;
		try
		{
			registration = await RegistrationModel.loader.load(this.props.backend.registrations, regId, email);

			// Brauch wir hier bisher noch nicht. Daher vermeiden wir an dieser Stelle das Fetchen der Übersetzungen.
			// await Translator.instance().changeLanguage(lang, 'RegistrationForm');
		}
		catch (e)
		{
			this.setState({ notFound: true });
			return;
		}

		this.setState({ registration });
	}

	private _renderContent(): ReactNode
	{
		Asserter.assert(!this._isBusy, 'must not be called when busy');

		if (this.state.notFound)
			return <Navigate to="/not-found" replace />;

		// Zugriff auf zusätzlichen (optionalen) State, den man beim Link oder Redirect mitgeben kann.
		// Reichen wir an den RegistrationView weiter.
		// state ist null, wenn nicht von außen gesetzt.
		const info = this.props.router.location.state as Nullable<string>;

		return (
			<Container fluid="md" className="my-4">
				<RegistrationView registration={this.state.registration!} info={info ?? undefined} />
			</Container>
		);
	}

	private get _isBusy(): boolean
	{
		return this.state.registration === null && this.state.notFound === false;
	}

	private readonly  _fetchGuard = new FetchGuard();
}


/**
 * Wir haben an dieser Stelle noch keinen Zugriff auf die Meldung selbst.
 * Daher müssen wir die Sprache anhand der Meldungs-Id ermitteln.
 */
// function _determineLang(regId: string): 'de' | 'en'
// {
// 	if (regId.substring(0, 3) === 'GER')
// 		return 'de';
// 	else
// 		return 'en';
// }


const RegistrationDetailsRouteWithRouter = withRouter(RegistrationDetailsRoute);


export { RegistrationDetailsRouteWithRouter as RegistrationDetailsRoute };
