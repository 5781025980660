import { PropsWithChildren, PureComponent, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { AppUserModel, UserRole } from '../models/AppUserModel';
import { moveSubscription } from '../models/IEvent';


type Props = PropsWithChildren<{
	appUser: AppUserModel;
	role?: UserRole;
}>;


/**
 * Ein Wrapper für Components, die nur angezeigt werden drüfen, wenn der User
 * authentifiziert ist. Die zu schützenden Components müssen als Child-Components
 * angegeben werden.
 *
 * Inspiriert durch: https://github.com/remix-run/react-router/blob/main/examples/auth
 */
class RequireAuth extends PureComponent<Props>
{
	constructor(props: Props)
	{
		super(props);

		this._onUserChanged = this._onUserChanged.bind(this);
	}

	render(): ReactNode
	{
		if (!this.props.appUser.isLoggedIn)
			return <Navigate to='/login' replace />;

		if (this.props.role !== undefined && !this.props.appUser.hasRole(this.props.role))
			return <Navigate to='/profile' replace />;

		return this.props.children;
	}

	componentDidMount(): void
	{
		this.props.appUser.onChanged.subscribe(this._onUserChanged);
	}

	componentWillUnmount(): void
	{
		this.props.appUser.onChanged.unsubscribe(this._onUserChanged);
	}

	componentDidUpdate(prevProps: Props): void
	{
		moveSubscription(prevProps.appUser.onChanged, this.props.appUser.onChanged, this._onUserChanged);
	}

	private _onUserChanged(appUser: AppUserModel): void
	{
		this.forceUpdate();
	}
}


export { RequireAuth };
