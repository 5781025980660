import { Event } from './Event';
import { IEvent } from './IEvent';
import { IFlagModel } from './IFlagModel';


class FlagModel implements IFlagModel
{
	constructor(value: boolean)
	{
		this._value = value;
	}

	get onChanged(): IEvent<this>
	{
		return this._onChanged;
	}

	get value()
	{
		return this._value;
	}

	setValue(value: boolean)
	{
		if (value === this._value)
			return;

		this._value = value;
		this._onChanged.notify(this, undefined);
	}

	toggle()
	{
		this.setValue(!this.value);
	}

	private _value: boolean;
	private _onChanged = new Event<this>();
}


export { FlagModel };
