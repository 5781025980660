import { PureComponent, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { Asserter } from '../common/Asserter';
import { ICampRegistrationClient } from '../common/CampRegistrationClient';
import { FetchGuard } from '../common/FetchGuard';
import { Nullable } from '../common/Optional';
import { TR, Translator } from '../common/Translator';
import { RouterData, withRouter } from '../common/withRouter';
import { BusyWithModel } from '../components/Busy';


type PropsWithRouter = {
	client: ICampRegistrationClient;
	router: RouterData;
};


type StateWithProps = {
	confirmed: Nullable<boolean>;
	failure: Nullable<string>;
};


/**
 * Führt die Bestätigung der Meldung durch und leitet dann auf die
 * RegistrationDetails um mit entsprechendem Info-Text.
 */
class CampRegistrationConfirmation extends PureComponent<PropsWithRouter, StateWithProps>
{
	constructor(props: PropsWithRouter)
	{
		super(props);

		this.state = {
			confirmed: null,
			failure: null
		};

		this._renderContent = this._renderContent.bind(this);
	}

	render(): ReactNode
	{
		Asserter.assert(this.props.router.params.id !== undefined, 'missing router param for registrationId');

		const email = this._email;
		if (email === null)
			return <Navigate to="/not-found" replace />;

		return <BusyWithModel isBusy={this._fetchGuard.isBusy} render={this._renderContent} className="my-4" />;
	}

	componentDidMount(): Promise<void>
	{
		return this._fetchGuard.fetch(() => this._postConfirmation());
	}

	private async _postConfirmation(): Promise<void>
	{
		const email = this._email;
		if (email === null)
			return;

		const regId = this.props.router.params.id!;
		const lang = _determineLang(regId);

		let confirmed: boolean;
		try
		{
			// Bei netcup ist die Performance bei paralleler Ausführung manchmal schlecht, daher sequentiell.
			confirmed = await this.props.client.confirm(regId, email, false);
			await Translator.instance().changeLanguage(lang, 'CampRegistrationForm');
		}
		catch (e)
		{
			this.setState({ failure: 'failed to confirm' });
			return;
		}

		this.setState({ confirmed });
	}

	private _renderContent(): ReactNode
	{
		Asserter.assert(!this._isBusy, 'must not be called when busy');

		if (this.state.failure !== null)
			return <Navigate to="/not-found" replace />;

		let confirmationText: string;
		if (this.state.confirmed)
			confirmationText = TR('CampRegistrationForm::Meldung bestätigt');
		else
			confirmationText = TR('CampRegistrationForm::Meldung bereits bestätigt');

		return (
			<Navigate
				to={{
					pathname: `/camp-registration/${this.props.router.params.id!}`,
					search: `?email=${this._email!}`,
				}}
				state={confirmationText}
			/>
		);
	}

	private get _isBusy(): boolean
	{
		return this.state.confirmed === null && this.state.failure === null;
	}

	private get _email(): Nullable<string>
	{
		const params = new URLSearchParams(this.props.router.location.search);
		return params.get('email');
	}

	private readonly _fetchGuard = new FetchGuard();
}



/**
 * Wir haben an dieser Stelle noch keinen Zugriff auf die Meldung selbst.
 * Daher müssen wir die Sprache anhand der Meldungs-Id ermitteln.
 */
function _determineLang(regId: string): 'de' | 'en'
{
	if (regId.substring(6, 9) === 'GER')
		return 'de';
	else
		return 'en';
}


const CampRegistrationConfirmationWithRouter = withRouter(CampRegistrationConfirmation);


export { CampRegistrationConfirmationWithRouter as CampRegistrationConfirmationRoute };
