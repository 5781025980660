
/**
 * Generiert temporäre Ids für Registration und CampRegistration.
 * Die brauchen wir, solange eine Registration noch nicht persistiert ist.
 */
class RegistrationIdGenerator
{
	nextId(): string
	{
		const nextId = this._id++;
		return _tmpPrefix + nextId.toString().padStart(3, '0');
	}

	static isPermanentId(id: string): boolean
	{
		return id.slice(0, 3) !== _tmpPrefix;
	}

	private _id = 1;
}


const _tmpPrefix = 'XXX';


export { RegistrationIdGenerator };
