import { PropsWithChildren, PureComponent, ReactNode } from 'react';
import { Overwrite } from '../common/Overwrite';
import { moveSubscription } from '../models/IEvent';
import { IFlagModel } from '../models/IFlagModel';


type ButtonType = 'button' | 'submit';


type Props = PropsWithChildren<{
	type?: ButtonType; // default ist 'button'
	className?: string;
	tabIndex?: number;
	isEnabled?: boolean;
	onClicked?: () => void;
	onFocus?: () => void;
	onBlur?: () => void;
}>;


class Button extends PureComponent<Props>
{
	render(): ReactNode
	{
		const type = this.props.type ?? 'button';
		const isDisabled = this.props.isEnabled === false ? true : undefined;

		return (
			<button
				type={type}
				onClick={this.props.onClicked}
				onFocus={this.props.onFocus}
				onBlur={this.props.onBlur}
				className={this.props.className}
				tabIndex={this.props.tabIndex}
				disabled={isDisabled}
			>
				{this.props.children}
			</button>
		);
	}
}


//==============================================================================


type PropsWithModel = Overwrite<Props, {
	isEnabled?: IFlagModel;
}>;


class ButtonWithModel extends PureComponent<PropsWithModel>
{
	constructor(props: PropsWithModel)
	{
		super(props);

		this._onIsEnabledChanged = this._onIsEnabledChanged.bind(this);
	}

	render(): ReactNode
	{
		const isEnabled = this.props.isEnabled?.value;
		return <Button {...this.props} isEnabled={isEnabled} />;
	}

	componentDidMount(): void
	{
		this.props.isEnabled?.onChanged.subscribe(this._onIsEnabledChanged);
	}

	componentWillUnmount(): void
	{
		this.props.isEnabled?.onChanged.unsubscribe(this._onIsEnabledChanged);
	}

	componentDidUpdate(prevProps: PropsWithModel): void
	{
		moveSubscription(prevProps.isEnabled?.onChanged, this.props.isEnabled?.onChanged, this._onIsEnabledChanged);
	}

	private _onIsEnabledChanged(flagModel: IFlagModel): void
	{
		this.forceUpdate();
	}
}


export { Button, ButtonWithModel };
