import { PureComponent, ReactNode } from 'react';
import { Asserter } from '../common/Asserter';
import { FetchGuard } from '../common/FetchGuard';
import { Nullable } from '../common/Optional';
import { IRegistrationClient } from '../common/RegistrationClient';
import { BusyWithModel } from '../components/Busy';
import { RegistrationsOverview } from '../components/RegistrationsOverview';
import { IRegistrationsModel } from '../models/IRegistrationsModel';
import { RegistrationsModel } from '../models/RegistrationsModel';


type Props = {
	client: IRegistrationClient;
};


type State = {
	registrations: Nullable<IRegistrationsModel>;
};


class RegistrationsRoute extends PureComponent<Props, State>
{
	constructor(props: Props)
	{
		super(props);

		this.state = {
			registrations: null
		};

		this._renderContent = this._renderContent.bind(this);
	}

	render(): ReactNode
	{
		return <BusyWithModel isBusy={this._fetchGuard.isBusy} render={this._renderContent} className="my-4" />;
	}

	componentDidMount(): Promise<void>
	{
		return this._fetchGuard.fetch(() => this._fetchRegistrations());
	}

	private _renderContent(): ReactNode
	{
		Asserter.assert(this.state.registrations !== null, 'must not be called when busy');

		return <RegistrationsOverview registrations={this.state.registrations} client={this.props.client} />;
	}

	private async _fetchRegistrations(): Promise<void>
	{
		const registrations = await RegistrationsModel.loader.load(this.props.client);
		this.setState({ registrations });
	}

	private readonly _fetchGuard = new FetchGuard();
}


export { RegistrationsRoute };
