
import { Asserter } from './Asserter';


/**
 * Eine bijektive Abbildung.
 * Das wird z.B. gebraucht, um Werte eines Model in Werte für die Anzeige zu mappen und zurück.
 */
class OneToOneMapping<K, V>
{
	constructor(values?: [K, V][])
	{
		for (const [key, value] of values ?? [])
			this.define(key, value);
	}

	toTarget(source: K): V
	{
		const target = this._toTarget.get(source);
		Asserter.assert(target !== undefined, 'unknown source');
		return target;
	}

	toSource(target: V): K
	{
		const source = this._toSource.get(target);
		Asserter.assert(source !== undefined, 'unknown target');
		return source;
	}

	define(source: K, target: V)
	{
		Asserter.assert(!this._toTarget.has(source), 'mapping must be bijective');
		Asserter.assert(!this._toSource.has(target), 'mapping must be bijective');

		this._toTarget.set(source, target);
		this._toSource.set(target, source);
	}

	private _toTarget: Map<K, V> = new Map<K, V>();
	private _toSource: Map<V, K> = new Map<V, K>();
}


export { OneToOneMapping };
