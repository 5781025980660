import { PureComponent, ReactNode } from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';


class ImpressumRoute extends PureComponent
{
	public render(): ReactNode
	{
		return (
			<Container fluid="lg" className="my-4">
				<Card border="secondary">
					<Card.Header>
						Impressum
					</Card.Header>
					<Card.Body>
					<p>
						Bremer Judo-Club e. V.<br />
						Norbert Specker<br />
						Parkallee 89<br />
						28209 Bremen<br />
						Germany<br />
						Telefon/Fax: +49-421-1650778<br />
						E-Mail: <a href="mailto:norbert.specker@specker-bremen.de">norbert.specker@specker-bremen.de</a>
					</p>
					<p>
						Inhaltlich verantwortlich gemäß §10, Absatz 3 MDStV: Norbert Specker<br />
						Registergericht: Amtsgericht Bremen<br />
						Vereinsregisternummer: VR2202
					</p>
					</Card.Body>
				</Card>
			</Container>
		);
	}
}


export { ImpressumRoute };
