import { PureComponent, ReactNode } from 'react';
import { ConfigForm } from '../components/ConfigForm';
import { ConfigModel } from '../models/ConfigModel';


type Props = {
	config: ConfigModel;
};


class ConfigRoute extends PureComponent<Props>
{
	render(): ReactNode
	{
		return <ConfigForm config={this.props.config} />;
	}
}


export { ConfigRoute };
