import { PureComponent, ReactNode } from 'react';
import { Optional } from '../common/Optional';
import { dateToIsoTz } from '../common/utils';
import { DateTimeModel } from '../models/DateTimeModel';
import { moveSubscription } from '../models/IEvent';
import { ValidatedLineEdit } from './ValidatedLineEdit';


type Props = {
	model: DateTimeModel;
	isEnabled?: boolean;
};


class DateTimeEditWithModel extends PureComponent<Props>
{
	constructor(props: Props)
	{
		super(props);

		this._onModelChanged = this._onModelChanged.bind(this);
		this._onUiChanged = this._onUiChanged.bind(this);
	}

	render(): ReactNode
	{
		return (
			<ValidatedLineEdit
				type="datetime-local"
				value={_dateToString(this.props.model.value)}
				isEnabled={this.props.isEnabled}
				isValid={this.props.model.isValid()}
				onChanged={this._onUiChanged}
			/>
		);
	}

	componentDidMount(): void
	{
		this.props.model.onChanged.subscribe(this._onModelChanged);
	}

	componentWillUnmount(): void
	{
		this.props.model.onChanged.unsubscribe(this._onModelChanged);
	}

	componentDidUpdate(prevProps: Props): void
	{
		moveSubscription(prevProps.model.onChanged, this.props.model.onChanged, this._onModelChanged);
	}

	private _onModelChanged(model: DateTimeModel): void
	{
		this.forceUpdate();
	}

	private _onUiChanged(value: string): void
	{
		this.props.model.setValue(_stringToDate(value));
	}
}


/**
 * Der String kommt vom input-Element und enthält *keine* Zeitzoneninformation.
 * Aus Browsersicht soll das halt immer die Zeitzone des Users sein.
 * Wir erzeugen daraus direkt die Date-Instanz. Die enthält dann auch die lokale Zeitzone.
 */
function _stringToDate(value: string): Optional<Date>
{
	const time = Date.parse(value); // ms seit Epoch
	if (Number.isNaN(time))
		return undefined;

	return new Date(time);
}

function _dateToString(date: Optional<Date>): string
{
	if (date === undefined)
		return '';

	// Hier nicht date.toISOString() verwenden, weil das immer in UTC formatiert.
	// Wir brauchen hier die lokale Zeitzone.
	return dateToIsoTz(date).slice(0, 16);
}


export { DateTimeEditWithModel };
