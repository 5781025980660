import { PureComponent, ReactNode } from 'react';
import { moveSubscription } from '../models/IEvent';
import { ITextModel } from '../models/ITextModel';


type Props = {
	label: string;
	className?: string;
};


/**
 * Rendert ein Label (Text) innerhalb eines Divs.
 * Das className-Attribute des Labels wird an das gerenderte Div weitergegeben.
 */
class Label extends PureComponent<Props>
{
	render(): ReactNode
	{
		let className = 'text-nowrap';
		if (this.props.className !== undefined)
			className += ` ${this.props.className}`;

		return (
			<div className={className}>
				{this.props.label}
			</div>
		);
	}
}


type PropsWithModel = {
	model: ITextModel;
	className?: string;
}


class LabelWithModel extends PureComponent<PropsWithModel>
{
	constructor(props: PropsWithModel)
	{
		super(props);

		this._onModelChanged = this._onModelChanged.bind(this);
	}

	render(): ReactNode
	{
		return <Label label={this.props.model.text} className={this.props.className} />;
	}

	componentDidMount(): void
	{
		this.props.model.onChanged.subscribe(this._onModelChanged);
	}

	componentWillUnmount(): void
	{
		this.props.model.onChanged.unsubscribe(this._onModelChanged);
	}

	componentDidUpdate(prevProps: PropsWithModel): void
	{
		moveSubscription(prevProps.model.onChanged, this.props.model.onChanged, this._onModelChanged);
	}

	private _onModelChanged(model: ITextModel): void
	{
		this.forceUpdate();
	}
}


export { Label, LabelWithModel };
