import { createRef, PureComponent, ReactNode, RefObject } from 'react';
import { Link } from 'react-router-dom';
import { Nullable } from '../common/Optional';
import { TR, Translator } from '../common/Translator';
import { ShortFormData } from '../data/RegistrationFormData';
import { ComputedFlagModel } from '../models/ComputedFlagModel';
import { IFlagModel } from '../models/IFlagModel';
import { IRegistrationModel } from '../models/IRegistrationModel';
import { IValidatedChoiceModel } from '../models/IValidatedChoiceModel';
import { IValidatedTextModel } from '../models/IValidatedTextModel';
import { MappedValidatedChoiceModel } from '../models/MappedValidatedChoiceModel';
import { ShortFormMapper } from '../models/ShortFormMapper';
import { CheckboxWithModel } from './Checkbox';
import { Hideable, HideableWithModel } from './Hideable';
import { Label } from './Label';
import { RegistrationFormType } from './RegistrationForm';
import { Row } from './Row';
import { ValidatedComboboxWithModel } from './ValidatedCombobox';
import { ValidatedLineEditWithModel } from './ValidatedLineEdit';


class Computed
{
	constructor(registration: IRegistrationModel)
	{
		this._isFederationShown = new ComputedFlagModel<IRegistrationModel>(r => r.isGerman(), registration);
	}

	get isFederationShown(): IFlagModel
	{
		return this._isFederationShown;
	}

	setRegistration(registration: IRegistrationModel)
	{
		this._isFederationShown.setModel(registration);
	}

	private _isFederationShown: ComputedFlagModel<IRegistrationModel>;
}


//==============================================================================


type Props = {
	registration: IRegistrationModel;
	formType: RegistrationFormType;
	withDetails: boolean;
};


/**
 * Der Formularanteil für den Meldenden.
 */
class RegistreeForm extends PureComponent<Props>
{
	constructor(props: Props)
	{
		super(props);

		this._onLanguageChanged = this._onLanguageChanged.bind(this);

		this._focusTarget = createRef<ValidatedLineEditWithModel>();

		const nationMapper = new ShortFormMapper(this.props.registration.nation.choices);
		this._nation = new MappedValidatedChoiceModel(this.props.registration.nation, nationMapper);

		const verbandMapper = new ShortFormMapper(this.props.registration.verband.choices);
		this._verband = new MappedValidatedChoiceModel(this.props.registration.verband, verbandMapper);

		this._computed = new Computed(this.props.registration);
	}

	render()
	{
		const { vereinEnabled, nationEnabled, showHint } = this._derivedProps();

		let hint: ReactNode = null;
		if (showHint)
			hint = (
				<div
					className="callout-danger rounded bg-light p-2 mb-3"
					dangerouslySetInnerHTML={{
						__html: TR('RegistrationForm::Hinweis für Ausländer'),
					}}
				/>
			);

		return (
			<>
				<Hideable isShown={this.props.withDetails}>
					<div className="card border-secondary my-3">
						<div className="card-body">
							<Row className="mb-1">
								<div className="col-md-6">
									{_renderLineEdit(`${TR('RegistrationForm::ID')}:`, this.props.registration.id, false)}
								</div>
							</Row>
							<Row className="mb-1">
								<div className="col-md-6">
									{_renderLineEdit(`${TR('RegistrationForm::Eingegangen')}:`, this.props.registration.eingegangen)}
								</div>
							</Row>
							<Row>
								<div className="col-md-6">
									{_renderCheckbox(`${TR('RegistrationForm::Bestätigt')}:`, this.props.registration.isConfirmed)}
								</div>
							</Row>
							<Row>
								<div className="col-md-6">
									{_renderLink('Link:', this.props.registration)}
								</div>
							</Row>
						</div>
					</div>
				</Hideable>
				<div className='card border-secondary my-3'>
					<div className='card-header'>{TR('RegistrationForm::Wer meldet')}</div>
					<div className='card-body'>
						{hint}

						<Row className="gy-1">
							<div className='col-md-6 order-md-0'>
								{_renderLineEdit(`${TR('RegistrationForm::Vorname')}:`, this.props.registration.vorname, undefined, this._focusTarget)}
							</div>
							<div className='col-md-6 order-md-2' >
								{_renderLineEdit(`${TR('RegistrationForm::Nachname')}:`, this.props.registration.nachname)}
							</div>
							<div className='col-md-6 order-md-4'>
								{_renderLineEdit(`${TR('RegistrationForm::E-Mail')}:`, this.props.registration.email)}
							</div>
							<div className='col-md-6 order-md-1'>
								{_renderLineEdit(`${TR('RegistrationForm::Verein')}:`, this.props.registration.verein, vereinEnabled)}
							</div>
							<div className='col-md-6 order-md-3'>
								{_renderCombobox(`${TR('RegistrationForm::Nation')}:`, this._nation, nationEnabled)}
							</div>
							<div className='col-md-6 order-md-5'>
								<HideableWithModel isShown={this._computed.isFederationShown}>
									{_renderCombobox(`${TR('RegistrationForm::Verband')}:`, this._verband)}
								</HideableWithModel>
							</div>
						</Row>
					</div>
				</div>
			</>
		);
	}

	componentDidMount()
	{
		this._focusTarget.current!.focus();

		Translator.instance().languageChanged.subscribe(this._onLanguageChanged);
	}

	componentWillUnmount()
	{
		Translator.instance().languageChanged.unsubscribe(this._onLanguageChanged);
	}

	componentDidUpdate(prevProps: Props)
	{
		this._computed.setRegistration(this.props.registration);

		// Die Mapper bleiben konstant.
		this._nation.setModels(this.props.registration.nation);
		this._verband.setModels(this.props.registration.verband);
	}

	private _onLanguageChanged(translator: Translator)
	{
		this.forceUpdate();
	}

	/**
	 * Auf Basis des Formular-Typs wird die Information zurückgegeben, ob Verein und Nation enabled sein sollen.
	 */
	private _derivedProps(): _DerivedProps
	{
		return {
			vereinEnabled:
				this.props.formType === 'Admin' ||
				this.props.formType === 'GermanClub' ||
				this.props.formType === 'InternationalClub',
			nationEnabled:
				this.props.formType === 'Admin' ||
				this.props.formType === 'InternationalClub' ||
				this.props.formType === 'InternationalNationalTeam',
			showHint:
				this.props.formType === 'GermanClub' ||
				this.props.formType === 'GermanKader'
		};
	}

	private _focusTarget: RefObject<ValidatedLineEditWithModel>;
	private _nation: MappedValidatedChoiceModel<Nullable<ShortFormData>>;
	private _verband: MappedValidatedChoiceModel<Nullable<ShortFormData>>;
	private _computed: Computed;
}


//==============================================================================


type _DerivedProps = {
	vereinEnabled: boolean;
	nationEnabled: boolean;
	showHint: boolean;
};


//==============================================================================


function _renderLineEdit(label: string, model: IValidatedTextModel, isEnabled?: boolean, ref?: RefObject<ValidatedLineEditWithModel>)
{
	return (
		<Row className="align-items-center">
			<Label label={label} className="col-4" />
			<div className="col">
				<ValidatedLineEditWithModel ref={ref} model={model} isEnabled={isEnabled} />
			</div>
		</Row>
	);
}

function _renderCombobox(label: string, model: IValidatedChoiceModel, enabled?: boolean)
{
	return (
		<Row className="align-items-center">
			<Label label={label} className="col-4" />
			<div className="col">
				<ValidatedComboboxWithModel model={model} isEnabled={enabled} />
			</div>
		</Row>
	);
}

function _renderCheckbox(label: string, model: IFlagModel)
{
	return (
		<Row className="align-items-center">
			<Label label={label} className="col-4" />
			<div className="col">
				<CheckboxWithModel type="switch" label="" isChecked={model} />
			</div>
		</Row>
	);
}

function _renderLink(label: string, registration: IRegistrationModel)
{
	const path = `/registration/${registration.id.text}?email=${registration.email.text}`;
	return (
		<Row className="align-items-center">
			<Label label={label} className="col-4" />
			<div className="col">
				<Link to={path}>{path}</Link>
			</div>
		</Row>
	);
}


export { RegistreeForm };
