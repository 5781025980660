import { PureComponent, ReactNode } from 'react';
import { TR, Translator } from '../common/Translator';
import { ICampRegistrationModel } from '../models/ICampRegistrationModel';
import { ButtonWithModel } from './Button';
import { CampBetreuerForm } from './CampBetreuerForm';
import { CampCompetitorForm } from './CampCompetitorForm';
import { CampPreFillForm } from './CampPreFillForm';
import { CampRegistreeForm } from './CampRegistreeForm';
import { Hideable } from './Hideable';


type Props = {
	registration: ICampRegistrationModel;
	withPreFill: boolean;
	withDetails: boolean;
	onSubmit?: () => void; // wenn undefined, wird der Submit-Button nicht gerendert
};


class CampRegistrationForm extends PureComponent<Props>
{
	constructor(props: Props)
	{
		super(props);

		this._onLanguageChanged = this._onLanguageChanged.bind(this);
	}

	render(): ReactNode
	{
		return (
			<>
				<Hideable isShown={this.props.withPreFill}>
					<CampPreFillForm registration={this.props.registration} />
				</Hideable>
				<CampRegistreeForm
					registration={this.props.registration}
					withDetails={this.props.withDetails}
				/>
				<CampCompetitorForm registration={this.props.registration} />
				<CampBetreuerForm registration={this.props.registration} />
				<Hideable isShown={this.props.onSubmit !== undefined}>
					<ButtonWithModel
						className="btn btn-secondary rounded-pill"
						isEnabled={this.props.registration.isValid}
						onClicked={this.props.onSubmit}
					>
						{TR('CampRegistrationForm::Abschicken')}
					</ButtonWithModel>
				</Hideable>
			</>
		);
	}

	componentDidMount(): void
	{
		Translator.instance().languageChanged.subscribe(this._onLanguageChanged);
	}

	componentWillUnmount(): void
	{
		Translator.instance().languageChanged.unsubscribe(this._onLanguageChanged);
	}

	private _onLanguageChanged(translator: Translator): void
	{
		this.forceUpdate();
	}
}


export { CampRegistrationForm };
