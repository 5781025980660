import { ChangeEvent, PureComponent, ReactNode } from 'react';
import { moveSubscription } from '../models/IEvent';
import { IFlagModel } from '../models/IFlagModel';


type Props = {
	label: string;
	isChecked: boolean;
	type?: 'checkbox' | 'switch'; // checkbox ist default
	onChanged?: (isChecked: boolean) => void;
	className?: string;
	tabIndex?: number;
	isEnabled?: boolean;
};


class Checkbox extends PureComponent<Props>
{
	constructor(props: Props)
	{
		super(props);

		this._onChanged = this._onChanged.bind(this);
	}

	render(): ReactNode
	{
		const id = Checkbox._nextId();

		let classNames = ['form-check'];
		if (this.props.type === 'switch')
			classNames.push('form-switch');
		if (this.props.className !== undefined)
			classNames.push(this.props.className);

		const role = this.props.type === 'switch' ? 'switch' : undefined;
		const isDisabled = this.props.isEnabled === false ? true : undefined;

		return (
			<div className={classNames.join(' ')}>
				<input
					id={id}
					className="form-check-input"
					type="checkbox"
					role={role}
					checked={this.props.isChecked}
					tabIndex={this.props.tabIndex}
					onChange={this._onChanged}
					disabled={isDisabled}
				/>
				<label className="form-check-label" htmlFor={id}>
					{this.props.label}
				</label>
			</div>
		);
	}

	private _onChanged(e: ChangeEvent<HTMLInputElement>): void
	{
		if (this.props.onChanged === undefined)
			return;
		this.props.onChanged(e.target.checked);
	}

	private static _nextId(): string
	{
		return `_checkbox-${Checkbox._idCounter++}`;
	}

	private static _idCounter = 0;
}


type PropsWithModel = Omit<Props, 'isChecked' | 'isEnabled' | 'onChanged'> & {
	isChecked: IFlagModel;
	isEnabled?: IFlagModel;
};


class CheckboxWithModel extends PureComponent<PropsWithModel>
{
	constructor(props: PropsWithModel)
	{
		super(props);

		this._onModelChanged = this._onModelChanged.bind(this);
		this._onUiChanged = this._onUiChanged.bind(this);
	}

	render(): ReactNode
	{
		return (
			<Checkbox
				{...this.props}
				isChecked={this.props.isChecked.value}
				isEnabled={this.props.isEnabled?.value}
				onChanged={this._onUiChanged}
			/>
		);
	}

	componentDidMount(): void
	{
		this.props.isChecked.onChanged.subscribe(this._onModelChanged);
		this.props.isEnabled?.onChanged.subscribe(this._onModelChanged);
	}

	componentWillUnmount(): void
	{
		this.props.isChecked.onChanged.unsubscribe(this._onModelChanged);
		this.props.isEnabled?.onChanged.unsubscribe(this._onModelChanged);
	}

	componentDidUpdate(prevProps: PropsWithModel): void
	{
		moveSubscription(prevProps.isChecked.onChanged, this.props.isChecked.onChanged, this._onModelChanged);
		moveSubscription(prevProps.isEnabled?.onChanged, this.props.isEnabled?.onChanged, this._onModelChanged);
	}

	private _onModelChanged(flagModel: IFlagModel): void
	{
		this.forceUpdate();
	}

	private _onUiChanged(isChecked: boolean): void
	{
		this.props.isChecked.setValue(isChecked);
	}
}


export { Checkbox, CheckboxWithModel };
