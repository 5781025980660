import { ChangeEvent, createRef, PureComponent, ReactNode, RefObject } from 'react';
import { Optional } from '../common/Optional';
import { FileModel } from '../models/FileModel';
import { moveSubscription } from '../models/IEvent';


type Props = {
	isEnabled?: boolean;
	onChanged: (value: Optional<File>) => void;
};


class FileSelect extends PureComponent<Props>
{
	constructor(props: Props)
	{
		super(props);

		this._onChanged = this._onChanged.bind(this);

		this._inputRef = createRef<HTMLInputElement>();
	}

	render(): ReactNode
	{
		const isDisabled = this.props.isEnabled === false ? true : undefined;

		return (
			<input
				ref={this._inputRef}
				type="file"
				className="form-control"
				onChange={this._onChanged}
				disabled={isDisabled}
			/>
		);
	}

	/**
	 * Setzt eine ggf. vorhandene Selektion im input-Element zurück.
	 */
	reset(): void
	{
		this._inputRef.current!.value = null!;
	}

	private _onChanged(e: ChangeEvent<HTMLInputElement>): void
	{
		const selectedFiles = e.target.files;
		const selectedFile = selectedFiles?.item(0);
		this.props.onChanged(selectedFile!);
	}

	private readonly _inputRef: RefObject<HTMLInputElement>;
}


//==============================================================================


type PropsWithModel = Omit<Props, 'onChanged'> & {
	model: FileModel;
};


/**
 * Ein File-Select mit Model-Anbindung.
 * Die Component hat die Besonderheit, dass sie nur eine Richtung unterstützt:
 * Die Daten fließen nur vom User (dem HTMLInputElement) zum Model, aber nicht umgekehrt.
 * Das liegt daran, dass das HTMLInputElement vom Typ 'file' nur lesbar ist.
 * Siehe dazu auch:
 * https://reactjs.org/docs/uncontrolled-components.html
 *
 * Die Rückrichtung klappt jetzt zumindest in einem Fall: wenn FileModel.file auf undefined gesetzt wird.
 * In diesem Fall wird auch die Selektion im File-Input-Element zurückgesetzt.
 */
class FileSelectWithModel extends PureComponent<PropsWithModel>
{
	constructor(props: PropsWithModel)
	{
		super(props);

		this._onUiChanged = this._onUiChanged.bind(this);
		this._onModelChanged = this._onModelChanged.bind(this);

		this._fileSelectRef = createRef<FileSelect>();
	}

	render(): ReactNode
	{
		return <FileSelect ref={this._fileSelectRef} onChanged={this._onUiChanged} isEnabled />;
	}

	componentDidMount(): void
	{
		this.props.model.onChanged.subscribe(this._onModelChanged);
	}

	componentWillUnmount(): void
	{
		this.props.model.onChanged.unsubscribe(this._onModelChanged);
	}

	componentDidUpdate(prevProps: PropsWithModel): void
	{
		moveSubscription(prevProps.model.onChanged, this.props.model.onChanged, this._onModelChanged);
	}

	private _onUiChanged(file: Optional<File>): void
	{
		this.props.model.setFile(file);
	}

	private _onModelChanged(): void
	{
		if (this.props.model.file === undefined)
			this._fileSelectRef.current!.reset();
	}

	private readonly _fileSelectRef: RefObject<FileSelect>;
}


export { FileSelect, FileSelectWithModel };
