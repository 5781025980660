import { Asserter } from '../common/Asserter';
import { Optional } from '../common/Optional';
import { Event } from './Event';
import { IEvent } from './IEvent';
import { IModel } from './IModel';


/**
 * Ein Model für DateTime.
 * Hat bereits eine isValid()-Methode.
 * Der value ist optional. In dem Fall liefert isValid() false.
 *
 * Denkbar wäre auch ein ValidatingDateTimeModel, welches zusätzlich
 * einen Validator hat, über den man dann z.B. erlaubte Zeitbereiche vorgeben kann.
 */
class DateTimeModel implements IModel
{
	constructor(value: Optional<Date>)
	{
		this._value = value;
	}

	get onChanged(): IEvent<this>
	{
		return this._onChanged;
	}

	isValid()
	{
		return this._value !== undefined;
	}

	get value(): Optional<Date>
	{
		return this._value;
	}

	/**
	 * Dummerweise können Date-Instanzen auch invalid sein.
	 * Die erkennt man daran, das valueOf() NaN liefert.
	 * Invalide Date-Instanzen sind hier nicht erlaubt.
	 */
	setValue(value: Optional<Date>)
	{
		Asserter.assert(value === undefined || !Number.isNaN(value.valueOf()), 'Must be a valid Date');

		if (value === this._value)
			return;

		this._value = value;
		this._onChanged.notify(this, undefined);
	}

	private _value: Optional<Date>;
	private _onChanged = new Event<this>();
}


export { DateTimeModel };
