import { PureComponent, ReactNode, RefObject, createRef } from 'react';
import { OneToOneMapping } from '../common/OneToOneMapping';
import { Nullable } from '../common/Optional';
import { TR, Translator } from '../common/Translator';
import { Event } from '../models/Event';
import { ICampBetreuerModel } from '../models/ICampBetreuerModel';
import { IEvent } from '../models/IEvent';
import { IFlagModel } from '../models/IFlagModel';
import { IUiModelDataMapper, MappedValidatedChoiceModel } from '../models/MappedValidatedChoiceModel';
import { Button } from './Button';
import { HideableWithModel } from './Hideable';
import { Label } from './Label';
import { ValidatedComboboxWithModel } from './ValidatedCombobox';
import { ValidatedLineEditWithModel } from './ValidatedLineEdit';


type Props = {
	betreuer: ICampBetreuerModel;
	withDelete: IFlagModel;
	onDeleted: (competitor: ICampBetreuerModel) => void;
};


class CampBetreuerEdit extends PureComponent<Props>
{
	constructor(props: Props)
	{
		super(props);

		this._onDeleteClicked = this._onDeleteClicked.bind(this);
		this._onLanguageChanged = this._onLanguageChanged.bind(this);

		this._rolle = new MappedValidatedChoiceModel(this.props.betreuer.rolle, new RolleMapper(this.props.betreuer.rolle.choices));
	}

	render(): ReactNode
	{
		return (
			<>
				<Label label={`${TR('CampRegistrationForm::Vorname')}:`} className='col-4 d-md-none' />
				<div className='col-8 col-md-3'>
					<ValidatedLineEditWithModel ref={this._focusTarget} model={this.props.betreuer.vorname} />
				</div>
				<Label label={`${TR('CampRegistrationForm::Nachname')}:`} className='col-4 d-md-none' />
				<div className='col-8 col-md-3'>
					<ValidatedLineEditWithModel model={this.props.betreuer.nachname} />
				</div>
				<Label label={`${TR('CampRegistrationForm::Rolle')}:`} className='col-4 d-md-none' />
				<div className='col-8 col-md-3'>
					<ValidatedComboboxWithModel model={this._rolle} />
				</div>
				<div className="col-12 col-md-3 d-flex justify-content-end">
					<HideableWithModel isShown={this.props.withDelete}>
						<Button className="btn btn-outline-primary" tabIndex={-1} onClicked={this._onDeleteClicked}>
							{TR('CampRegistrationForm::Entfernen')}
						</Button>
					</HideableWithModel>
				</div>
			</>
		);
	}

	focus(): void
	{
		this._focusTarget.current!.focus();
	}

	componentDidMount(): void
	{
		Translator.instance().languageChanged.subscribe(this._onLanguageChanged);
	}

	componentWillUnmount(): void
	{
		Translator.instance().languageChanged.unsubscribe(this._onLanguageChanged);
	}

	componentDidUpdate(prevProps: Props): void
	{
		// Der Mapper bleibt konstant.
		this._rolle.setModels(this.props.betreuer.rolle);
	}

	private _onDeleteClicked(): void
	{
		this.props.onDeleted(this.props.betreuer);
	}

	private _onLanguageChanged(translator: Translator): void
	{
		this.forceUpdate();
	}

	private readonly _focusTarget: RefObject<ValidatedLineEditWithModel> = createRef<ValidatedLineEditWithModel>();
	private readonly _rolle: MappedValidatedChoiceModel<Nullable<string>>;
}


//==============================================================================


const INVALID_ENTRY = '------';


class RolleMapper implements IUiModelDataMapper<Nullable<string>>
{
	constructor(rollen: readonly Nullable<string>[])
	{
		this._onLanguageChanged = this._onLanguageChanged.bind(this);

		this._rollen = Array.from(rollen);
		this._resetMapping();

		Translator.instance().languageChanged.subscribe(this._onLanguageChanged);
	}

	get onChanged(): IEvent<this>
	{
		return this._onChanged;
	}

	fromUi(rolle: string): Nullable<string>
	{
		return this._mapping.toSource(rolle);
	}

	toUi(rolle: Nullable<string>): string
	{
		return this._mapping.toTarget(rolle);
	}

	private _onLanguageChanged(): void
	{
		this._resetMapping();
		this._onChanged.notify(this, undefined);
	}

	private _resetMapping(): void
	{
		this._mapping = new OneToOneMapping<Nullable<string>, string>(this._rollen.map(r => {
			if (r === null)
				return [null, INVALID_ENTRY];

			return [r, TR(`CampRegistrationForm::${r}`)];
		}));
	}

	private _rollen: Nullable<string>[];
	private _mapping: OneToOneMapping<Nullable<string>, string> = null!;
	private _onChanged = new Event<this>();
}


export { CampBetreuerEdit };
