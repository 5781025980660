import { Event } from './Event';
import { IEvent } from './IEvent';
import { ITextModel } from './ITextModel';


class TextModel implements ITextModel
{
	constructor(text: string = '')
	{
		this._text = text;
	}

	get onChanged(): IEvent<this>
	{
		return this._onChanged;
	}

	get text()
	{
		return this._text;
	}

	setText(text: string)
	{
		if (text === this._text)
			return;

		this._text = text;
		this._onChanged.notify(this, undefined);
	}

	private _text: string;
	private _onChanged = new Event<this>();
}


export { TextModel };
