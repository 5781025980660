import { PureComponent, ReactNode } from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import { Row } from '../components/Row';



type Props = {};


class RegistrationHub extends PureComponent<Props>
{
	render(): ReactNode
	{
		return (
			<Container fluid="lg" className="my-4">
				<Row className="gy-4">

					<div className="col-12">
						<Card border="secondary">
							<Card.Header className="fw-bold">
								Tournament Registration
							</Card.Header>
							<Card.Body>
								<p>
									Please choose one of the following registration forms:
								</p>
								<h5>For German Judoka only / Nur für deutsche Judoka</h5>
								<ul>
									<li><Link to="/registration/german-club">Einzelpersonen und Vereine</Link></li>
									<li><Link to="/registration/german-kader">Landeskader</Link></li>
								</ul>
								<h5>International Judoka</h5>
								<ul>
									<li><Link to="/registration/international-club">Single Persons and Clubs</Link></li>
									<li><Link to="/registration/national-team">National Team</Link></li>
								</ul>
							</Card.Body>
						</Card>
					</div>

					<div className="col-12">
						<Card border="secondary">
							<Card.Header className="fw-bold">
								Training Camp Registration
							</Card.Header>
							<Card.Body>
								<p>
									Please choose one of the following registration forms:
								</p>
								<ul>
									<li><Link to="/camp-registration/german">German Judoka</Link></li>
									<li><Link to="/camp-registration/international">International Judoka</Link></li>
								</ul>
							</Card.Body>
						</Card>
					</div>

				</Row>
			</Container>
		);
	}
}


export { RegistrationHub };
