import { Asserter } from './Asserter';
import { AgeGroupData } from '../data/AgeGroupData';
import { IGenericValidator, IValidator } from './IValidator';
import { stringToInt } from './utils';
import { Nullable } from './Optional';


interface ICharacterSet
{
	contains(char_: string): boolean;
}


/**
 * Repräsentierung eines CharacterSets durch einen String.
 */
class CharacterSet implements ICharacterSet
{
	/** string enthält enthält die Character des CharacterSets. */
	constructor(charSet: string)
	{
		this._charSet = charSet;
	}

	contains(char_: string)
	{
		Asserter.assert(char_.length === 1, 'Es darf nur ein Zeichen übergeben werden.');
		return this._charSet.includes(char_);
	}

	private readonly _charSet: string;
}

/**
 * Ein spezieller CharacterSet, repräsentiert durch einen Bereich, Grenzen inklusive.
 * Der Bereich wird per Unicode definiert.
 */
class CharacterRange implements ICharacterSet
{
	/** upperBound und lowerBound sind Integers, die Unicodes repräsentieren. */
	constructor(lowerBound: number, upperBound: number)
	{
		Asserter.assert(lowerBound <= upperBound, 'Bereiche sind nicht valide: lowerBound muss <= upperBound sein.');
		this._lowerBound = lowerBound;
		this._upperBound = upperBound;
	}

	contains(char_: string)
	{
		Asserter.assert(char_.length === 1, 'Es darf nur ein Zeichen übergeben werden.');
		let charCode = char_.charCodeAt(0);
		if (charCode < this._lowerBound)
			return false;
		if (charCode > this._upperBound)
			return false;

		return true;
	}

	private readonly _lowerBound: number;
	private readonly _upperBound: number;
}


/**
 * Hilfsklasse für die Validatoren.
 * Liefert true, wenn alle Zeichen des userInputs im erlaubten CharacterSet sind.
 * Den erlaubten CharacterSet definiert man per addCharacterSet().
 * Dieser Methode kann man Instanzen von CharacterRange und CharacterSet übergeben.
 */
class CharacterSetValidator implements IValidator
{
	constructor()
	{
		this._validCharSets = [];
	}

	/** charSet kann entweder ein CharacterRange oder ein CharacterSet sein. */
	addCharacterSet(charSet: ICharacterSet)
	{
		this._validCharSets.push(charSet);
	}

	isValid(userInput: string): boolean
	{
		for (const c of userInput)
			if (!this._characterValid(c))
				return false;

		return true;
	}

	private _characterValid(char_: string)
	{
		return this._validCharSets.some(charSet => charSet.contains(char_));
	}

	private readonly _validCharSets: ICharacterSet[];
}


/**
 * Validator für Namen -- also Vorname und Nachname
 */
class NameValidator extends CharacterSetValidator
{
	constructor()
	{
		super();
		this.addCharacterSet(new CharacterRange(0x0041, 0x005a)); // A-Z
		this.addCharacterSet(new CharacterRange(0x0061, 0x007a)); // a-z
		this.addCharacterSet(new CharacterRange(0x00c0, 0x00d6)); // latin-1
		this.addCharacterSet(new CharacterRange(0x00d8, 0x00f6)); // latin-1
		this.addCharacterSet(new CharacterRange(0x00f8, 0x00ff)); // latin-1
		this.addCharacterSet(new CharacterRange(0x0100, 0x017f)); // extended A
		this.addCharacterSet(new CharacterRange(0x0180, 0x024f)); // extended B
		this.addCharacterSet(new CharacterRange(0x1e00, 0x1e9b)); // extended additional
		this.addCharacterSet(new CharacterRange(0x1ea0, 0x1ef9)); // extended additional
		this.addCharacterSet(new CharacterSet(" .'-"));
	}

	isValid(userInput: string): boolean
	{
		const trimmed = userInput.trim();

		if (trimmed.length === 0)
			return false;

		if (trimmed.length < 2 || trimmed.length >= 64) // 64 ist die DB-Beschränkung
			return false;

		return super.isValid(trimmed);
	}
}


/**
 * Validator für E-Mail-Adressen.
 */
class EMailValidator extends CharacterSetValidator
{
	constructor()
	{
		super();
		this.addCharacterSet(new CharacterRange(0x0041, 0x005a)); // A-Z
		this.addCharacterSet(new CharacterRange(0x0061, 0x007a)); // a-z
		this.addCharacterSet(new CharacterRange(0x0030, 0x0039)); // 0-9
		this.addCharacterSet(new CharacterSet( ".-_"));
	}

	isValid(userInput: string): boolean
	{
		const trimmed = userInput.trim();

		if (trimmed.length >= 64) // 64 ist die DB-Beschränkung
			return false;

		let parts = trimmed.split('@');
		if (parts.length !== 2)
			return false;
		if ((parts[0].length === 0) || (parts[1].length === 0))
			return false;

		if (!super.isValid(parts[0]))
			return false;
		if (!super.isValid(parts[1]))
			return false;

		// Noch eine gesonderte Abfrage für den 2. Teil:
		// ... er muss mindestens einen . enthalten.
		if (!parts[1].includes('.'))
			return false;
		// ... und am Ende darf kein . stehen.
		if (parts[1].endsWith('.'))
			return false;
		// ... und am Anfang darf kein . stehen
		if (parts[1].charAt(0) === '.')
			return false;

		return true;
	}
}


/**
 * Validator für Vereinsnamen. -- Im Prizip ein NameValidator; zusätzlich sind aber auch Ziffern erlaubt.
 */
class VereinsValidator extends NameValidator
{
	constructor()
	{
		super();
		this.addCharacterSet(new CharacterRange(0x0030, 0x0039)); // 0-9
	}
}


class AgeGroupValidator implements IValidator
{
	constructor(jahrgaenge: AgeGroupData)
	{
		Asserter.assert(jahrgaenge.von < jahrgaenge.bis, 'invalid range');
		this._jahrgaenge = jahrgaenge;

		this._allDigitsValidator.addCharacterSet(new CharacterRange(0x0030, 0x0039)); // 0-9
	}

	isValid(value: string): boolean
	{
		if (!this._allDigitsValidator.isValid(value))
			return false;

		let jahrgang: number;
		try
		{
			jahrgang = stringToInt(value);
		}
		catch (e)
		{
			return false;
		}

		if (jahrgang < this._jahrgaenge.von)
			return false;
		if (jahrgang > this._jahrgaenge.bis)
			return false;

		return true;
	}

	private _allDigitsValidator = new CharacterSetValidator();
	private _jahrgaenge: AgeGroupData;
}


class MultiAgeGroupValidator implements IValidator
{
	constructor(ageGroups: Map<string, AgeGroupData>)
	{
		for (const [ageGroupName, range] of ageGroups)
			this._validators.set(ageGroupName, new AgeGroupValidator(range));
	}

	isValid(userInput: string): boolean
	{
		this._lastValidatedAgeGroups.length = 0;
		for (const [ageGroupName, validator] of this._validators)
			if (validator.isValid(userInput))
				this._lastValidatedAgeGroups.push(ageGroupName);
		return this._lastValidatedAgeGroups.length > 0;
	}

	/**
	 * Gibt die möglichen -- also validen -- Altersklassen zum zuletzt validierten
	 * userInput zurück.
	 */
	possibleAgeGroups()
	{
		return [...this._lastValidatedAgeGroups].sort();
	}

	private _validators = new Map<string, AgeGroupValidator>();
	private _lastValidatedAgeGroups: string[] = [];
}


class DateValidator implements IValidator
{
	constructor()
	{
		this._numberValidator = new CharacterSetValidator();
		this._numberValidator.addCharacterSet(new CharacterRange(0x0030, 0x0039)); // 0-9
	}

	isValid(userInput: string): boolean
	{
		let parts = userInput.split('-');
		if (parts.length !== 3)
			return false;

		let yearPart = parts[0];
		if (yearPart.length !== 4)
			return false;
		if (!this._isNumber(yearPart))
			return false;
		let yearAsInt = this._toNumber(yearPart);
		if (yearAsInt < 0)
			return false;

		let monthPart = parts[1];
		if (monthPart.length !== 2)
			return false;
		if (!this._isNumber(monthPart))
			return false;
		let monthAsInt = this._toNumber(monthPart);
		if (monthAsInt <= 0 || monthAsInt > 12)
			return false;

		let dayPart = parts[2];
		if (dayPart.length !== 2)
			return false;
		if (!this._isNumber(dayPart))
			return false;
		let dayAsInt = this._toNumber(dayPart);
		if (dayAsInt <= 0 || dayAsInt > 31)
			return false;

		return true;
	}

	private _isNumber(userInput: string)
	{
		return this._numberValidator.isValid(userInput);
	}

	private _toNumber(userInput: string)
	{
		return parseInt(userInput, 10);
	}

	private _numberValidator: CharacterSetValidator;
}


class TournamentIdValidator implements IValidator
{
	constructor()
	{
		this._nationValidator.addCharacterSet(new CharacterRange(0x0041, 0x005a)); // A-Z
		this._nationValidator.addCharacterSet(new CharacterRange(0x0061, 0x007a)); // a-z
		this._numberValidator.addCharacterSet(new CharacterRange(0x0030, 0x0039)); // 0-9
	}

	isValid(userInput: string): boolean
	{
		const trimmed = userInput.trim();

		if (trimmed.length !== 6)
			return false;

		const nationPart = trimmed.substring(0, 3);
		if (!this._nationValidator.isValid(nationPart))
			return false;

		const numberPart = trimmed.substring(3, 6);
		if (!this._numberValidator.isValid(numberPart))
			return false;

		return true;
	}

	private readonly _nationValidator = new CharacterSetValidator();
	private readonly _numberValidator = new CharacterSetValidator();
}


class NotEmptyValidator implements IValidator
{
	isValid(userInput: string): boolean
	{
		const trimmed = userInput.trim();
		return trimmed.length > 0;
	}

}


/**
 * Ein Wrapper für einen Validator, der zusätzlich valide ist, wenn der userInput leer ist.
 */
class EmptyOrValidator implements IValidator
{
	constructor(wrappedValidator: IValidator)
	{
		this._wrapped = wrappedValidator;
	}

	isValid(userInput: string): boolean
	{
		if (userInput.length === 0)
			return true;

		return this._wrapped.isValid(userInput);
	}

	private _wrapped: IValidator;
}


/**
 * Ein Validator, der alles durchlässt, was ungleich dem InvalidEntry ist.
 */
class InvalidEntryValidator implements IValidator
{
	constructor(invalidEntry: string)
	{
		this._invalidEntry = invalidEntry;
	}

	isValid(text: string): boolean
	{
		return text !== this._invalidEntry;
	}

	private _invalidEntry: string;
}


/**
 * Mit Hilfe des FunctionValidators lassen sich auf einfache Weise
 * Validatoren implementieren, deren Implementierung einfach nur eine Funktion ist.
 */
class FunctionValidator implements IValidator
{
	constructor(isValid: (userInput: string) => boolean)
	{
		this._isValid = isValid;
	}

	isValid(userInput: string): boolean
	{
		return this._isValid(userInput);
	}

	private _isValid: (UserInput: string) => boolean;
}


class NotNullValidator<T> implements IGenericValidator<Nullable<T>>
{
	isValid(item: Nullable<T>): boolean
	{
		return item !== null;
	}
}


/**
 * Dummy Validator, den ich zur Zeit noch an verschiedener Stelle verwende,
 * wo ich noch keinen dedizierten Validator habe.
 */
class DummyValidator implements IValidator
{
	isValid(text: string): boolean
	{
		return true;
	}
}


export {
	AgeGroupValidator,
	MultiAgeGroupValidator,
	InvalidEntryValidator,
	DummyValidator,
	FunctionValidator,
	NameValidator,
	EMailValidator,
	VereinsValidator,
	DateValidator,
	NotEmptyValidator,
	NotNullValidator,
	EmptyOrValidator,
	TournamentIdValidator
};
