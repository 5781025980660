import { Optional } from '../common/Optional';

type EventHandler<TSubject, TEventArg = undefined> = (subject: TSubject, arg: TEventArg) => void;


interface IEvent<TSubject, TEventArg = undefined>
{
	subscribe(handler: EventHandler<TSubject, TEventArg>): void;
	unsubscribe(handler: EventHandler<TSubject, TEventArg>): void;

	collectWhile(action: () => void): void;
}


function moveSubscription<TSubject, TEventArg>(
	oldEvent: Optional<IEvent<TSubject, TEventArg>>,
	newEvent: Optional<IEvent<TSubject, TEventArg>>,
	handler: EventHandler<TSubject, TEventArg>
)
{
	if (newEvent === oldEvent)
		return;

	oldEvent?.unsubscribe(handler);
	newEvent?.subscribe(handler);
}

/**
 * Hebt die Subscription des handlers auf, während action ausgeführt wird.
 */
function withoutSubscription<TSubject, TEventArg>(
	event: IEvent<TSubject, TEventArg>,
	handler: EventHandler<TSubject, TEventArg>,
	action: () => void
)
{
	event.unsubscribe(handler);
	try
	{
		action();
	}
	finally
	{
		event.subscribe(handler);
	}
}


export type { IEvent, EventHandler };
export { moveSubscription, withoutSubscription };
