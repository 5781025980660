import { EventForwarder } from './EventForwarder';
import { moveSubscription } from './IEvent';
import { IModel } from './IModel';
import { IObservableCollection } from './IObservableCollection';
import { ObservableCollection } from './ObservableCollection';


type CompareFunction<TItem> = (lhs: TItem, rhs: TItem) => number;


class SortedCollection<TItem extends IModel> implements IObservableCollection<TItem>
{
	constructor(source: IObservableCollection<TItem>, compareFn: CompareFunction<TItem>)
	{
		this._onSourceChanged = this._onSourceChanged.bind(this);

		this._source = source;
		this._compareFn = compareFn;
		this._sorted = new ObservableCollection<TItem>(this._getSortedItems());

		this._onChanged = new EventForwarder(this._sorted.onChanged, this);
		this._onAdded = new EventForwarder(this._sorted.onAdded, this);
		this._onRemoved = new EventForwarder(this._sorted.onRemoved, this);

		this._source.onChanged.subscribe(this._onSourceChanged);
	}

	get onChanged()
	{
		return this._onChanged.event;
	}

	get onAdded()
	{
		return this._onAdded.event;
	}

	get onRemoved()
	{
		return this._onRemoved.event;
	}

	get items()
	{
		return this._sorted.items;
	}

	setCompareFn(compareFn: CompareFunction<TItem>)
	{
		this._compareFn = compareFn;
		this._doSort();
	}

	setSource(newSource: IObservableCollection<TItem>)
	{
		moveSubscription(this._source.onChanged, newSource.onChanged, this._onSourceChanged);
		this._source = newSource;
		this._doSort();
	}

	add(item: TItem)
	{
		this._source.add(item);
	}

	remove(item: TItem)
	{
		this._source.remove(item);
	}

	clear()
	{
		this._source.clear();
	}

	reset(items: Readonly<TItem[]>)
	{
		this._source.reset(items);
	}

	private _onSourceChanged(source: IObservableCollection<TItem>)
	{
		this._doSort();
	}

	private _doSort()
	{
		this._sorted.reset(this._getSortedItems());
	}

	private _getSortedItems()
	{
		return [...this._source.items].sort(this._compareFn);
	}

	private _source: IObservableCollection<TItem>;
	private _compareFn: CompareFunction<TItem>;
	private _sorted: ObservableCollection<TItem>;
	private _onChanged: EventForwarder<ObservableCollection<TItem>, this>;
	private _onAdded: EventForwarder<ObservableCollection<TItem>, this, TItem>;
	private _onRemoved: EventForwarder<ObservableCollection<TItem>, this, TItem>;
}


function reversed<TItem>(compareFn: CompareFunction<TItem>): CompareFunction<TItem>
{
	function reversedCompare(lhs: TItem, rhs: TItem)
	{
		return compareFn(rhs, lhs);
	}

	return reversedCompare;
}


export { SortedCollection, reversed };
