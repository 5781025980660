import { IBackend } from '../common/IBackend';
import { ConfigData } from '../data/ConfigData';
import { Event } from './Event';
import { FlagModel } from './FlagModel';
import { IEvent } from './IEvent';


/**
 * Das Model für die Frontend-Config.
 */
class ConfigModel
{
	constructor(backend: IBackend, config: ConfigData)
	{
		this._onSubmodelChanged = this._onSubmodelChanged.bind(this);

		this._backend = backend;
		this._registrationEnabled = new FlagModel(config.registrationEnabled);
		this._newstickerEnabled = new FlagModel(config.newstickerEnabled);
		this._tournamentDetailsEnabled = new FlagModel(config.tournamentDetailsEnabled);
		this._campDetailsEnabled = new FlagModel(config.campDetailsEnabled);

		this._registrationEnabled.onChanged.subscribe(this._onSubmodelChanged);
		this._newstickerEnabled.onChanged.subscribe(this._onSubmodelChanged);
		this._tournamentDetailsEnabled.onChanged.subscribe(this._onSubmodelChanged);
		this._campDetailsEnabled.onChanged.subscribe(this._onSubmodelChanged);
	}

	get registrationEnabled()
	{
		return this._registrationEnabled;
	}

	get newstickerEnabled()
	{
		return this._newstickerEnabled;
	}

	get tournamentDetailsEnabled()
	{
		return this._tournamentDetailsEnabled;
	}

	get campDetailsEnabled()
	{
		return this._campDetailsEnabled;
	}

	get onChanged(): IEvent<ConfigModel>
	{
		return this._onChanged;
	}

	save(): Promise<void>
	{
		const config = {
			newstickerEnabled: this._newstickerEnabled.value,
			registrationEnabled: this._registrationEnabled.value,
			tournamentDetailsEnabled: this._tournamentDetailsEnabled.value,
			campDetailsEnabled: this._campDetailsEnabled.value,
		};
		return this._backend.updateConfig(config);
	}

	private _onSubmodelChanged()
	{
		this._notifyChange();
	}

	private _notifyChange()
	{
		this._onChanged.notify(this, undefined);
	}

	private _backend: IBackend;
	private _registrationEnabled: FlagModel;
	private _newstickerEnabled: FlagModel;
	private _tournamentDetailsEnabled: FlagModel;
	private _campDetailsEnabled: FlagModel;
	private _onChanged = new Event<ConfigModel>();
}


export { ConfigModel };
