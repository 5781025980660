import { PureComponent, ReactNode } from 'react';
import Row from 'react-bootstrap/Row';
import { moveSubscription } from '../models/IEvent';
import { NewstickerEntryModel } from '../models/NewstickerEntryModel';


type Props = {
	model: NewstickerEntryModel;
};


/**
 * Diese Component dient nur der Anzeige eines NewstickerEntry z.B. auf der Landing-Page.
 * Wird aber auch vom Formular zum Editieren eines NewstickerEntry beim Preview verwendet.
 */
class NewstickerEntry extends PureComponent<Props>
{
	constructor(props: Props)
	{
		super(props);

		this._onModelChanged = this._onModelChanged.bind(this);
	}

	render(): ReactNode
	{
		return (
			<div className={'callout-success rounded bg-white p-2 mt-2'}>
				<Row className='align-items-center'>
					<div className="col-2">
						{this.props.model.dateTime.value?.toLocaleString()}
					</div>
					<div className="col-10 border-start" dangerouslySetInnerHTML={{ __html: this.props.model.text.text }} />
				</Row>
			</div>
		);
	}

	componentDidMount(): void
	{
		this.props.model.onChanged.subscribe(this._onModelChanged);
	}

	componentWillUnmount(): void
	{
		this.props.model.onChanged.unsubscribe(this._onModelChanged);
	}

	componentDidUpdate(prevProps: Props): void
	{
		moveSubscription(prevProps.model.onChanged, this.props.model.onChanged, this._onModelChanged);
	}

	private _onModelChanged(model: NewstickerEntryModel):void
	{
		this.forceUpdate();
	}
}


export { NewstickerEntry };
