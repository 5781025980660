import { PureComponent, ReactNode } from 'react';
import { Asserter } from '../common/Asserter';
import { IChoiceModel } from '../models/IChoiceModel';
import { moveSubscription } from '../models/IEvent';
import BsDropdown from 'react-bootstrap/Dropdown';

type Props = {
	values: Readonly<string[]>;
	selected: string;
	onChanged: (value: string) => void;

	/**
	 * Eine Funktion, die zum Label ein ReactNode für das Label liefert.
	 * Ist optional. Wenn keine Funktion angegeben wird, wird der value selbst genommen.
	 * Wenn die Funktion zu einem value null oder undefined liefert, wird auch der
	 * value selbst genommen.
	 */
	valueToLabel?: (value: string) => ReactNode;
};


class RadioButtons extends PureComponent<Props>
{
	render()
	{
		let { values, selected } = this.props;
		const selectedIdx = values.indexOf(selected);
		Asserter.assert(selectedIdx !== -1, 'invalid selected value');

		const radios = values.map((value, idx) => {
			const isSelected = idx === selectedIdx;
			const labelContent = this.props.valueToLabel?.call(undefined, value);
			return (
				<BsDropdown.Item key={value} active={isSelected} onClick={() => this._onChanged(value)}>{labelContent ?? value}</BsDropdown.Item>
			);
		});

		return (
			<>
				{radios}
			</>
		);
	}

	private _onChanged(value: string)
	{
		this.props.onChanged(value);
	}
}


//==============================================================================


type PropsWithModel =
{
	model: IChoiceModel;
	valueToLabel?: (value: string) => ReactNode;
}


class RadioButtonsWithModel extends PureComponent<PropsWithModel>
{
	constructor(props: PropsWithModel)
	{
		super(props);

		this._onModelChanged = this._onModelChanged.bind(this);
		this._onUiChanged = this._onUiChanged.bind(this);
	}

	render()
	{
		return (
			<RadioButtons
				values={this.props.model.choices}
				selected={this.props.model.selected}
				onChanged={this._onUiChanged}
				valueToLabel={this.props.valueToLabel}
			/>
		);
	}

	componentDidMount()
	{
		this.props.model.onChanged.subscribe(this._onModelChanged);
	}

	componentWillUnmount()
	{
		this.props.model.onChanged.unsubscribe(this._onModelChanged);
	}

	componentDidUpdate(prevProps: PropsWithModel)
	{
		moveSubscription(prevProps.model.onChanged, this.props.model.onChanged, this._onModelChanged);
	}

	private _onModelChanged(model: IChoiceModel)
	{
		this.forceUpdate();
	}

	private _onUiChanged(selected: string)
	{
		this.props.model.setSelected(selected);
	}
}


export { RadioButtons, RadioButtonsWithModel };
