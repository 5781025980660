import { PureComponent, ReactNode } from 'react';
import { Asserter } from '../common/Asserter';
import { ICampRegistrationClient } from '../common/CampRegistrationClient';
import { FetchGuard } from '../common/FetchGuard';
import { Nullable } from '../common/Optional';
import { IRegistrationClient } from '../common/RegistrationClient';
import { BusyWithModel } from '../components/Busy';
import { CampRegistrationsOverview } from '../components/CampRegistrationsOverview';
import { CampRegistrationsModel } from '../models/CampRegistrationsModel';
import { ICampRegistrationsModel } from '../models/ICampRegistrationsModel';


type Props = {
	registrationClient: IRegistrationClient;
	campRegistrationClient: ICampRegistrationClient;
};


type State = {
	registrations: Nullable<ICampRegistrationsModel>;
};


class CampRegistrationsRoute extends PureComponent<Props, State>
{
	constructor(props: Props)
	{
		super(props);

		this.state = {
			registrations: null
		};

		this._renderContent = this._renderContent.bind(this);
		this._fetchRegistrations = this._fetchRegistrations.bind(this);
	}

	render(): ReactNode
	{
		return <BusyWithModel isBusy={this._fetchGuard.isBusy} render={this._renderContent} className="my-4" />;
	}

	componentDidMount(): Promise<void>
	{
		return this._fetchGuard.fetch(this._fetchRegistrations);
	}

	private _renderContent(): ReactNode
	{
		Asserter.assert(this.state.registrations !== null, 'must not be called when busy');

		return <CampRegistrationsOverview registrations={this.state.registrations} client={this.props.campRegistrationClient} />;
	}

	private async _fetchRegistrations(): Promise<void>
	{
		const registrations = await CampRegistrationsModel.loader.load(this.props.campRegistrationClient, this.props.registrationClient);
		this.setState({ registrations });
	}

	private readonly _fetchGuard = new FetchGuard();
}


export { CampRegistrationsRoute };
