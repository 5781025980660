import { PropsWithChildren, PureComponent } from 'react';
// import { MdOutlineMenu } from 'react-icons/md';
import BsDropdown from 'react-bootstrap/Dropdown';


type Props = PropsWithChildren<{
	label?: string;
	iconSize?: string;
}>;


class Dropdown extends PureComponent<Props>
{
	render()
	{
		return (
			<BsDropdown>
				<BsDropdown.Toggle variant="primary">
					{this.props.label}
					{/* <MdOutlineMenu size={this.props.iconSize ?? '2em'} className={this.props.label ? 'ms-1' : undefined} /> */}
				</BsDropdown.Toggle>
				<BsDropdown.Menu>
					{this.props.children}
				</BsDropdown.Menu>
			</BsDropdown>
		);
	}
}


export { Dropdown };
