import { Asserter } from '../common/Asserter';
import { EventForwarder } from './EventForwarder';
import { FlagModel } from './FlagModel';
import { IEvent, moveSubscription } from './IEvent';
import { IFlagModel } from './IFlagModel';
import { IModel } from './IModel';


type Compute<TModel> = (model: TModel) => boolean;


class ComputedFlagModel<TModel extends IModel> implements IFlagModel
{
	constructor(compute: Compute<TModel>, sourceModel: TModel)
	{
		this._onModelChanged = this._onModelChanged.bind(this);

		this._sourceModel = sourceModel;
		this._compute = compute;
		this._flagModel = new FlagModel(compute(sourceModel));
		this._onChanged = new EventForwarder(this._flagModel.onChanged, this);

		this._sourceModel.onChanged.subscribe(this._onModelChanged);
	}

	get onChanged(): IEvent<this>
	{
		return this._onChanged.event;
	}

	get value()
	{
		return this._flagModel.value;
	}

	setModel(model: TModel)
	{
		if (model === this._sourceModel)
			return;

		moveSubscription(this._sourceModel.onChanged, model.onChanged, this._onModelChanged);
		this._sourceModel = model;
		this._onModelChanged(this._sourceModel);
	}

	setValue(value: boolean)
	{
		Asserter.fail('readonly');
	}

	toggle()
	{
		Asserter.fail('readonly');
	}

	private _onModelChanged(model: IModel)
	{
		this._flagModel.setValue(this._compute(this._sourceModel));
	}

	private _sourceModel: TModel;
	private _compute: Compute<TModel>;
	private _flagModel: FlagModel;
	private _onChanged: EventForwarder<FlagModel, this>;
}


export { ComputedFlagModel };
