import { RegistrationFormData } from '../data/RegistrationFormData';
import { Asserter } from './Asserter';
import { CachedValue } from './CachedValue';
import { IBackend } from './IBackend';
import { Nullable } from './Optional';


class ResourceProvider
{
	static instance()
	{
		Asserter.assert(ResourceProvider._instance !== null, 'singleton instance was not created yet');
		return ResourceProvider._instance;
	}

	constructor(backend: IBackend)
	{
		Asserter.assert(ResourceProvider._instance === null, 'singleton instance was created already');
		ResourceProvider._instance = this;

		this._formData = new CachedValue(() => backend.registrations.fetchFormData(), 3600);
	}

	get formData(): Promise<RegistrationFormData>
	{
		return this._formData.get();
	}

	private static _instance: Nullable<ResourceProvider> = null;
	private _formData: CachedValue<RegistrationFormData>;
}


export { ResourceProvider };
