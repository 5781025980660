import { Asserter } from '../common/Asserter';
import { IFilesClient } from '../common/FilesClient';
import { stringToInt } from '../common/utils';
import { NotEmptyValidator } from '../common/Validators';
import { FileEntryData, FileStorageData } from '../data/FileData';
import { ComputedFlagModel } from './ComputedFlagModel';
import { ComputedTextModel } from './ComputedTextModel';
import { DateTimeModel } from './DateTimeModel';
import { Event } from './Event';
import { FileModel } from './FileModel';
import { IEvent } from './IEvent';
import { IFlagModel } from './IFlagModel';
import { IModel } from './IModel';
import { ITextModel } from './ITextModel';
import { IValidatedTextModel } from './IValidatedTextModel';
import { TextModel } from './TextModel';
import { ValidatedTextModel } from './ValidatedTextModel';
import { WasChangedModel } from './WasChangedModel';


/**
 * Model für einen FileUpload.
 */
class FileUploadEntryModel implements IModel
{
	constructor(client: IFilesClient, data?: FileEntryData)
	{
		this._getIsValid = this._getIsValid.bind(this);
		this._onSubmodelChanged = this._onSubmodelChanged.bind(this);
		this._onFileChanged = this._onFileChanged.bind(this);

		this._client = client;
		this._id = new TextModel(data?.id ?? FileUploadEntryModel._nextId());
		this._name = new ValidatedTextModel(data?.name ?? '', new NotEmptyValidator());
		this._created = new DateTimeModel(data?.created ?? undefined);
		this._updated = new DateTimeModel(data?.updated ?? undefined);
		this._url = new ComputedTextModel(_makeUrl, this._id);
		this._isValid = new ComputedFlagModel<FileUploadEntryModel>(this._getIsValid, this);

		this._id.onChanged.subscribe(this._onSubmodelChanged);
		this._name.onChanged.subscribe(this._onSubmodelChanged);
		this._file.onChanged.subscribe(this._onFileChanged);
		this._created.onChanged.subscribe(this._onSubmodelChanged);
		this._updated.onChanged.subscribe(this._onSubmodelChanged);

		this._wasChanged = new WasChangedModel(this);
		// Zirkelschluss
		this._wasChanged.onChanged.subscribe(this._onSubmodelChanged);
	}

	get onChanged(): IEvent<this>   { return this._onChanged; }

	get isValid(): IFlagModel       { return this._isValid; }
	get id(): ITextModel            { return this._id; }
	get name(): IValidatedTextModel { return this._name; }
	get file(): FileModel           { return this._file; }
	get created(): DateTimeModel    { return this._created; }
	get updated(): DateTimeModel    { return this._updated; }
	get url(): ITextModel           { return this._url; }
	get isPersisted(): boolean      { return this._hasPermanentId(); }

	get wasChanged(): IFlagModel    { return this._wasChanged; }


	async save(): Promise<void>
	{
		Asserter.assert(this.isValid.value, 'not allowed when invalid');

		const fileData: FileStorageData = {
			name: this._name.text,
			file: this._file.file!
		}

		let savedData: FileEntryData;
		if (this.isPersisted)
		{
			fileData.id = stringToInt(this._id.text);
			savedData = await this._client.update(fileData);
		}
		else
			savedData = await this._client.post(fileData);

		this._refresh(savedData);
	}

	private _refresh(data: FileEntryData)
	{
		this._onChanged.collectWhile(() => {
			this._id.setText(data.id);
			this._name.setText(data.name);

			this._file.setFile(undefined);

			this._created.setValue(data.created);
			this._updated.setValue(data.updated);
		});

		this._wasChanged.reset();
	}

	private _getIsValid(): boolean
	{
		if (this.isPersisted)
			return this._name.isValid();

		// noch nicht persistiert
		if (this._file.file === undefined)
			return false;
		if (this._name.isValid() === false)
			return false;

		return true;
	}

	private _onSubmodelChanged()
	{
		this._notifyChange();
	}

	private _onFileChanged(file: FileModel)
	{
		if (file.file !== undefined)
			this._name.setText(file.file.name);

		this._notifyChange();
	}

	private _notifyChange()
	{
		this._onChanged.notify(this, undefined);
	}

	private _hasPermanentId()
	{
		return _isPermanentId(this._id.text);
	}

	private static _nextId()
	{
		return (FileUploadEntryModel._fileId--).toString();
	}

	private static _fileId = -1;

	private readonly _client: IFilesClient;
	private readonly _onChanged = new Event<this>();
	private readonly _id: TextModel;
	private readonly _name: ValidatedTextModel;
	private readonly _file = new FileModel();
	private readonly _created: DateTimeModel;
	private readonly _updated: DateTimeModel;
	private readonly _url: ComputedTextModel<TextModel>;
	private readonly _isValid: ComputedFlagModel<FileUploadEntryModel>;
	private readonly _wasChanged: WasChangedModel<this>;
}


function _makeUrl(id: ITextModel): string
{
	return `/api/files/${id.text}`;
}


function _isPermanentId(id: string)
{
	return stringToInt(id) > 0;
}


export { FileUploadEntryModel };
