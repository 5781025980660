import { FormEvent, PureComponent, ReactNode } from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import { AppUserModel } from '../models/AppUserModel';
import { moveSubscription } from '../models/IEvent';
import { Button } from './Button';
import { Checkbox } from './Checkbox';
import { Label } from './Label';
import { Row } from './Row';


type Props = {
	user: AppUserModel;
};


class ProfileForm extends PureComponent<Props>
{
	constructor(props: Props)
	{
		super(props);

		this._onUserChanged = this._onUserChanged.bind(this);
		this._logout = this._logout.bind(this);
	}

	render(): ReactNode
	{
		return (
			<Container fluid="lg" className="my-4">
				<Card border="secondary">
					<Card.Header>
						Profil
					</Card.Header>
					<Card.Body>
						<form onSubmit={this._logout} noValidate>
							<Row className="justify-content-center">
								<Label label="User Name:" className="col-4" />
								<Label label={this.props.user.email} className="col-4" />
							</Row>

							<Row className="justify-content-center mt-2">
								<Label label="API-Token:" className="col-4" />
								<Label label={this.props.user.token} className="col-4" />
							</Row>

							<Row className="justify-content-center mt-2">
								<div className="col-8">
									<Checkbox type="checkbox" isChecked={this.props.user.isAdmin} label="Administrator" isEnabled={false} />
								</div>
							</Row>

							<Row className="justify-content-center mt-2">
								<div className="col-8">
									<Button type="submit" className="btn btn-secondary rounded-pill">
										Log Out
									</Button>
								</div>
							</Row>
						</form>
					</Card.Body>
				</Card>
			</Container>
		);
	}

	componentDidMount(): void
	{
		this.props.user.onChanged.subscribe(this._onUserChanged);
	}

	componentWillUnmount(): void
	{
		this.props.user.onChanged.unsubscribe(this._onUserChanged);
	}

	componentDidUpdate(prevProps: Props): void
	{
		moveSubscription(prevProps.user.onChanged, this.props.user.onChanged, this._onUserChanged);
	}

	private _onUserChanged(appUser: AppUserModel): void
	{
		this.forceUpdate();
	}

	private _logout(e: FormEvent<HTMLFormElement>): void
	{
		e.preventDefault();
		this.props.user.logout();
	}
}


export { ProfileForm };
