import { PureComponent, ReactNode } from 'react';
import { Asserter } from '../common/Asserter';
import { FetchGuard } from '../common/FetchGuard';
import { IBackend } from '../common/IBackend';
import { Nullable } from '../common/Optional';
import { BusyWithModel } from '../components/Busy';
import { TournamentsOverview } from '../components/TournamentsOverview';
import { AllTournamentsData } from '../data/AllTournamentsData';

type ConnectedProps = {
	backend: IBackend;
};


type ConnectedState = {
	tournaments: Nullable<AllTournamentsData>;
};


class ConnectedTournaments extends PureComponent<ConnectedProps, ConnectedState>
{
	constructor(props: ConnectedProps)
	{
		super(props);

		this.state = {
			tournaments: null
		};

		this._renderContent = this._renderContent.bind(this);
	}

	render(): ReactNode
	{
		return <BusyWithModel isBusy={this._fetchGuard.isBusy} render={this._renderContent} className="my-4"/>;
	}

	componentDidMount(): Promise<void>
	{
		return this._fetchGuard.fetch(() => this._fetchTournaments());
	}

	private async _fetchTournaments(): Promise<void>
	{
		const allTournaments = await this.props.backend.fetchAllTournaments();
		this.setState({tournaments: allTournaments });
	}

	private _renderContent(): ReactNode
	{
		Asserter.assert(this.state.tournaments !== null, 'must not be called when busy');

		return <TournamentsOverview tournaments={this.state.tournaments} />;
	}

	private readonly _fetchGuard = new FetchGuard();
}


export { ConnectedTournaments as TournamentsRoute };
