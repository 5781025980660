import { FormEvent, PureComponent, ReactNode } from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import { ConfigModel } from '../models/ConfigModel';
import { IFlagModel } from '../models/IFlagModel';
import { IndicatorModel } from '../models/IndicatorModel';
import { Button } from './Button';
import { CheckboxWithModel } from './Checkbox';
import { Indicator } from './Indicator';
import { Row } from './Row';


type Props = {
	config: ConfigModel;
}


class ConfigForm extends PureComponent<Props>
{
	constructor(props: Props)
	{
		super(props);

		this._saveConfig = this._saveConfig.bind(this);
	}

	render()
	{
		return (
			<Container fluid="lg" className="my-4">
				<Card border="secondary" className="mb-2">
					<Card.Header>
						Konfiguration
					</Card.Header>
					<Card.Body>
						<form onSubmit={this._saveConfig} noValidate>
							<Row className='justify-content-center'>
								{this._renderCheckbox("Registration", this.props.config.registrationEnabled)}
								{this._renderCheckbox("Newsticker", this.props.config.newstickerEnabled)}
								{this._renderCheckbox("Tournament Details", this.props.config.tournamentDetailsEnabled)}
								{this._renderCheckbox("Camp Details", this.props.config.campDetailsEnabled)}
							</Row>

							<Row className='justify-content-center mt-2'>
								<div className="col-8">
									<Button type="submit" className="btn btn-secondary rounded-pill ">
										Update Configuration
									</Button>
								</div>
							</Row>
						</form>
					</Card.Body>
				</Card>

				<Indicator model={this._backendResult} />
			</Container>
		);
	}

	private _renderCheckbox(label: string, model: IFlagModel): ReactNode
	{
		return (
			<div className="col-8">
				<CheckboxWithModel label={label} isChecked={model} type="switch" />
			</div>
		);
	}

	private async _saveConfig(e: FormEvent<HTMLFormElement>): Promise<void>
	{
		e.preventDefault();
		this._backendResult.reset();

		try
		{
			await this.props.config.save();
		}
		catch (e)
		{
			this._backendResult.setError('Fehler beim Speichern der Konfiguration');
			return;
		}

		this._backendResult.setSuccess('Konfiguration erfolgreich gespeichert');
	}

	private _backendResult = new IndicatorModel();
}


export { ConfigForm };
