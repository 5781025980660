import { Asserter } from '../common/Asserter';
import { INewstickerClient } from '../common/NewstickerClient';
import { stringToInt } from '../common/utils';
import { NewstickerData } from '../data/NewstickerData';
import { ComputedFlagModel } from './ComputedFlagModel';
import { Event } from './Event';
import { IEvent } from './IEvent';
import { IFlagModel } from './IFlagModel';
import { INewstickerModel } from './INewstickerModel';
import { IObservableCollection } from './IObservableCollection';
import { NewstickerEntryModel } from './NewstickerEntryModel';
import { ObservableCollection } from './ObservableCollection';


/**
 * Model für die Liste aller NewstickerEntries.
 */
class NewstickerModel implements INewstickerModel
{
	constructor(client: INewstickerClient, data: NewstickerData)
	{
		this._getIsValid = this._getIsValid.bind(this);
		this._onSubmodelChanged = this._onSubmodelChanged.bind(this);

		this._client = client;
		const entries = _createEntries(client, data);
		this._entries = new ObservableCollection(entries);
		this._isValid = new ComputedFlagModel<typeof this._entries>(this._getIsValid, this._entries);

		this._entries.onChanged.subscribe(this._onSubmodelChanged);
		this._isValid.onChanged.subscribe(this._onSubmodelChanged);
	}

	get onChanged(): IEvent<this>
	{
		return this._onChanged;
	}

	get entries(): IObservableCollection<NewstickerEntryModel>
	{
		return this._entries;
	}

	get isValid(): IFlagModel
	{
		return this._isValid;
	}

	create(): NewstickerEntryModel
	{
		const newEntry = new NewstickerEntryModel(this._client);
		this._entries.add(newEntry);
		return newEntry;
	}

	async remove(entry: NewstickerEntryModel): Promise<void>
	{
		if (entry.isPersisted)
			await this._client.delete(stringToInt(entry.id.text));
		this._entries.remove(entry);
	}

	/**
	 * Darf nur gerufen werden, wenn das Model valide ist.
	 * Speichert alle modifizierten Einträge.
	 */
	async save(): Promise<void>
	{
		Asserter.assert(this.isValid.value, 'not allowed when invalid');

		// Einträge parallel speichern
		const tasks = this._entries.items.map(entry => this._saveIfNeeded(entry));
		await Promise.all(tasks);
	}

	async refresh(): Promise<void>
	{
		const data = await this._client.fetchAll();
		const entries = _createEntries(this._client, data);
		this._entries.reset(entries);
	}

	private _getIsValid()
	{
		return this._entries.items.every(e => e.isValid.value);
	}

	private _saveIfNeeded(entry: NewstickerEntryModel): Promise<void>
	{
		Asserter.assert(entry.isValid.value, 'inconsistency');

		if (!entry.wasChanged.value)
			return Promise.resolve();

		return entry.save()
	}

	private _onSubmodelChanged()
	{
		this._onChanged.notify(this, undefined);
	}

	private _onChanged = new Event<this>();
	private _client: INewstickerClient;
	private _entries: ObservableCollection<NewstickerEntryModel>;
	private _isValid: ComputedFlagModel<typeof this._entries>;
}


function _createEntries(client: INewstickerClient, data: NewstickerData)
{
	return data.map(entry => new NewstickerEntryModel(client, entry));
}


export { NewstickerModel };
