import { Asserter } from './Asserter';
import { Nullable, Optional } from './Optional';


type _Fetcher<T> = () => Promise<T>;
type _Resolver = (value: void | PromiseLike<void>) => void;


/**
 * Ein Cache für Werte vom Type T, die asynchron über die im Konstruktor
 * übergebene Funktion bezogen werden.
 * Der erste Zugriff auf den Wert via get() löst das Beziehen des Wertes aus.
 * Alle weiteren Aufrufe von get() liefern dann den einmal ermittelten Wert zurück.
 *
 * Optional kann man noch einen Zeitspanne mitgeben, die angibt, wie lange
 * ein bezogener Wert gültig ist. Wird diese Zeit überschritten, wird der Wert
 * neu bezogen.
 */
class CachedValue<T>
{
	constructor(fetcher: _Fetcher<T>, validFor?: number)
	{
		this._fetcher = fetcher;
		this._validFor = validFor;
	}

	async get(): Promise<T>
	{
		this._holder.resetIfOutdated(this._validFor);

		if (this._holder.value === undefined)
			await this._fetchOrWait();

		Asserter.assert(this._holder.value !== undefined, 'inconsistency');
		return this._holder.value;
	}

	private _fetchOrWait(): Promise<void>
	{
		Asserter.assert(this._holder.value === undefined, 'inconsistency');

		if (this._inFlight)
			return this._wait();

		return this._fetch();
	}

	private async _fetch(): Promise<void>
	{
		Asserter.assert(this._holder.value === undefined, 'inconsistency');
		Asserter.assert(!this._inFlight, 'inconsistency');

		this._inFlight = true;
		try
		{
			this._holder.setValue(await this._fetcher());
		}
		finally
		{
			this._inFlight = false;

			this._waiting.forEach(resolve => resolve());
			this._waiting.length = 0;
		}
	}

	private async _wait(): Promise<void>
	{
		Asserter.assert(this._inFlight, 'inconsistency');

		const promise = new Promise<void>((resolve, reject) => {
			this._waiting.push(resolve);
		});
		return promise;
	}

	private _holder = new _ValueHolder<T>();
	private _fetcher: _Fetcher<T>;
	private _inFlight = false;
	private _waiting: _Resolver[] = [];
	private _validFor: Optional<number>; // in s
}


/**
 * Hilfsklasse, die den Wert speichert mit der Uhrzeit, zu der er bezogen wurde.
 */
class _ValueHolder<T>
{
	get value(): Optional<T>
	{
		return this._value;
	}

	setValue(value: T)
	{
		this._value = value;
		this._receivedAt = new Date();
	}

	resetIfOutdated(validFor: Optional<number>)
	{
		if (validFor === undefined) // wird nie ungültig
			return;

		if (this._value === undefined) // Wert wurde noch nicht bezogen
			return;

		Asserter.assert(this._receivedAt !== null, 'inconsistency');

		const now = new Date();
		const elapsed = (now.valueOf() - this._receivedAt.valueOf()) / 1000; // in s

		if (elapsed > validFor) // abgelaufen
			this._reset();
	}

	private _reset()
	{
		this._value = undefined;
		this._receivedAt = null;
	}

	private _value: Optional<T>;
	private _receivedAt: Nullable<Date> = null;
}


export { CachedValue };
