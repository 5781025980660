import { AllRegistrationsData } from '../data/AllRegistrationsData';
import { RegistrationConfirmationData } from '../data/RegistrationConfirmationData';
import { RegistrationData } from '../data/RegistrationData';
import { RegistrationDistributionData } from '../data/RegistrationDistributionData';
import { RegistrationFormData, RegistrationFormServerData } from '../data/RegistrationFormData';
import { Asserter } from './Asserter';
import { HttpClient, SupportedContentType } from './HttpClient';
import { Nullable } from './Optional';


interface IRegistrationClient
{
	fetchFormData(): Promise<RegistrationFormData>;

	post(registration: RegistrationData): Promise<RegistrationData>;
	update(registration: RegistrationData): Promise<RegistrationData>;
	fetch(regId: string, email: Nullable<string>): Promise<RegistrationData>;
	fetchAll(): Promise<AllRegistrationsData>;
	delete(regId: string): Promise<void>;

	/**
	 * @param force sorgt dafür, dass die Confirmation-E-Mail erneut rausgeschickt wird, wenn die
	 * Meldung bereits bestätigt war.
	 * @returns true, wenn die Meldung bestätigt wurde; false, wenn die Meldung bereits bestätigt war.
	 */
	confirm(regId: string, email: Nullable<string>, force: boolean): Promise<boolean>;

	/**
	 * Liefert die Verteilung der Gemeldeten pro Altersklasse.
	 */
	distribution(): Promise<RegistrationDistributionData>;

	allAsCsv(): Promise<Blob>;
	allCompetitorsAsCsv(): Promise<Blob>;
}


class RegistrationClient implements IRegistrationClient
{
	constructor(client: HttpClient)
	{
		this._client = client;
	}

	async fetchFormData(): Promise<RegistrationFormData>
	{
		const response = await this._client.get('api/registrations/form-data');
		this._client.checkResponse(response);
		const data = await response.json() as RegistrationFormServerData;

		const nations = data.nations.sort((lhs, rhs) => lhs.name.localeCompare(rhs.name));
		const federations = data.federations.sort((lhs, rhs) => lhs.name.localeCompare(rhs.name));
		return {
			nations,
			federations,
			age_groups: data.age_groups,
			rollen: data.rollen
		}
	}

	async post(registration: RegistrationData): Promise<RegistrationData>
	{
		const data = { meldung: registration };
		const response = await this._client.post('api/registrations', data);
		this._client.checkResponse(response);
		const registrationData = await response.json();
		return registrationData;
	}

	async update(registration: RegistrationData): Promise<RegistrationData>
	{
		const data = { meldung: registration };
		const response = await this._client.put(`api/registrations/${registration.id}`, data);
		this._client.checkResponse(response);
		const registrationData = await response.json();
		return registrationData;
	}

	async fetch(regId: string, email: Nullable<string>): Promise<RegistrationData>
	{
		Asserter.assert(regId.length > 0, 'must not be empty');
		const arg = email !== null ? `?email=${email}` : '';
		const response = await this._client.get(`api/registrations/${regId}${arg}`);
		this._client.checkResponse(response);
		const data = await response.json();
		return data;
	}

	async fetchAll(): Promise<AllRegistrationsData>
	{
		const response = await this._client.get('api/registrations');
		this._client.checkResponse(response);
		const data = await response.json();
		return data;
	}

	async delete(regId: string): Promise<void>
	{
		const response = await this._client.delete(`api/registrations/${regId}`);
		this._client.checkResponse(response);
	}

	/**
	 * @returns false, wenn die Meldung bereits confirmed war.
	 */
	async confirm(regId: string, email: Nullable<string>, force: boolean): Promise<boolean>
	{
		const confirmationData: RegistrationConfirmationData = {
			email,
			force
		};

		const response = await this._client.post(`api/registrations/${regId}/confirm`, confirmationData);
		this._client.checkResponse(response);

		const wasConfirmedAlready = response.status === 204;
		return !wasConfirmedAlready;
	}

	async distribution(): Promise<RegistrationDistributionData>
	{
		const response = await this._client.get('api/registrations/distribution');
		this._client.checkResponse(response);
		const data = await response.json();
		return data as RegistrationDistributionData;
	}

	async allAsCsv(): Promise<Blob>
	{
		const contentType: SupportedContentType = 'text/csv';
		const response = await this._client.get('api/registrations/csv', contentType);
		this._client.checkResponse(response, contentType);
		return await response.blob();
	}

	async allCompetitorsAsCsv(): Promise<Blob>
	{
		const contentType: SupportedContentType = 'text/csv';
		const response = await this._client.get('api/registrations/csv/competitors', contentType);
		this._client.checkResponse(response, contentType);
		return await response.blob();
	}

	private _client: HttpClient;
}


export { RegistrationClient };
export type { IRegistrationClient };

