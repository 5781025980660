import { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { AllTournamentsData, TournamentData } from '../data/AllTournamentsData';


type Props = {
	tournaments: AllTournamentsData
};


class TournamentsOverview extends PureComponent<Props>
{
	render()
	{
		// hint: reverse() arbeitet in-place, daher ziehen wir vorher eine Kopie.
		const rows = Array.from(this.props.tournaments).reverse().map((t, idx) => <TournamentRow key={t.id} idx={idx} tournament={t} />);
		return (
			<div className="container-md my-4">
				<div className="list-group">
					{rows}
				</div>
			</div>
		)
	}
};


//==============================================================================


type RowProps = {
	idx: number;
	tournament: TournamentData;
};

class TournamentRow extends PureComponent<RowProps>
{
	render()
	{
		let variant: string;
		if (this.props.idx % 2 === 0)
			variant = 'secondary';
		else
			variant = 'success';
		return (
			<Link className={`list-group-item list-group-item-action list-group-item-${variant} text-center`} to={`/tournaments/${this.props.tournament.id}/results`}>
				International Masters Bremen {this.props.tournament.jahr} &mdash; Men {this.props.tournament.altersklasse}
			</Link>
		);
	}
}


export { TournamentsOverview };
