import { FlagModel } from "../models/FlagModel";
import { IFlagModel } from "../models/IFlagModel";


/**
 * Eine Hilfsklasse für Components, die Resourcen vom Server ziehen, die sie dann darstellen wollen.
 * Beipsiele für solche Components sind etwa TournamentResults oder TournamentStatistics.
 *
 * Üblicherweise ist das Vorgehen so:
 * Das Fetchen der Resource wird von der Component in componentDidMount() asynchron angestoßen.
 * Das kann entweder erfolgreich sein oder mit "not found" vom Server beantwortet werden.
 * (Andere Fehler wie z.B. 500 internal server error können theoretisch auch auftreten, aber die
 * behandeln wir in erster Instanz wie "not found".)
 * Die Resource selbst bzw. der Status "not found" werden dann im State der Component gesetzt.
 * Daraufhin rendert die Component ihren Content.
 *
 * Das Handling des Fetchens der Resource und des folgenden Setzens des States der Component
 * wird schnell unübersichtlich. Grund dafür ist vor allem die Art-und-Weise, wie React
 * die Lifecycle-Methoden von Components aufruft. Es kann z.B. sein, dass componentDidMount()
 * mehrfach gerufen wird. Im React Strict Mode im Development Build macht React das explizit so.
 *
 * Mit dem FetchGuard wollen wir dafür sorgen, dass der Code an der Stelle einfacher wird.
 * Es soll sichergestellt werden, dass die Resource genau einmal gefetcht wird und dass
 * der State der Component genau einmal gesetzt wird.
 */
class FetchGuard
{
	/**
	 * Fragt die Resource beim Server an -- jedoch nur, wenn sie nicht bereits angefragt wurde.
	 */
	async fetch(doFetch: () => Promise<void>): Promise<void>
	{
		if (this._state !== 'not-started')
			return;

		this._state = 'in-flight';
		try
		{
			// Würde es das unten beschriebene Problem lösen, wenn ich den State auf 'done' setze,
			// nachdem der synchrone Teil von doFetch() durchgelaufen ist?
			// Vermutlich nicht. Man müsste es hinbekommen, dass der State hier richtig gesetzt wird,
			// bevor in doFetch() der State der Component geändert wird.
			// Kann man das irgendwie erreichen?

			await doFetch();
			this._isBusy.setValue(false);
		}
		finally
		{
			this._state = 'done';
		}
	}

	//
	// Das Property will ich nicht anbieten. Das muss in der Component selbst nachgebildet werden,
	// die den FetchGuard verwendet.
	// Das Problem ist, dass doFetch() oben auch synchron durchlaufen kann -- z.B. im Fehlerfall.
	// Das führt dann vermutlich auch synchron zu einem render() (weil sich der State geändert hat).
	// Dort würde dann isBusy abgefragt werden, was zu diesem Zeitpunkt dann aber immer noch auf
	// 'in-flight' steht. Erst nach dem render() würde der State hier auf 'done' gesetzt werden,
	// was aber wiederum nicht zu einem erneuten render() führt.
	// Blöd...
	//
	// get isBusy(): boolean
	// {
	// 	return this._state !== 'done';
	// }

	/**
	 * Kann genutzt werden, um damit z.B. BusyWithModel zu füttern.
	 * Liefert initial true und wechselt nur dann auf false, wenn fetch() erfolgreich durchgelaufen ist.
	 */
	get isBusy(): IFlagModel
	{
		return this._isBusy;
	}

	private _state: RequestState = 'not-started';
	private readonly _isBusy = new FlagModel(true);
}

/**
 * Der State der ServerResource bzw. der Anfrage an den Server, um die Resource zu besorgen.
 */
type RequestState = 'not-started' | 'in-flight' | 'done';


export { FetchGuard };
