import { IRegistrationClient } from '../common/RegistrationClient';
import { ResourceProvider } from '../common/ResourceProvider';
import { AllRegistrationsData } from '../data/AllRegistrationsData';
import { RegistrationFormData } from '../data/RegistrationFormData';
import { Event } from './Event';
import { IEvent } from './IEvent';
import { IObservableCollection } from './IObservableCollection';
import { IRegistrationModel } from './IRegistrationModel';
import { IRegistrationsModel } from './IRegistrationsModel';
import { ObservableCollection } from './ObservableCollection';
import { RegistrationModel } from './RegistrationModel';


/**
 * Der Loader für die Meldungen.
 * Die Singleton-Instanz ist über RegistrationsModel::loader() zugreifbar.
 */
class Loader
{
	async load(client: IRegistrationClient): Promise<IRegistrationsModel>
	{
		const formData = await ResourceProvider.instance().formData;
		const allRegistrationsData = await client.fetchAll();
		return new RegistrationsModel(client, formData, allRegistrationsData);
	}
}


class RegistrationsModel implements IRegistrationsModel
{
	constructor(client: IRegistrationClient, formData: RegistrationFormData, data: AllRegistrationsData)
	{
		this._onCollectionChanged = this._onCollectionChanged.bind(this);

		this._formData = formData;
		this._client = client;
		this._registrations = new ObservableCollection<IRegistrationModel>(data.map(r => new RegistrationModel(client, formData, r)));

		this._registrations.onChanged.subscribe(this._onCollectionChanged);
	}

	static get loader(): Loader
	{
		return RegistrationsModel._loader;
	}

	get onChanged(): IEvent<this>
	{
		return this._onChanged;
	}

	get registrations(): IObservableCollection<IRegistrationModel>
	{
		return this._registrations;
	}

	create(): IRegistrationModel
	{
		const registration = new RegistrationModel(this._client, this._formData);
		this._registrations.add(registration);
		return registration;
	}

	async remove(registration: IRegistrationModel): Promise<void>
	{
		if (registration.isPersisted.value === true)
			await this._client.delete(registration.id.text);

		this._registrations.remove(registration);
	}

	async refresh(): Promise<void>
	{
		const allRegistrationsData = await this._client.fetchAll();
		this._registrations.reset(allRegistrationsData.map(r => new RegistrationModel(this._client, this._formData, r)));
	}

	private _onCollectionChanged(collection: ObservableCollection<IRegistrationModel>): void
	{
		this._onChanged.notify(this, undefined);
	}

	private readonly _formData: RegistrationFormData;
	private readonly _client: IRegistrationClient;
	private readonly _registrations: ObservableCollection<IRegistrationModel>;
	private readonly _onChanged = new Event<this>();
	private static readonly _loader = new Loader();
}


export { RegistrationsModel };
