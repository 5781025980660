import { PureComponent, ReactNode, RefObject, createRef } from 'react';
import { TR, Translator } from '../common/Translator';
import { ComputedFlagModel } from '../models/ComputedFlagModel';
import { FlagModel } from '../models/FlagModel';
import { ICampBetreuerModel } from '../models/ICampBetreuerModel';
import { ICampRegistrationModel } from '../models/ICampRegistrationModel';
import { moveSubscription } from '../models/IEvent';
import { IFlagModel } from '../models/IFlagModel';
import { IObservableCollection } from '../models/IObservableCollection';
import { InvertedFlagModel } from '../models/InvertedFlagModel';
import { Button } from './Button';
import { CampBetreuerEdit } from './CampBetreuerEdit';
import { CheckboxWithModel } from './Checkbox';
import { HideableWithModel } from './Hideable';
import { Label } from './Label';
import { Row } from './Row';


class BetreuerHeader extends PureComponent
{
	constructor(props: {})
	{
		super(props);

		this._onLanguageChanged = this._onLanguageChanged.bind(this);
	}

	render()
	{
		return (
			<Row className="mb-1">
				<Label label={TR(`CampRegistrationForm::Vorname`)} className='d-none d-md-block col-md-3' />
				<Label label={TR(`CampRegistrationForm::Nachname`)} className='d-none d-md-block col-md-3' />
				<Label label={TR(`CampRegistrationForm::Rolle`)} className='d-none d-md-block col-md-3' />
			</Row>
		);
	}

	componentDidMount(): void
	{
		Translator.instance().languageChanged.subscribe(this._onLanguageChanged);
	}

	componentWillUnmount(): void
	{
		Translator.instance().languageChanged.unsubscribe(this._onLanguageChanged);
	}

	private _onLanguageChanged(): void
	{
		this.forceUpdate();
	}
}


class Computed
{
	constructor(registration: ICampRegistrationModel)
	{
		this._isDeleteVisible = new ComputedFlagModel(betreuer => betreuer.items.length > 1, registration.betreuer);
	}

	get isDeleteVisible()
	{
		return this._isDeleteVisible;
	}

	setRegistration(registration: ICampRegistrationModel)
	{
		this._isDeleteVisible.setModel(registration.betreuer);
	}

	private _isDeleteVisible: ComputedFlagModel<IObservableCollection<ICampBetreuerModel>>;
}


type Props = {
	registration: ICampRegistrationModel;
};


class CampBetreuerForm extends PureComponent<Props>
{
	constructor(props: Props)
	{
		super(props);

		this._onBetreuerAdded = this._onBetreuerAdded.bind(this);
		this._onBetreuerRemoved = this._onBetreuerRemoved.bind(this);
		this._onBetreuersChanged = this._onBetreuersChanged.bind(this);
		this._onLanguageChanged = this._onLanguageChanged.bind(this);
		this._withBetreuerChanged = this._withBetreuerChanged.bind(this);

		if (this.props.registration.betreuer.items.length === 0)
			this._withoutBetreuer.setValue(true);

		this._computed = new Computed(this.props.registration);

		this._withBetreuer.onChanged.subscribe(this._withBetreuerChanged);
	}

	render(): ReactNode
	{
		const lastIdx = this.props.registration.betreuer.items.length - 1;
		const betreuers = this.props.registration.betreuer.items.map((b, idx) => {
			// Die Ref nur an das letzte Element übergeben.
			const ref = idx === lastIdx ? this._focusTarget : undefined;
			return (
				<Row key={b.id.text} className="gy-1 mb-1 align-items-center">
					<CampBetreuerEdit ref={ref} betreuer={b} withDelete={this._computed.isDeleteVisible} onDeleted={this._onBetreuerRemoved} />
				</Row>
			);
		});

		return (
			<div className='card border-secondary my-3'>
				<div className='card-header'>{TR('CampRegistrationForm::Angaben zu den Betreuern')}</div>
				<div className='card-body'>
					<Row className="mb-1">
						<div className="col-md-6">
							{_renderCheckbox(TR('CampRegistrationForm::Keine Betreuer'), this._withoutBetreuer)}
						</div>
					</Row>

					<HideableWithModel isShown={this._withBetreuer}>
						<BetreuerHeader />
						{betreuers}
						<Button className='btn btn-outline-secondary' onClicked={this._onBetreuerAdded}>
							{TR('CampRegistrationForm::Betreuer hinzufügen')}
						</Button>
					</HideableWithModel>
				</div>
			</div>
		);
	}

	componentDidMount(): void
	{
		this.props.registration.betreuer.onChanged.subscribe(this._onBetreuersChanged);
		Translator.instance().languageChanged.subscribe(this._onLanguageChanged);
	}

	componentWillUnmount(): void
	{
		this.props.registration.betreuer.onChanged.unsubscribe(this._onBetreuersChanged);
		Translator.instance().languageChanged.unsubscribe(this._onLanguageChanged);
	}

	componentDidUpdate(prevProps: Props)
	{
		this._computed.setRegistration(this.props.registration);
		moveSubscription(prevProps.registration.betreuer.onChanged, this.props.registration.betreuer.onChanged, this._onBetreuersChanged);

		if (this._focusLastOnNextUpdate)
		{
			this._focusTarget.current!.focus();
			this._focusLastOnNextUpdate = false;
		}
	}

	private _onBetreuerAdded()
	{
		this.props.registration.addBetreuer();
		this._focusLastOnNextUpdate = true;
	}

	private _onBetreuerRemoved(betreuer: ICampBetreuerModel)
	{
		this.props.registration.betreuer.remove(betreuer);
		this._focusLastOnNextUpdate = true;
	}

	private _onBetreuersChanged(competitors: IObservableCollection<ICampBetreuerModel>)
	{
		this.forceUpdate();
	}

	private _withBetreuerChanged(model: IFlagModel)
	{
		if (this._withBetreuer.value)
		{
			this.props.registration.addBetreuer();
			this._focusLastOnNextUpdate = true;
		}
		else
			this.props.registration.betreuer.clear();
	}

	private _onLanguageChanged(translator: Translator)
	{
		this.forceUpdate();
	}

	private readonly _focusTarget: RefObject<CampBetreuerEdit> = createRef<CampBetreuerEdit>();
	private _focusLastOnNextUpdate: boolean = false;
	private readonly _withBetreuer: FlagModel = new FlagModel(true);
	private readonly _withoutBetreuer: InvertedFlagModel = new InvertedFlagModel(this._withBetreuer);
	private readonly _computed: Computed;
}


//==============================================================================


function _renderCheckbox(label: string, model: IFlagModel)
{
	return (
		<Row className="align-items-center">
			<Label label={label} className="col-4" />
			<div className="col">
				<CheckboxWithModel type="switch" label="" isChecked={model} />
			</div>
		</Row>
	);
}


export { CampBetreuerForm };
