import { Asserter } from '../common/Asserter';
import { Nullable } from '../common/Optional';
import { ChoiceModel } from './ChoiceModel';
import { Event } from './Event';
import { FlagModel } from './FlagModel';
import { IEvent } from './IEvent';
import { IFlagModel } from './IFlagModel';
import { IModel } from './IModel';
import { TextModel } from './TextModel';


type IndicatorType = 'error' | 'success';


/**
 * Ein Model für den Indicator.
 * Er erlaubt das Setzen einer Error- oder Success-Message.
 */
class IndicatorModel implements IModel
{
	constructor()
	{
		this._onSubmodelChanged = this._onSubmodelChanged.bind(this);

		this._type.onChanged.subscribe(this._onSubmodelChanged);
		this._message.onChanged.subscribe(this._onSubmodelChanged);
	}

	/**
	 * Setzt zu erst den Indicator zurück (reset()),
	 * führt dann die asynchrone action aus und setzt dann
	 * entweder die successMessage oder die errorMessage.
	 */
	async run(action: () => Promise<void>, errorMessage: string, successMessage?: string): Promise<void>
	{
		this.reset();

		try
		{
			await action();
		}
		catch (e)
		{
			this.setError(errorMessage);
			return;
		}

		if (successMessage !== undefined)
			this.setSuccess(successMessage);
	}

	setError(message: string)
	{
		Asserter.assert(message.length > 0, 'Message must not be empty');
		this._type.setSelected('error');
		this._message.setText(message);
		this._isShown.setValue(true);
	}

	setSuccess(message: string)
	{
		Asserter.assert(message.length > 0, 'Message must not be empty');
		this._type.setSelected('success');
		this._message.setText(message);
		this._isShown.setValue(true);
	}

	dismiss(): void
	{
		this._isShown.setValue(false);
	}

	reset(): void
	{
		// Hint: In dem Fall, wo der Text leer ist, ist der Indicator implizit nicht sichtbar.
		// Man muss ihn in diesem Fall also nicht explizit über dismiss() oder isShown() verstecken.
		this._message.setText('');
	}

	get onChanged(): IEvent<this>
	{
		return this._onChanged;
	}

	get type(): IndicatorType
	{
		return this._type.selected as IndicatorType;
	}

	get message(): Nullable<string>
	{
		const message = this._message.text;
		if (message.length === 0)
			return null;
		return message;
	}

	get isShown(): IFlagModel
	{
		return this._isShown;
	}

	private _onSubmodelChanged(model: IModel)
	{
		this._onChanged.notify(this, undefined);
	}

	private readonly _onChanged = new Event<this>();
	private readonly _type = new ChoiceModel(['success', 'error'], 'success');
	private readonly _message = new TextModel('');
	private readonly _isShown = new FlagModel(false);
}


export { IndicatorModel };
export type { IndicatorType };

