import { createRef, FormEvent, PureComponent, ReactNode, RefObject } from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import { Navigate } from 'react-router-dom';
import { AppUserModel } from '../models/AppUserModel';
import { FlagModel } from '../models/FlagModel';
import { moveSubscription } from '../models/IEvent';
import { IFlagModel } from '../models/IFlagModel';
import { IndicatorModel } from '../models/IndicatorModel';
import { ITextModel } from '../models/ITextModel';
import { TextModel } from '../models/TextModel';
import { ButtonWithModel } from './Button';
import { Indicator } from './Indicator';
import { LineEditWithModel } from './LineEdit';
import { Row } from './Row';


/**
 * Hält die TextModels für User und Password und errechnet daraus
 * das FlagModel für den IsValid-Zustand.
 */
class Model
{
	constructor()
	{
		this._onUserInputChanged = this._onUserInputChanged.bind(this);

		this._userName = new TextModel('');
		this._password = new TextModel('');
		this._isValid = new FlagModel(this._getIsValid());

		this._userName.onChanged.subscribe(this._onUserInputChanged);
		this._password.onChanged.subscribe(this._onUserInputChanged);
	}

	get userName(): ITextModel
	{
		return this._userName;
	}

	get password(): ITextModel
	{
		return this._password;
	}

	get isValid(): IFlagModel
	{
		return this._isValid;
	}

	private _getIsValid(): boolean
	{
		return this._userName.text.length >= 3;
	}

	private _onUserInputChanged(): void
	{
		this._isValid.setValue(this._getIsValid());
	}

	private readonly _userName: TextModel;
	private readonly _password: TextModel;
	private readonly _isValid: FlagModel;
}


type Props = {
	user: AppUserModel;
};


class LoginForm extends PureComponent<Props>
{
	constructor(props: Props)
	{
		super(props);

		this._onUserChanged = this._onUserChanged.bind(this);
		this._tryLogin = this._tryLogin.bind(this);

		this._focusTarget = createRef<LineEditWithModel>();
	}

	render(): ReactNode
	{
		if (this.props.user.isLoggedIn)
			return <Navigate to="/profile" />;

		return (
			<Container fluid="lg" className="my-4">
				<Card border="secondary" className="mb-2">
					<Card.Header>
						Login
					</Card.Header>
					<Card.Body>
						<form onSubmit={this._tryLogin} noValidate>
							<Row className='justify-content-center my-2'>
								<div className='col-12 col-sm-6 col-md-5 col-lg-4'>
									<LineEditWithModel ref={this._focusTarget} model={this._model.userName} placeholder='E-Mail' type='email' />
								</div>
							</Row>
							<Row className='justify-content-center my-2'>
								<div className='col-12 col-sm-6 col-md-5 col-lg-4'>
									<LineEditWithModel model={this._model.password} placeholder='Password' type='password' />
								</div>
							</Row>
							<Row className='justify-content-center my-2'>
								<div className='col-12 col-sm-6 col-md-5 col-lg-4'>
									<ButtonWithModel type='submit' className='btn btn-secondary rounded-pill' isEnabled={this._model.isValid}>Log In</ButtonWithModel>
								</div>
							</Row>
						</form>
					</Card.Body>
				</Card>
				<Indicator model={this._backendResult} />
			</Container>
		);
	}

	componentDidMount(): void
	{
		this.props.user.onChanged.subscribe(this._onUserChanged);
		this._focusTarget.current?.focus();
	}

	componentWillUnmount(): void
	{
		this.props.user.onChanged.unsubscribe(this._onUserChanged);
	}

	componentDidUpdate(prevProps: Props): void
	{
		moveSubscription(prevProps.user.onChanged, this.props.user.onChanged, this._onUserChanged);
	}

	private _onUserChanged(appUser: AppUserModel): void
	{
		this.forceUpdate();
	}

	private async _tryLogin(e: FormEvent<HTMLFormElement>): Promise<void>
	{
		e.preventDefault();
		this._backendResult.reset();

		let success: boolean;
		try
		{
			success = await this.props.user.login(this._model.userName.text, this._model.password.text);
		}
		catch (e)
		{
			success = false;
		}

		if (!success)
		{
			this._backendResult.setError('Login failed!');
			this._model.password.setText('');
		}
	}

	private readonly _focusTarget: RefObject<LineEditWithModel>;
	private readonly _model = new Model();
	private readonly _backendResult = new IndicatorModel();
}


export { LoginForm };
