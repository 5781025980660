import { AllCampRegistrationsData } from '../data/AllCampRegistrationsData';
import { CampRegistrationData } from '../data/CampRegistrationData';
import { RegistrationConfirmationData } from '../data/RegistrationConfirmationData';
import { Asserter } from './Asserter';
import { HttpClient, SupportedContentType } from './HttpClient';
import { Nullable } from './Optional';


interface ICampRegistrationClient
{
	post(registration: CampRegistrationData): Promise<CampRegistrationData>;
	update(registration: CampRegistrationData): Promise<CampRegistrationData>;
	fetch(regId: string, email: Nullable<string>): Promise<CampRegistrationData>;
	fetchAll(): Promise<AllCampRegistrationsData>;
	delete(regId: string): Promise<void>;

	/**
	 * @param force sorgt dafür, dass die Confirmation-E-Mail erneut rausgeschickt wird, wenn die
	 * Meldung bereits bestätigt war.
	 * @returns true, wenn die Meldung bestätigt wurde; false, wenn die Meldung bereits bestätigt war.
	 */
	confirm(regId: string, email: Nullable<string>, force: boolean): Promise<boolean>;

	allAsCsv(): Promise<Blob>;
}


class CampRegistrationClient implements ICampRegistrationClient
{
	constructor(client: HttpClient)
	{
		this._client = client;
	}

	async post(registration: CampRegistrationData): Promise<CampRegistrationData>
	{
		// Hmm, warum packen wir die Daten noch mal ein? Historie?
		const data = { meldung: registration };
		const response = await this._client.post('api/camp-registrations', data);
		this._client.checkResponse(response);
		const registrationData = await response.json();
		return registrationData;
	}

	async update(registration: CampRegistrationData): Promise<CampRegistrationData>
	{
		const data = { meldung: registration };
		const response = await this._client.put(`api/camp-registrations/${registration.id}`, data);
		this._client.checkResponse(response);
		const registrationData = await response.json();
		return registrationData;
	}

	async fetch(regId: string, email: Nullable<string>): Promise<CampRegistrationData>
	{
		Asserter.assert(regId.length > 0, 'must not be empty');
		const arg = email !== null ? `?email=${email}` : '';
		const response = await this._client.get(`api/camp-registrations/${regId}${arg}`);
		this._client.checkResponse(response);
		const data = await response.json();
		return data;
	}

	async fetchAll(): Promise<AllCampRegistrationsData>
	{
		const response = await this._client.get('api/camp-registrations');
		this._client.checkResponse(response);
		const data = await response.json();
		return data;
	}

	async delete(regId: string): Promise<void>
	{
		const response = await this._client.delete(`api/camp-registrations/${regId}`);
		this._client.checkResponse(response);
	}

	async confirm(regId: string, email: Nullable<string>, force: boolean): Promise<boolean>
	{
		const confirmationData: RegistrationConfirmationData = {
			email,
			force
		};

		const response = await this._client.post(`api/camp-registrations/${regId}/confirm`, confirmationData);
		this._client.checkResponse(response);

		const wasConfirmedAlready = response.status === 204;
		return !wasConfirmedAlready;
	}

	async allAsCsv(): Promise<Blob>
	{
		const contentType: SupportedContentType = 'text/csv';
		const response = await this._client.get('api/camp-registrations/csv', contentType);
		this._client.checkResponse(response, contentType);
		return await response.blob();
	}

	private readonly _client: HttpClient;
}


export { CampRegistrationClient };
export type { ICampRegistrationClient };
