import { ReactNode } from 'react';


type RenderProp = () => ReactNode;
type ElementOrRenderProp = ReactNode | RenderProp;


function render(content: ElementOrRenderProp): ReactNode
{
	if (content instanceof Function)
		return content();
	return content;
}


export { render };
export type { ElementOrRenderProp, RenderProp };
