import { PureComponent } from 'react';


type Props = {
	className?: string;
};


/**
 * Ein Spinner mit Bootstrap-Hilfsmitteln (CSS only).
 */
class Spinner extends PureComponent<Props>
{
	public render()
	{
		let classes = 'spinner-grow';
		if (this.props.className !== undefined)
			classes += ` ${this.props.className}`;

		return (
			<div className={classes}>
				<span className="visually-hidden">Loading</span>
			</div>
		)
	}
}


export { Spinner };
