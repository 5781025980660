import { createRef, PureComponent, ReactNode, RefObject } from 'react';
import { Link } from 'react-router-dom';
import { Nullable } from '../common/Optional';
import { TR, Translator } from '../common/Translator';
import { ShortFormData } from '../data/RegistrationFormData';
import { ICampRegistrationModel } from '../models/ICampRegistrationModel';
import { IFlagModel } from '../models/IFlagModel';
import { IValidatedChoiceModel } from '../models/IValidatedChoiceModel';
import { IValidatedTextModel } from '../models/IValidatedTextModel';
import { MappedValidatedChoiceModel } from '../models/MappedValidatedChoiceModel';
import { ShortFormMapper } from '../models/ShortFormMapper';
import { CheckboxWithModel } from './Checkbox';
import { Hideable } from './Hideable';
import { Label } from './Label';
import { Row } from './Row';
import { ValidatedComboboxWithModel } from './ValidatedCombobox';
import { ValidatedLineEditWithModel } from './ValidatedLineEdit';


type Props = {
	registration: ICampRegistrationModel;
	withDetails: boolean;
};


/**
 * Formularanteil für den Meldenden bei der Camp-Registrierung.
 */
class CampRegistreeForm extends PureComponent<Props>
{
	constructor(props: Props)
	{
		super(props);

		this._onLanguageChanged = this._onLanguageChanged.bind(this);

		this._focusTarget = createRef<ValidatedLineEditWithModel>();

		const nationMapper = new ShortFormMapper(this.props.registration.nation.choices);
		this._nation = new MappedValidatedChoiceModel(this.props.registration.nation, nationMapper);
	}

	render(): ReactNode
	{
		return (
			<>
				<Hideable isShown={this.props.withDetails}>
					<div className="card border-secondary my-3">
						<div className="card-body">
							<Row className="mb-1">
								<div className="col-md-6">
									{_renderLineEdit(`${TR('CampRegistrationForm::ID')}:`, this.props.registration.id, false)}
								</div>
							</Row>
							<Row className="mb-1">
								<div className="col-md-6">
									{_renderLineEdit(`${TR('CampRegistrationForm::Eingegangen')}:`, this.props.registration.eingegangen)}
								</div>
							</Row>
							<Row>
								<div className="col-md-6">
									{_renderCheckbox(`${TR('CampRegistrationForm::Bestätigt')}:`, this.props.registration.isConfirmed)}
								</div>
							</Row>
							<Row>
								<div className="col-md-6">
									{_renderLink('Link:', this.props.registration)}
								</div>
							</Row>
						</div>
					</div>
				</Hideable>

				<div className='card border-secondary my-3'>
					<div className='card-header'>{TR('CampRegistrationForm::Wer meldet')}</div>
					<div className='card-body'>
						<Row className="gy-1">
							<div className='col-md-6 order-md-0'>
								{_renderLineEdit(`${TR('CampRegistrationForm::Vorname')}:`, this.props.registration.vorname, undefined, this._focusTarget)}
							</div>
							<div className='col-md-6 order-md-2' >
								{_renderLineEdit(`${TR('CampRegistrationForm::Nachname')}:`, this.props.registration.nachname)}
							</div>
							<div className='col-md-6 order-md-4'>
								{_renderLineEdit(`${TR('CampRegistrationForm::E-Mail')}:`, this.props.registration.email)}
							</div>
							<div className='col-md-6 order-md-1'>
								{_renderLineEdit(`${TR('CampRegistrationForm::Verein')}:`, this.props.registration.verein)}
							</div>
							<div className='col-md-6 order-md-3'>
								{_renderCombobox(`${TR('CampRegistrationForm::Nation')}:`, this._nation)}
							</div>
						</Row>
					</div>
				</div>

			</>
		);
	}

	componentDidMount(): void
	{
		this._focusTarget.current!.focus();

		Translator.instance().languageChanged.subscribe(this._onLanguageChanged);
	}

	componentWillUnmount(): void
	{
		Translator.instance().languageChanged.unsubscribe(this._onLanguageChanged);
	}

	componentDidUpdate(prevProps: Props): void
	{
		// Der Mapper bleibt konstant.
		this._nation.setModels(this.props.registration.nation);
	}

	private _onLanguageChanged(translator: Translator)
	{
		this.forceUpdate();
	}

	private _focusTarget: RefObject<ValidatedLineEditWithModel>;
	private _nation: MappedValidatedChoiceModel<Nullable<ShortFormData>>;
}


//==============================================================================


function _renderLineEdit(label: string, model: IValidatedTextModel, isEnabled?: boolean, ref?: RefObject<ValidatedLineEditWithModel>)
{
	return (
		<Row className="align-items-center">
			<Label label={label} className="col-4" />
			<div className="col">
				<ValidatedLineEditWithModel ref={ref} model={model} isEnabled={isEnabled}/>
			</div>
		</Row>
	);
}

function _renderCombobox(label: string, model: IValidatedChoiceModel)
{
	return (
		<Row className="align-items-center">
			<Label label={label} className="col-4" />
			<div className="col">
				<ValidatedComboboxWithModel model={model} />
			</div>
		</Row>
	);
}

function _renderCheckbox(label: string, model: IFlagModel)
{
	return (
		<Row className="align-items-center">
			<Label label={label} className="col-4" />
			<div className="col">
				<CheckboxWithModel type="switch" label="" isChecked={model} />
			</div>
		</Row>
	);
}

function _renderLink(label: string, registration: ICampRegistrationModel)
{
	const path = `/camp-registration/${registration.id.text}?email=${registration.email.text}`;
	return (
		<Row className="align-items-center">
			<Label label={label} className="col-4" />
			<div className="col">
				<Link to={path}>{path}</Link>
			</div>
		</Row>
	);
}

export { CampRegistreeForm };
