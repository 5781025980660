import { Component, MouseEvent, ReactNode } from 'react';
import { FaRegCheckCircle, FaRegCircle } from 'react-icons/fa';
import { arePropsEqual, getValidChangedIndicatorPostfix } from '../common/utils';
import { ComputedChoiceModel } from '../models/ComputedChoiceModel';
import { ComputedFlagModel } from '../models/ComputedFlagModel';
import { ComputedTextModel } from '../models/ComputedTextModel';
import { FlagModel } from '../models/FlagModel';
import { ICampRegistrationModel } from '../models/ICampRegistrationModel';
import { IChoiceModel } from '../models/IChoiceModel';
import { IFlagModel } from '../models/IFlagModel';
import { ITextModel } from '../models/ITextModel';
import { CalloutWithModel } from './Callout';
import { CampRegistrationForm } from './CampRegistrationForm';
import { ContentSwitchWithModel } from './ContentSwitch';
import { Dropdown } from './Dropdown';
import { DropdownItem } from './DropdownItem';
import { HideableWithTransition } from './Hideable';
import { LabelWithModel } from './Label';


class Computed
{
	constructor(registration: ICampRegistrationModel)
	{
		this._teilnehmerCount = new ComputedTextModel(Computed._getTeilnehmerCount, registration);
		this._betreuerCount = new ComputedTextModel(Computed._getBetreuerCount, registration);
		this._nameAndEmail = new ComputedTextModel(r => `${r.vorname.text} ${r.nachname.text} <${r.email.text}>`, registration);
		this._indicator = new ComputedChoiceModel(Computed._getIndicator, ['-danger', '-success', ''], registration);
		this._isSaveable = new ComputedFlagModel(r => r.isValid.value === true && r.wasChanged.value === true, registration);
		this._isRefreshable = new ComputedFlagModel(r => r.isPersisted.value === true, registration);
	}

	get teilnehmerCount(): ITextModel { return this._teilnehmerCount; }
	get betreuerCount(): ITextModel   { return this._betreuerCount; }
	get nameAndEmail(): ITextModel    { return this._nameAndEmail; }
	get indicator(): IChoiceModel     { return this._indicator }
	get isSaveable(): IFlagModel      { return this._isSaveable; }
	get isRefreshable(): IFlagModel   { return this._isRefreshable; }

	setRegistration(registration: ICampRegistrationModel): void
	{
		[
			this._teilnehmerCount,
			this._betreuerCount,
			this._nameAndEmail,
			this._indicator,
			this._isSaveable,
			this._isRefreshable,
		].forEach(m => m.setModel(registration));
	}

	private static _getTeilnehmerCount(registration: ICampRegistrationModel): string
	{
		return registration.competitors.items.length.toString();
	}

	private static _getBetreuerCount(registration: ICampRegistrationModel): string
	{
		return registration.betreuer.items.length.toString();
	}

	/**
	 * Der Indicator soll anzeigen, ob eine Meldung valide ist oder geändert wurde.
	 */
	private static _getIndicator(registration: ICampRegistrationModel): string
	{
		return getValidChangedIndicatorPostfix(
			registration.isValid.value,
			registration.wasChanged.value
		);
	}

	private readonly _teilnehmerCount: ComputedTextModel<ICampRegistrationModel>;
	private readonly _betreuerCount: ComputedTextModel<ICampRegistrationModel>;
	private readonly _nameAndEmail: ComputedTextModel<ICampRegistrationModel>;
	private readonly _indicator: ComputedChoiceModel<ICampRegistrationModel>;
	private readonly _isSaveable: ComputedFlagModel<ICampRegistrationModel>;
	private readonly _isRefreshable: ComputedFlagModel<ICampRegistrationModel>;
}


//==============================================================================


type Props = {
	registration: ICampRegistrationModel;
	onSave?: (registration: ICampRegistrationModel) => void;
	onDelete?: (registration: ICampRegistrationModel) => void;
	onConfirm?: (registration: ICampRegistrationModel) => void;
	onRefresh?: (registration: ICampRegistrationModel) => void;
	onSwapVornameNachname?: (registration: ICampRegistrationModel) => void;
	onDuplicate?: (registration: ICampRegistrationModel) => void;
}


class CampRegistrationsOverviewRow extends Component<Props>
{
	constructor(props: Props)
	{
		super(props);

		this._onClicked = this._onClicked.bind(this);
		this._onSaveClicked = this._onSaveClicked.bind(this);
		this._onRefreshClicked = this._onRefreshClicked.bind(this);
		this._onDeleteClicked = this._onDeleteClicked.bind(this);
		this._onConfirmClicked = this._onConfirmClicked.bind(this);
		this._onSwapVornameNachname = this._onSwapVornameNachname.bind(this);
		this._onDuplicateClicked = this._onDuplicateClicked.bind(this);

		this._computed = new Computed(this.props.registration);
	}

	render(): ReactNode
	{
		const cursorStyle = {
			cursor: 'pointer'
		};

		return (
			<>
				<CalloutWithModel className="d-flex mt-2 p-2 bg-white rounded" indicatorModel={this._computed.indicator} >
					<div className="flex-grow-1" style={cursorStyle} onClick={this._onClicked}>
						<div className="d-flex justify-content-between align-items-center">
							<div>
								<LabelWithModel model={this.props.registration.id} className="badge rounded-pill bg-primary" />
								<LabelWithModel model={this._computed.teilnehmerCount} className="ms-2 badge rounded-pill bg-secondary" />
								<LabelWithModel model={this._computed.betreuerCount} className="ms-2 badge rounded-pill bg-secondary" />
								<ContentSwitchWithModel
									isOn={this.props.registration.isConfirmed}
									contentWhenOn={<FaRegCheckCircle size="1.1em" className="ms-2 text-success" />}
									contentWhenOff={<FaRegCircle size="1.1em" className="ms-2 text-warning" />}
								/>
							</div>
							<LabelWithModel model={this.props.registration.eingegangen} />
						</div>
						<div className="d-flex justify-content-between align-items-center">
							<LabelWithModel model={this.props.registration.verein} />
						</div>
					</div>
					<div className="ms-3">
						<Dropdown iconSize="1.3em">
							{_renderButton('Bestätigen', this._onConfirmClicked, this.props.registration.isPersisted)}
							{_renderButton('Speichern', this._onSaveClicked, this._computed.isSaveable)}
							{_renderButton('Refresh', this._onRefreshClicked, this._computed.isRefreshable)}
							{_renderButton('Löschen', this._onDeleteClicked)}
							{_renderButton('Vorname Nachname tauschen', this._onSwapVornameNachname)}
							{_renderButton('Duplizieren', this._onDuplicateClicked)}
						</Dropdown>
					</div>
				</CalloutWithModel>

				<HideableWithTransition isShown={this._areDetailsVisible}>
					<CampRegistrationForm registration={this.props.registration} withPreFill={false} withDetails />
				</HideableWithTransition>
			</>
		);
	}

	shouldComponentUpdate(nextProps: Props): boolean
	{
		return !arePropsEqual(this.props, nextProps, ['registration']);
	}

	componentDidUpdate(prevProps: Props): void
	{
		this._computed.setRegistration(this.props.registration);
	}

	private _onClicked(event: MouseEvent<HTMLDivElement>): void
	{
		this._areDetailsVisible.toggle();
	}

	private _onSaveClicked(): void
	{
		this.props.onSave?.call(undefined, this.props.registration);
	}

	private _onRefreshClicked(): void
	{
		this.props.onRefresh?.call(undefined, this.props.registration);
	}

	private _onDeleteClicked(): void
	{
		this.props.onDelete?.call(undefined, this.props.registration);
	}

	private _onConfirmClicked(): void
	{
		this.props.onConfirm?.call(undefined, this.props.registration);
	}

	private _onSwapVornameNachname(): void
	{
		this.props.onSwapVornameNachname?.call(undefined, this.props.registration);
	}

	private _onDuplicateClicked(): void
	{
		this.props.onDuplicate?.call(undefined, this.props.registration);
	}

	private readonly _computed: Computed;
	private readonly _areDetailsVisible = new FlagModel(false);
}


function _renderButton(label: string, handler: () => void, isEnabled?: IFlagModel): ReactNode
{
	return <DropdownItem label={label} onClicked={handler} isEnabled={isEnabled} />;
}


export { CampRegistrationsOverviewRow };
