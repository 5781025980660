import { PureComponent, ReactNode } from 'react';
import { Asserter } from '../common/Asserter';
import { FetchGuard } from '../common/FetchGuard';
import { INewstickerClient } from '../common/NewstickerClient';
import { Nullable } from '../common/Optional';
import { renderElementList } from '../common/utils';
import { NewstickerEntryModel } from '../models/NewstickerEntryModel';
import { BusyWithModel } from './Busy';
import { NewstickerEntry } from './NewstickerEntry';


type Props = {
	newstickerClient: INewstickerClient;
}


type State = {
	entries: Nullable<NewstickerEntryModel[]>;
}


/**
 * Diese Komponente wird für die Anzeige des Newstickers auf der Home-Page verwendet.
 */
class Newsticker extends PureComponent<Props, State>
{
	constructor(props: Props)
	{
		super(props);

		this.state = { entries: null };

		this._renderContent = this._renderContent.bind(this);
	}

	render(): ReactNode
	{
		return <BusyWithModel isBusy={this._fetchGuard.isBusy} render={this._renderContent} />;
	}

	componentDidMount(): Promise<void>
	{
		return this._fetchGuard.fetch(() => this._fetchNewsticker());
	}

	private _renderContent(): ReactNode
	{
		Asserter.assert(this.state.entries !== null, 'must not be called when busy');

		return renderElementList(this.state.entries.map(e => <NewstickerEntry model={e} />));
	}

	private async _fetchNewsticker(): Promise<void>
	{
		const entriesData = await this.props.newstickerClient.fetchAll();
		const models = entriesData.map(d => new NewstickerEntryModel(this.props.newstickerClient, d));

		this.setState({ entries: models });
	}

	private readonly _fetchGuard = new FetchGuard();
}


export { Newsticker };
