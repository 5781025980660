import { Optional } from '../common/Optional';
import { Event } from './Event';
import { IEvent } from './IEvent';
import { IModel } from './IModel';


class FileModel implements IModel
{
	get file(): Optional<File>
	{
		return this._file;
	}

	setFile(value: Optional<File>)
	{
		this._file = value;
		this._onChanged.notify(this, undefined);
	}

	get onChanged(): IEvent<this>
	{
		return this._onChanged;
	}

	private _file: Optional<File>;
	private _onChanged = new Event<this>();
}


export { FileModel };
