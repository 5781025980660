import { Asserter } from '../common/Asserter';
import { Nullable } from '../common/Optional';
import { ShortFormData } from '../data/RegistrationFormData';
import { Event } from './Event';
import { IEvent } from './IEvent';
import { IUiModelDataMapper } from './MappedValidatedChoiceModel';


/**
 * Der Mapper wird für das Mappen der Verbände und der Nationen verwendet.
 */
class ShortFormMapper implements IUiModelDataMapper<Nullable<ShortFormData>>
{
	constructor(items: Readonly<Nullable<ShortFormData>[]>, invalidEntry: string = INVALID_ENTRY)
	{
		this._items = items;
		this._invalidEntry = invalidEntry;
	}

	get onChanged(): IEvent<this>
	{
		return this._onChanged;
	}

	fromUi(item: string): Nullable<ShortFormData>
	{
		if (item === INVALID_ENTRY)
			return null;

		const found = this._items.find(v => v?.name === item);
		Asserter.assert(found !== undefined, 'inconsistency');
		return found;
	}

	toUi(item: Nullable<ShortFormData>): string
	{
		if (item === null)
			return this._invalidEntry;

		return item.name;
	}

	private _items: Readonly<Nullable<ShortFormData>[]>;
	private _invalidEntry: string;
	private _onChanged = new Event<this>();
}


const INVALID_ENTRY = '------';


export { ShortFormMapper };
