import { PureComponent, Suspense } from 'react';
import { Outlet } from "react-router-dom";
import { Footer } from './Footer';
import { Navbar } from './Navbar';
import { Spinner } from './Spinner';
import { AppUserModel } from '../models/AppUserModel';
import { ConfigModel } from '../models/ConfigModel';


type Props = {
	user: AppUserModel;
	config: ConfigModel;
};


/**
 * Der HomeContainer ist sozusagen der Container für alle Pages, die wir
 * anzeigen. Die Inhalte der dedizierten Pages (bzw. Routes) wird an Stelle
 * des Outlet-Elements angezeigt.
 */
class HomeContainer extends PureComponent<Props>
{
	public render()
	{
		return (
			// Suspense, damit wir dann im Outlet React.lazy() verwenden können.
			<Suspense fallback={<Spinner />}>
				<Navbar user={this.props.user} config={this.props.config} />
				<Outlet />
				<Footer />
			</Suspense>
		);
	}
}


export { HomeContainer };
