import { PureComponent, ReactNode } from 'react';
import Card from 'react-bootstrap/Card';
import { Asserter } from '../common/Asserter';
import { generateRange, gkString, renderElementList } from '../common/utils';
import { CompetitorDistributionData, CompetitorDistributionNationData } from '../data/TournamentStatisticsData';
import { Flag } from './Flag';


type Props = {
	data: CompetitorDistributionData;
	ageGroup?: string;
};


class CompetitorDistribution extends PureComponent<Props>
{
	render(): ReactNode
	{
		const rows = renderElementList(this.props.data.ranking.map(d => {
			Asserter.assert(d.categoryCount.length === this.props.data.categories.length, 'inconsistency');
			return this._renderNationRow(d);
		}));

		return (
			<Card border="secondary" className="my-3">
				<Card.Header className="bg-secondary text-light text-center fw-bold">
					Competitor Distribution {this.props.ageGroup ?? ''}
				</Card.Header>

				<Card.Body>
					<table style={{ tableLayout: 'fixed' }} className="table table-sm table-striped text-center text-nowrap">
						{this._renderHeader(this.props.data.categories)}
						<tbody className="table-group-divider">
							{rows}
						</tbody>
						<tfoot className="table-group-divider">
							{this._renderTotalRow()}
						</tfoot>
					</table>
				</Card.Body>
			</Card>
		);
	}

	_renderHeader(categories: number[])
	{
		const headerCells = renderElementList(categories.map(c => (
			<th scope="col">
				{gkString(c)}
			</th>
		)));

		return (
			<thead>
				<tr>
					<th className="d-none d-md-table-cell" />
					<th />
						{headerCells}
					<th scope="col">Total</th>
				</tr>
			</thead>
		);
	}

	_renderNationRow(data: CompetitorDistributionNationData)
	{
		const cells = renderElementList(data.categoryCount.map(c => <td>{c}</td>));
		const totalCount = data.categoryCount.reduce(_sum, 0);

		return (
			<tr>
				<td className="d-none d-md-table-cell"><Flag nation={data.nation} /></td>
				<td>{data.nation}</td>
				{cells}
				<td className="fw-bold">
					{totalCount}
				</td>
			</tr>
		);
	}

	_renderTotalRow()
	{
		const nCategories = this.props.data.categories.length;
		const totals = [...generateRange(nCategories)].map(idx => this._getCategoryTotal(idx));
		const totalTotal = totals.reduce(_sum, 0);

		const cells = renderElementList(totals.map(t => (
			<td className="">
				{t}
			</td>
		)));

		return (
			<tr className="fw-bold">
				<td className="d-table-cell d-md-none">Total</td>
				<td colSpan={2} className="d-none d-md-table-cell">Total</td>
				{cells}
				<td>{totalTotal}</td>
			</tr>
		);
	}

	_getCategoryTotal(idx: number)
	{
		return this.props.data.ranking.map(d => d.categoryCount[idx]).reduce(_sum, 0);
	}
}


/**
 * Reducer-Funktion zum Aufsummieren der Werte eines Arrays.
 */
function _sum(prevValue: number, currentValue: number): number
{
	return prevValue + currentValue;
}


export { CompetitorDistribution };
