import { Asserter } from '../common/Asserter';
import { AppUserData } from '../data/AppUserData';
import { IModel } from './IModel';
import { IEvent } from './IEvent';
import { Event } from './Event';
import { IBackend } from '../common/IBackend';
import { Nullable } from '../common/Optional';


const STORAGE_KEY = 'appUser';


type UserRole = 'admin';


class AppUserModel implements IModel
{
	constructor(backend: IBackend)
	{
		this._data = null;
		this._backend = backend;
	}

	get onChanged(): IEvent<this>
	{
		return this._onChanged;
	}

	async login(email: string, password: string)
	{
		Asserter.assert(this._data === null, 'A User is still logged in');

		const loginResponse = await this._backend.login(email, password);
		if (!loginResponse)
			return false;

		this._setState({
			userName: email,
			token: loginResponse.token,
			isAdmin: loginResponse.isAdmin,
		});
		this._persist();

		return true;
	}

	logout()
	{
		Asserter.assert(this._data !== null, 'No User logged in');

		this._setState(null);
		this._unpersist();
	}

	get isLoggedIn()
	{
		return this._data !== null;
	}

	get email()
	{
		Asserter.assert(this._data, 'User must be logged in');
		return this._data.userName;
	}

	get isAdmin()
	{
		if (!this._data)
			return false;
		return this._data.isAdmin;
	}

	get token(): string
	{
		Asserter.assert(this._data, 'User must be logged in');
		return this._data.token;
	}

	hasRole(role: UserRole)
	{
		switch(role)
		{
			case 'admin':
				return this.isAdmin;
		}
	}

	/**
	 * Versucht zuvor persistierte User-Informationen wieder herzustellen.
	 */
	restore()
	{
		Asserter.assert(this._data === null, 'must not be logged in');

		const appUserJson = localStorage.getItem(STORAGE_KEY);
		if (appUserJson === null)
			return; // bisher nicht persistiert

		this._setState(JSON.parse(appUserJson));
	}

	/**
	 * Persistiert die User-Informationen im LocalStorage.
	 */
	private _persist()
	{
		Asserter.assert(this._data, 'must be logged in');

		localStorage.setItem(STORAGE_KEY, JSON.stringify(this._data));
	}

	/**
	 * Löscht ggf. vorhandene User-Informationen im LocalStorage.
	 */
	private _unpersist()
	{
		localStorage.removeItem(STORAGE_KEY);
	}

	private _setState(newState: Nullable<AppUserData>)
	{
		this._data = newState;

		if (this._data)
			this._backend.setToken(this._data.token);
		else
			this._backend.unsetToken();

		this._onChanged.notify(this, undefined);
	}

	private _data: Nullable<AppUserData>; // null, wenn nicht eingeloggt
	private _backend: IBackend;
	private _onChanged = new Event<this>();
}


export { AppUserModel };
export type { UserRole };
