import { PureComponent } from 'react';
import { Nullable } from '../common/Optional';


type Props = {
	nation: string; // in Kurzform
};


type State = {
	src: Nullable<string>;
};

/**
 * Hier sind Flaggen in verschiedenen Größen und guter Auflösung zu finden:
 * https://www.countryflags.com
 */
class Flag extends PureComponent<Props, State>
{
	constructor(props: Props)
	{
		super(props);

		this.state = {
			src: null,
		};
	}

	render()
	{
		if (this._isLoading)
			return null;

		return <img className="border border-dark" src={this.state.src!} alt={this.props.nation} />;
	}

	async componentDidMount()
	{
		const imageModule = await import(
			/* webpackInclude: /f_.*\.jpg$/ */
			/* webpackChunkName: "flag_" */
			/* webpackMode: "lazy" */
			`../resources/f_${this.props.nation}.jpg`
		);

		if (this._isLoading)
			this.setState({ src: imageModule.default });
	}

	private get _isLoading()
	{
		return this.state.src === null;
	}
}


export { Flag };
