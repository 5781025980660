import React, { Fragment, ReactNode } from 'react';
import { Asserter } from './Asserter';

/**
 * Hilfsfunktion für die Verwendung in Component::componentShouldUpdate().
 * Dort müssen alte Props mit neuen Props verglichen werden.
 * Wir überprüfen hier genau die Properties, die explizit zum Vergleich
 * angefordert wurden.
 */
function arePropsEqual<T extends {}>(lhs: T, rhs: T, props: (keyof T)[])
{
	if (lhs === rhs)
		return true;

	return props.every(p => lhs[p] === rhs[p]);
}


function areArraysEqual<T>(lhs: Readonly<T[]>, rhs: Readonly<T[]>)
{
	if (lhs === rhs)
		return true;

	if (lhs.length !== rhs.length)
		return false;

	return lhs.every((e, idx) => e === rhs[idx]);
}

/**
 * Generiert count Elemente durch Aufruf der factory.
 */
function* generate<T>(count: number, factory: (idx: number) => T)
{
	for (let i = 0; i < count; i++)
		yield factory(i);
}

function generateRange(stop: number): Generator<number, void, unknown>;
function generateRange(start: number, stop: number): Generator<number, void, unknown>;
function generateRange(start: number, stop: number, step: number): Generator<number, void, unknown>;
function generateRange(startOrStop: number, stop?: number, step: number = 1)
{
	if (stop !== undefined)
		return _generateRange(startOrStop, stop, step);

	return _generateRange(0, startOrStop, step);
}

function* _generateRange(start: number, stop: number, step: number)
{
	for (let i = start; i < stop; i += step)
		yield i;
}


/**
 * Rendert ein Array von ReactNodes und umgeht dabei, dass man für jedes Element
 * im Array einen Key angeben muss.
 */
function renderElementList(elements: ReactNode[]): ReactNode
{
	return React.createElement(Fragment, undefined, ...elements);
}


/**
 * @param deltaT in ms
 */
function sleep(deltaT: number): Promise<void>
{
	return new Promise((resolve) => {
		setTimeout(resolve, deltaT);
	});
}


function cmpNumber(lhs: number, rhs: number)
{
	if (lhs === rhs)
		return 0;
	if (lhs < rhs)
		return -1;
	return 1;
}


/**
 * Vergleich zweier Gewichtsklassen.
 */
function cmpGk(lgk: number, rgk: number)
{
	const absCmp = cmpNumber(Math.abs(lgk), Math.abs(rgk));
	if (absCmp !== 0)
		return absCmp;

	return cmpNumber(lgk, rgk);
}


function gkString(gk: number)
{
	if (gk < 0)
		return `${gk}kg`;
	else
		return `+${gk}kg`;
}


function stringToInt(asString: string): number
{
	const number = Number.parseFloat(asString);
	if (Number.isNaN(number))
		throw new Error(`Not an Int: ${asString}`);

	const int = Math.floor(number);
	if (int !== number)
		throw new Error(`Not an Int: ${asString}`);

	return int;
}


/**
 * Konvertiert einen ISO-formatierten String in eine Date-Instanz.
 * Das klappt auch, wenn z.B. die Sekunden im String fehlen.
 */
function isoStringToDate(value: string): Date
{
	const time = Date.parse(value); // ms seit Epoch
	Asserter.assert(!Number.isNaN(time), 'invalid date');
	return new Date(time);
}


/**
 * Liefert eine String-Repräsentierung des Dates (inkl. Zeit) in ISO8601-Format
 * mit der Timezone, die in der Date-Instanz hinterlegt ist.
 * In der Date-Instanz ist wohl immer die lokale Zeitzone hinterlegt.
 * Im Gegensatz dazu liefert Date.toISOString() immer UTC-Zeitzone.
 */
function dateToIsoTz(date: Date): string
{
	function pad(n: number)
	{
		return n.toString().padStart(2, '0');
	}

	const tzOffset = -date.getTimezoneOffset(); // in Minuten
	const tzHours = pad(tzOffset / 60);
	const tzMinutes = pad(tzOffset % 60);
	const tzSign = tzOffset < 0 ? '-' : '+';

	const year = pad(date.getFullYear());
	const month = pad(date.getMonth() + 1);
	const day = pad(date.getDate());
	const hours = pad(date.getHours());
	const minutes = pad(date.getMinutes());
	const seconds = pad(date.getSeconds());

	return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${tzSign}${tzHours}:${tzMinutes}`;
}


/**
 * Wird verwendet, um aus der Kombination von isValid- und wasChanged-Flag das Postfix
 * für bootstrap-Klassen zu ermitteln.
 */
function getValidChangedIndicatorPostfix(isValid: boolean, wasChanged: boolean): string
{
	if (!isValid)
		return '-danger';

	if (wasChanged)
		return '-success';

	return '';
}


export {
	arePropsEqual,
	areArraysEqual,
	generate,
	generateRange,
	renderElementList,
	sleep,
	cmpNumber,
	cmpGk,
	gkString,
	stringToInt,
	isoStringToDate,
	dateToIsoTz,
	getValidChangedIndicatorPostfix
};
