import { NewstickerData, NewstickerDataConverter, NewstickerEntryData, NewstickerEntryServerData, NewstickerServerData } from '../data/NewstickerData';
import { Asserter } from './Asserter';
import { HttpClient } from './HttpClient';


interface INewstickerClient
{
	fetchAll(): Promise<NewstickerData>;
	fetch(entryId: number): Promise<NewstickerEntryData>;
	post(entry: NewstickerEntryData): Promise<NewstickerEntryData>;
	update(entry: NewstickerEntryData): Promise<NewstickerEntryData>;
	delete(entryId: number): Promise<void>;
}


class NewstickerClient implements INewstickerClient
{
	constructor(client: HttpClient)
	{
		this._client = client;
	}

	async fetchAll(): Promise<NewstickerData>
	{
		const response = await this._client.get('api/newsticker');
		this._client.checkResponse(response);
		const serverData = await response.json() as NewstickerServerData;
		return NewstickerDataConverter.fromServer(serverData);
	}

	async fetch(entryId: number): Promise<NewstickerEntryData>
	{
		const response = await this._client.get(`api/newsticker/${entryId}`);
		this._client.checkResponse(response);
		const serverData = await response.json() as NewstickerEntryServerData;
		return NewstickerDataConverter.entryFromServer(serverData);
	}

	async post(entry: NewstickerEntryData): Promise<NewstickerEntryData>
	{
		const postData = NewstickerDataConverter.entryToServer(entry);
		const response = await this._client.post('api/newsticker', postData);
		this._client.checkResponse(response);
		const createdEntry = await response.json() as NewstickerEntryServerData;
		return NewstickerDataConverter.entryFromServer(createdEntry);
	}

	async update(entry: NewstickerEntryData): Promise<NewstickerEntryData>
	{
		Asserter.assert(entry.id !== null, 'need a persisted entry');
		const putData = NewstickerDataConverter.entryToServer(entry);
		const response = await this._client.put(`api/newsticker/${entry.id}`, putData);
		this._client.checkResponse(response);
		const updatedEntry = await response.json() as NewstickerEntryServerData;
		return NewstickerDataConverter.entryFromServer(updatedEntry);
	}

	async delete(entryId: number): Promise<void>
	{
		const response = await this._client.delete(`api/newsticker/${entryId}`);
		this._client.checkResponse(response);
	}

	private _client: HttpClient;
}


export type { INewstickerClient };
export { NewstickerClient };

