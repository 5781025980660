import { PureComponent, ReactNode } from 'react';
import { moveSubscription } from '../models/IEvent';
import { IndicatorModel } from '../models/IndicatorModel';
import { Button } from './Button';
import { HideableWithModel } from './Hideable';


type Props = {
	model: IndicatorModel;
	className?: string;
};


/**
 * Zeigt Text in einer Callout-Box an.
 * Ich verwende das, um z.B. das Ergebnis (Erfolg oder Fehler) eines Backend-Calls
 * anzuzeigen, ohne die Seite zu verlassen.
 * Der User kann die Meldung dann schließen.
 * Wenn der Text leer ist, wird kein Indicator angezeigt.
 */
class Indicator extends PureComponent<Props>
{
	constructor(props: Props)
	{
		super(props);

		this._onModelChanged = this._onModelChanged.bind(this);
		this._onDismissClicked = this._onDismissClicked.bind(this);
	}

	render(): ReactNode
	{
		if (this.props.model.message === null)
			return null;

		let calloutClass: string;
		switch (this.props.model.type)
		{
			case 'success':
				calloutClass = 'callout-success';
				break;
			case 'error':
				calloutClass = 'callout-danger';
				break;
		}

		const className = `${calloutClass} rounded bg-white p-2 d-flex align-items-center ${this.props.className}`;

		return (
			<HideableWithModel isShown={this.props.model.isShown}>
				<div className={className}>
					<Button onClicked={this._onDismissClicked} className="btn-close" />
					<div className="ms-2" dangerouslySetInnerHTML={{ __html: this.props.model.message}} />
				</div>
			</HideableWithModel>
		);
	}

	componentDidMount(): void
	{
		this.props.model.onChanged.subscribe(this._onModelChanged);
	}

	componentWillUnmount(): void
	{
		this.props.model.onChanged.unsubscribe(this._onModelChanged);
	}

	componentDidUpdate(prevProps: Props): void
	{
		moveSubscription(prevProps.model.onChanged, this.props.model.onChanged, this._onModelChanged);
	}

	private _onModelChanged(model: IndicatorModel): void
	{
		this.forceUpdate();
	}

	private _onDismissClicked(): void
	{
		this.props.model.dismiss();
	}
}


export { Indicator };
