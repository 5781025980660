import { PureComponent, ReactNode, RefObject, createRef } from 'react';
import { Nullable } from '../common/Optional';
import { TR, Translator } from '../common/Translator';
import { ComputedFlagModel } from '../models/ComputedFlagModel';
import { ICampCompetitorModel } from '../models/ICampCompetitorModel';
import { IFlagModel } from '../models/IFlagModel';
import { IUiModelDataMapper, MappedValidatedChoiceModel } from '../models/MappedValidatedChoiceModel';
import { Button } from './Button';
import { HideableWithModel } from './Hideable';
import { Label } from './Label';
import { ValidatedComboboxWithFlagModel } from './ValidatedCombobox';
import { ValidatedLineEditWithModel } from './ValidatedLineEdit';
import { Event } from '../models/Event';
import { IEvent } from '../models/IEvent';


class Computed
{
	constructor(competitor: ICampCompetitorModel)
	{
		this._isAgeGroupEnabled = new ComputedFlagModel<ICampCompetitorModel>(c => c.altersklasse.choices.length > 1, competitor);
	}

	isAgeGroupEnabled()
	{
		return this._isAgeGroupEnabled;
	}

	setCompetitor(competitor: ICampCompetitorModel)
	{
		this._isAgeGroupEnabled.setModel(competitor);
	}

	private _isAgeGroupEnabled: ComputedFlagModel<ICampCompetitorModel>;
}


//==============================================================================


type Props = {
	competitor: ICampCompetitorModel;
	withDelete: IFlagModel;
	onDeleted: (competitor: ICampCompetitorModel) => void;
};


class CampCompetitorEdit extends PureComponent<Props>
{
	constructor(props: Props)
	{
		super(props);

		this._onDeleteClicked = this._onDeleteClicked.bind(this);
		this._onLanguageChanged = this._onLanguageChanged.bind(this);

		this._altersklasse = new MappedValidatedChoiceModel(this.props.competitor.altersklasse, _akMapper);
		this._computed = new Computed(this.props.competitor);
	}

	render(): ReactNode
	{
		return (
			<>
				<Label label={`${TR('CampRegistrationForm::Vorname')}:`} className='col-4 d-md-none' />
				<div className='col-8 col-md-3'>
					<ValidatedLineEditWithModel ref={this._focusTarget} model={this.props.competitor.vorname} />
				</div>
				<Label label={`${TR('CampRegistrationForm::Nachname')}:`} className='col-4 d-md-none' />
				<div className='col-8 col-md-3'>
					<ValidatedLineEditWithModel model={this.props.competitor.nachname} />
				</div>
				<Label label={`${TR('CampRegistrationForm::Geburtsjahr')}:`} className='col-4 d-md-none' />
				<div className='col-8 col-md-2'>
					<ValidatedLineEditWithModel model={this.props.competitor.geburtsjahr} placeholder={TR('CampRegistrationForm::JJJJ')} />
				</div>
				<Label label={`${TR('CampRegistrationForm::U18/U21')}:`} className='col-4 d-md-none' />
				<div className='col-8 col-md-2'>
					<ValidatedComboboxWithFlagModel model={this._altersklasse} isEnabled={this._computed.isAgeGroupEnabled()} />
				</div>
				<div className="col-12 col-md-2 d-flex justify-content-end">
					<HideableWithModel isShown={this.props.withDelete}>
						<Button className="btn btn-outline-primary" tabIndex={-1} onClicked={this._onDeleteClicked}>
							{TR('CampRegistrationForm::Entfernen')}
						</Button>
					</HideableWithModel>
				</div>
			</>
		);
	}

	focus()
	{
		this._focusTarget.current!.focus();
	}

	componentDidMount()
	{
		Translator.instance().languageChanged.subscribe(this._onLanguageChanged);
	}

	componentWillUnmount()
	{
		Translator.instance().languageChanged.unsubscribe(this._onLanguageChanged);
	}

	componentDidUpdate(prevProps: Props)
	{
		this._computed.setCompetitor(this.props.competitor);

		// Der Mapper bleibt konstant.
		this._altersklasse.setModels(this.props.competitor.altersklasse);
	}

	private _onDeleteClicked()
	{
		this.props.onDeleted(this.props.competitor);
	}

	private _onLanguageChanged(translator: Translator)
	{
		this.forceUpdate();
	}

	private readonly _focusTarget: RefObject<ValidatedLineEditWithModel> = createRef<ValidatedLineEditWithModel>();
	private readonly _altersklasse: MappedValidatedChoiceModel<Nullable<string>>;
	private readonly _computed: Computed;
}


//==============================================================================


const INVALID_ENTRY = '------';


class AltersklasseMapper implements IUiModelDataMapper<Nullable<string>>
{
	get onChanged(): IEvent<this>
	{
		return this._onChanged;
	}

	fromUi(ak: string): Nullable<string>
	{
		if (ak === INVALID_ENTRY)
			return null;

		return ak;
	}

	toUi(ak: Nullable<string>): string
	{
		if (ak === null)
			return INVALID_ENTRY;

		return ak;
	}

	private _onChanged = new Event<this>();
}


const _akMapper = new AltersklasseMapper();


export { CampCompetitorEdit };

