import { EMailValidator, TournamentIdValidator } from '../common/Validators';
import { ComputedFlagModel } from './ComputedFlagModel';
import { Event } from './Event';
import { IEvent } from './IEvent';
import { IFlagModel } from './IFlagModel';
import { IModel } from './IModel';
import { IValidatedTextModel } from './IValidatedTextModel';
import { ValidatedTextModel } from './ValidatedTextModel';


class CampPreFillModel implements IModel
{
	constructor()
	{
		this._onSubmodelChanged = this._onSubmodelChanged.bind(this);
		this._getIsValid = this._getIsValid.bind(this);

		this._id.onChanged.subscribe(this._onSubmodelChanged);
		this._email.onChanged.subscribe(this._onSubmodelChanged);

		this._isValid = new ComputedFlagModel(this._getIsValid, this)
	}

	get onChanged(): IEvent<this>
	{
		return this._onChanged;
	}

	get id(): IValidatedTextModel    { return this._id; }
	get email(): IValidatedTextModel { return this._email; }
	get isValid(): IFlagModel        { return this._isValid; }

	private _getIsValid(): boolean
	{
		return this._id.isValid()
			&& this._email.isValid();
	}

	private _onSubmodelChanged(): void
	{
		this._onChanged.notify(this, undefined);
	}

	private readonly _id: ValidatedTextModel = new ValidatedTextModel('', new TournamentIdValidator());
	private readonly _email: ValidatedTextModel = new ValidatedTextModel('', new EMailValidator());
	private readonly _isValid: ComputedFlagModel<this>;
	private readonly _onChanged = new Event<this>();
}


export { CampPreFillModel };
