import { PureComponent, ReactNode } from 'react';
import BsModal from 'react-bootstrap/Modal';
import { moveSubscription } from '../models/IEvent';
import { IFlagModel } from '../models/IFlagModel';
import { Button } from './Button';


type Props = {
	isShown: IFlagModel;
	title: string;
	content: ReactNode; // Überlicherweise einfach nur Text, ggf. gewrapped in p-Tags.
	buttons: ReactNode; // Der Content für den Footer, wo in der Regel die Buttons reinkommen
	onClosed?: () => void; // Wird gerufen, wenn der User den Dialog über das X schließt.
};


/**
 * Ein modaler Dialog auf Basis der bootstrap-Klassen.
 * Der bootstrap Modals erfordern normalerweise auf JavaScript-Anteile von bootstrap.
 * Den JavaScript-Kram machen wir hier selbst.
 *
 * siehe auch: https://jasonwatmore.com/post/2018/01/23/react-custom-modal-window-dialog-box
 *
 * Bei der Variante von bootstrap und der verlinkten Variante machen sie es so, dass das
 * Backdrop-Element ganz ans Ende des Dokuments verschoben wird -- also direkt vor
 * das schließende body-Tag. Das soll irgendwelche möglichen Effekte verhindern.
 * Keine Ahnung, wann genau da was schiefgehen kann. Jedenfalls mache ich das hier nicht.
 *
 * Mittlerweile ist der Modal über react-bootstrap implementiert.
 */
class Modal extends PureComponent<Props>
{
	constructor(props: Props)
	{
		super(props);

		this._onClosed = this._onClosed.bind(this);
		this._onModelChanged = this._onModelChanged.bind(this);
	}

	render(): ReactNode
	{
		return (
			<BsModal show={this.props.isShown.value} backdrop="static" onHide={this._onClosed}>
				<BsModal.Header closeButton>
					<BsModal.Title>{this.props.title}</BsModal.Title>
				</BsModal.Header>

				<BsModal.Body>
					{this.props.content}
				</BsModal.Body>

				<BsModal.Footer>
					{this.props.buttons}
				</BsModal.Footer>
			</BsModal>
		);
	}

	componentDidMount(): void
	{
		this.props.isShown.onChanged.subscribe(this._onModelChanged);
	}

	componentWillUnmount(): void
	{
		this.props.isShown.onChanged.unsubscribe(this._onModelChanged);
	}

	componentDidUpdate(prevProps: Props): void
	{
		moveSubscription(prevProps.isShown.onChanged, this.props.isShown.onChanged, this._onModelChanged);
	}

	private _onClosed(): void
	{
		this.props.isShown.setValue(false);
		this.props.onClosed?.call(undefined);
	}

	private _onModelChanged(flagModel: IFlagModel): void
	{
		if (this.props.isShown.value)
			document.body.classList.add('modal-open');
		else
			document.body.classList.remove('modal-open');

		this.forceUpdate();
	}
}


//==============================================================================


type OkCancelProps = Omit<Props, 'buttons' | 'onClosed'> & {
	onOk: () => void;
	onCancel?: () => void;
};


/**
 * Bin mir nicht sicher, ob das FlagModel für die Anzeige wirklich notwendig ist.
 * In der Regel muss ich mir wohl eh einen State außerhalb des Modals halten -- z.B.
 * für die Meldung, deren Löschen ich mir bestätigen lassen will.
 * Abhängig davon (vom externen State) kann ich dann den Modal rendern oder nicht.
 * Der Content hängt ja evtl. auch vom externen State ab.
 */
class ModalOkCancel extends PureComponent<OkCancelProps>
{
	constructor(props: OkCancelProps)
	{
		super(props);

		this._onOk = this._onOk.bind(this);
		this._onCancel = this._onCancel.bind(this);
	}

	render(): ReactNode
	{
		const buttons = (
			<>
				<Button type="button" className="btn btn-primary" onClicked={this._onOk}>
					Ok
				</Button>
				<Button type="button" className="btn btn-secondary" onClicked={this._onCancel}>
					Cancel
				</Button>
			</>
		);

		return (
			<Modal
				isShown={this.props.isShown}
				title={this.props.title}
				content={this.props.content}
				buttons={buttons}
				onClosed={this._onCancel}
			/>
		);
	}

	private _onOk(): void
	{
		this.props.isShown.setValue(false);
		this.props.onOk();
	}

	private _onCancel(): void
	{
		this.props.isShown.setValue(false);
		this.props.onCancel?.call(undefined);
	}
}

export { Modal, ModalOkCancel };
