import { Asserter } from '../common/Asserter';
import { Event } from './Event';
import { IEvent, withoutSubscription } from './IEvent';
import { IFlagModel } from './IFlagModel';
import { IModel } from './IModel';


class WasChangedModel<TModel extends IModel> implements IFlagModel
{
	constructor(model: TModel)
	{
		this._onModelChanged = this._onModelChanged.bind(this);
		this._notifyChange = this._notifyChange.bind(this);

		this._model = model;
		this._model.onChanged.subscribe(this._onModelChanged);
	}

	get onChanged(): IEvent<this>
	{
		return this._onChanged;
	}

	get value(): boolean
	{
		return this._wasChanged;
	}

	reset(): void
	{
		if (this._wasChanged === false)
			return;

		this._wasChanged = false;
		withoutSubscription(this._model.onChanged, this._onModelChanged, this._notifyChange);
	}

	setValue(value: boolean): void
	{
		Asserter.fail('readonly');
	}

	toggle(): void
	{
		Asserter.fail('readonly');
	}

	private _onModelChanged(model: TModel)
	{
		if (this._wasChanged === true)
			return;

		this._wasChanged = true;
		withoutSubscription(this._model.onChanged, this._onModelChanged, this._notifyChange);
	}

	private _notifyChange()
	{
		this._onChanged.notify(this, undefined);
	}

	private _wasChanged = false;
	private readonly _model: TModel;
	private readonly _onChanged = new Event<this>();
}

export { WasChangedModel };
