import { PropsWithChildren, PureComponent, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { moveSubscription } from '../models/IEvent';
import { IModel } from '../models/IModel';


type Props = PropsWithChildren<{
	model: IModel;
	isAllowed: (model: IModel) => boolean; // Prüft den gewünscht Zustand des Models.
}>;


/**
 * Stellt sicher, dass die children nur angezeigt werden,
 * wenn das Model einen gewünschten Zustand hat.
 */
class RequireModelState extends PureComponent<Props>
{
	constructor(props: Props)
	{
		super(props);

		this._onModelChanged = this._onModelChanged.bind(this);
	}

	render(): ReactNode
	{
		if (!this.props.isAllowed(this.props.model))
			return <Navigate to="/not-found" replace />;

		return this.props.children;
	}

	componentDidMount(): void
	{
		this.props.model.onChanged.subscribe(this._onModelChanged);
	}

	componentWillUnmount(): void
	{
		this.props.model.onChanged.unsubscribe(this._onModelChanged);
	}

	componentDidUpdate(prevProps: Props): void
	{
		moveSubscription(prevProps.model.onChanged, this.props.model.onChanged, this._onModelChanged);
	}

	private _onModelChanged(model: IModel): void
	{
		this.forceUpdate();
	}
}


export { RequireModelState };
