import { createRef, PureComponent, ReactNode, RefObject } from 'react';
import { Asserter } from '../common/Asserter';
import { Nullable } from '../common/Optional';
import { TR, Translator } from '../common/Translator';
import { gkString, stringToInt } from '../common/utils';
import { ComputedFlagModel } from '../models/ComputedFlagModel';
import { Event } from '../models/Event';
import { ICompetitorModel } from '../models/ICompetitorModel';
import { IEvent } from '../models/IEvent';
import { IFlagModel } from '../models/IFlagModel';
import { IUiModelDataMapper, MappedValidatedChoiceModel } from '../models/MappedValidatedChoiceModel';
import { Button } from './Button';
import { HideableWithModel } from './Hideable';
import { Label } from './Label';
import { ValidatedComboboxWithFlagModel } from './ValidatedCombobox';
import { ValidatedLineEditWithModel } from './ValidatedLineEdit';


class Computed
{
	constructor(competitor: ICompetitorModel)
	{
		this._isAgeGroupEnabled = new ComputedFlagModel<ICompetitorModel>(c => c.altersklasse.choices.length > 1, competitor);
		this._isWeightCategoryEnabled = new ComputedFlagModel<ICompetitorModel>(c => c.altersklasse.isValid(), competitor);
	}

	isAgeGroupEnabled(): IFlagModel
	{
		return this._isAgeGroupEnabled;
	}

	isWeightCategoryEnabled(): IFlagModel
	{
		return this._isWeightCategoryEnabled;
	}

	setCompetitor(competitor: ICompetitorModel): void
	{
		[this._isAgeGroupEnabled, this._isWeightCategoryEnabled].forEach(m => m.setModel(competitor));
	}

	private readonly _isAgeGroupEnabled: ComputedFlagModel<ICompetitorModel>;
	private readonly _isWeightCategoryEnabled: ComputedFlagModel<ICompetitorModel>;
}


//==============================================================================


type Props = {
	competitor: ICompetitorModel;
	withDelete: IFlagModel;
	onDeleted: (competitor: ICompetitorModel) => void;
};


class CompetitorEdit extends PureComponent<Props>
{
	constructor(props: Props)
	{
		super(props);

		this._onDeleteClicked = this._onDeleteClicked.bind(this);
		this._onLanguageChanged = this._onLanguageChanged.bind(this);

		this._focusTarget = createRef<ValidatedLineEditWithModel>();
		this._altersklasse = new MappedValidatedChoiceModel(this.props.competitor.altersklasse, _akMapper);
		this._gewichtsklasse = new MappedValidatedChoiceModel(this.props.competitor.gewichtsklasse, _gkMapper);
		this._computed = new Computed(this.props.competitor);
	}

	render(): ReactNode
	{
		return (
			<>
				<Label label={`${TR('RegistrationForm::Vorname')}:`} className='col-4 d-md-none' />
				<div className='col-8 col-md-2'>
					<ValidatedLineEditWithModel ref={this._focusTarget} model={this.props.competitor.vorname} />
				</div>
				<Label label={`${TR('RegistrationForm::Nachname')}:`} className='col-4 d-md-none' />
				<div className='col-8 col-md-2'>
					<ValidatedLineEditWithModel model={this.props.competitor.nachname} />
				</div>
				<Label label={`${TR('RegistrationForm::Geburtsjahr')}:`} className='col-4 d-md-none' />
				<div className='col-8 col-md-2'>
					<ValidatedLineEditWithModel model={this.props.competitor.geburtsjahr} placeholder={TR('RegistrationForm::JJJJ')} />
				</div>
				<Label label={`${TR('RegistrationForm::U18/U21')}:`} className='col-4 d-md-none' />
				<div className='col-8 col-md-2'>
					<ValidatedComboboxWithFlagModel model={this._altersklasse} isEnabled={this._computed.isAgeGroupEnabled()} />
				</div>
				<Label label={`${TR('RegistrationForm::Gewicht')}:`} className='col-4 d-md-none' />
				<div className='col-8 col-md-2'>
					<ValidatedComboboxWithFlagModel model={this._gewichtsklasse} isEnabled={this._computed.isWeightCategoryEnabled()} />
				</div>
				<div className="col-12 col-md-2 d-flex justify-content-end">
					<HideableWithModel isShown={this.props.withDelete}>
						<Button className="btn btn-outline-primary" tabIndex={-1} onClicked={this._onDeleteClicked}>
							{TR('RegistrationForm::Entfernen')}
						</Button>
					</HideableWithModel>
				</div>
			</>
		);
	}

	focus(): void
	{
		this._focusTarget.current!.focus();
	}

	componentDidMount(): void
	{
		Translator.instance().languageChanged.subscribe(this._onLanguageChanged);
	}

	componentWillUnmount(): void
	{
		Translator.instance().languageChanged.unsubscribe(this._onLanguageChanged);
	}

	componentDidUpdate(prevProps: Props): void
	{
		this._computed.setCompetitor(this.props.competitor);

		// Die Mapper bleiben konstant.
		this._altersklasse.setModels(this.props.competitor.altersklasse);
		this._gewichtsklasse.setModels(this.props.competitor.gewichtsklasse);
	}

	private _onDeleteClicked(): void
	{
		this.props.onDeleted(this.props.competitor);
	}

	private _onLanguageChanged(translator: Translator): void
	{
		this.forceUpdate();
	}

	private readonly _focusTarget: RefObject<ValidatedLineEditWithModel>;
	private readonly _altersklasse: MappedValidatedChoiceModel<Nullable<string>>;
	private readonly _gewichtsklasse: MappedValidatedChoiceModel<Nullable<number>>;
	private readonly _computed: Computed;
}


//==============================================================================


const INVALID_ENTRY = '------';


class WeightCategoryMapper implements IUiModelDataMapper<Nullable<number>>
{
	get onChanged(): IEvent<this>
	{
		return this._onChanged;
	}

	fromUi(category: string): Nullable<number>
	{
		if (category === INVALID_ENTRY)
			return null;

		Asserter.assert(category.endsWith('kg'), `invalid category: ${category}`);
		category = category.slice(0, -2);

		return stringToInt(category);
	}

	toUi(category: Nullable<number>): string
	{
		if (category === null)
			return INVALID_ENTRY;

		return gkString(category);
	}

	private readonly _onChanged = new Event<this>();
}


class AltersklasseMapper implements IUiModelDataMapper<Nullable<string>>
{
	get onChanged(): IEvent<this>
	{
		return this._onChanged;
	}

	fromUi(ak: string): Nullable<string>
	{
		if (ak === INVALID_ENTRY)
			return null;

		return ak;
	}

	toUi(ak: Nullable<string>): string
	{
		if (ak === null)
			return INVALID_ENTRY;

		return ak;
	}

	private readonly _onChanged = new Event<this>();
}


const _gkMapper = new WeightCategoryMapper();
const _akMapper = new AltersklasseMapper();


export { CompetitorEdit };
