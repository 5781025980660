import { FileData, FileDataConverter, FileEntryData, FileEntryServerData, FileServerData, FileStorageData } from '../data/FileData';
import { Asserter } from './Asserter';
import { HttpClient } from './HttpClient';


interface IFilesClient
{
	post(fileData: FileStorageData): Promise<FileEntryData>;
	update(fileData: FileStorageData): Promise<FileEntryData>;
	delete(id: number): Promise<void>;
	fetchAll(): Promise<FileData>;
}


class FilesClient implements IFilesClient
{
	constructor(client: HttpClient)
	{
		this._client = client;
	}

	async post(fileData: FileStorageData): Promise<FileEntryData>
	{
		Asserter.assert(fileData.id === undefined, 'id must not be set');

		const formData = _makeFormData(fileData);
		const response = await this._client.postFormData('api/files', formData);
		this._client.checkResponse(response);
		const serverData = await response.json() as FileEntryServerData
		return FileDataConverter.entryFromServer(serverData);
	}

	async update(fileData: FileStorageData): Promise<FileEntryData>
	{
		Asserter.assert(fileData.id !== undefined, 'id must be set');

		const formData = _makeFormData(fileData);
		const response = await this._client.putFormData(`api/files/${fileData.id}`, formData);
		this._client.checkResponse(response);
		const serverData = await response.json() as FileEntryServerData
		return FileDataConverter.entryFromServer(serverData);
	}

	async delete(id: number): Promise<void>
	{
		const response = await this._client.delete(`api/files/${id}`);
		this._client.checkResponse(response);
	}

	async fetchAll(): Promise<FileData>
	{
		const response = await this._client.get('api/files');
		this._client.checkResponse(response);
		const serverData = await response.json() as FileServerData;
		return FileDataConverter.fromServer(serverData);
	}

	private _client: HttpClient;
}


function _makeFormData(fileData: FileStorageData): FormData
{
	const formData = new FormData();
	if (fileData.id !== undefined)
		formData.set('id', fileData.id.toString());
	formData.set('name', fileData.name);
	formData.set('file', fileData.file);

	return formData;
}


export type { IFilesClient };
export { FilesClient };

