import { Event } from '../models/Event';
import { IEvent } from '../models/IEvent';
import { Asserter } from './Asserter';
import { IBackend } from './IBackend';
import { Nullable } from './Optional';
import { areArraysEqual } from './utils';


/**
 * Im Prinzip ein einfaches Wörterbuch.
 */
class _TranslationTable
{
	constructor(application: string, language: string)
	{
		this._dictionary  = new Map<string, string>();
		this._application = application;
		this._language    = language;
	}

	destroy()
	{
		this._dictionary.clear();
	}

	get application() { return this._application; }
	get language()    { return this._language; }

	translate(text: string) : string
	{
		const translated = this._dictionary.get(text);
		Asserter.assert(translated !== undefined, 'No translation available for: ' + text);
		return translated;
	}

	addEntry(source: string, translation: string)
	{
		this._dictionary.set(source, translation);
	}

	private _dictionary: Map<string, string>;
	private readonly _application: string;
	private readonly _language: string;
}


/**
 * Die Übersetzungen haben immer einen Scope: die Applikation, z.B. Meldung und CampMeldung.
 * Es kann mehrere Applikationen parallel geben.
 * Da wir nicht wissen, welche Applikationen der User überhaupt aufrufen wird,
 * laden wir die Übersetztungen on-demand.
 * In der Regel will man die Applikation erst rendern, wenn man auch die Übersetzungen zur
 * Verfügung hat. Es muss also einen Mechanismus geben, das Laden anzustoßen und ggf.
 * darauf zu warten, dass die Übersetzung fertig geladen wurde.
 * changeLanguage() könnte einfach einen Promise liefern, auf den man asynchron warten kann.
 * Man muss changeLanguage() jedoch nicht explizit aufrufen.
 * Ein Aufruf von TR() stößt das Laden der Übersetzung auch an, sollte für die Applikation
 * in der Sprache noch keine Übersetzung vorhanden sein.
 *
 * Die Seite insgesamt kann während der Lebenszeit mehrere Applikationen in mehreren Sprachen
 * anzeigen. Der User kann beliebig die Sprache wechseln. translate() kann in kurzer Folge
 * für verschiedene Applikationen gerufen werden.
 */
class Translator
{
	static instance(): Translator
	{
		Asserter.assert(Translator._instance !== null, 'singleton instance was not created yet');
		return Translator._instance;
	}

	constructor(initialLang: string, backend: IBackend)
	{
		Asserter.assert(Translator._instance === null, 'singleton instance was created already');
		Translator._instance = this;

		this._backend = backend;
		this._currentLang = initialLang;
		this._dictionaries = new Map();
		this._dictionaries.set(initialLang, new Map());
		this._inFlightMonitor = new _InFlightMonitor();
	}

	get languageChanged(): IEvent<Translator>
	{
		return this._languageChanged;
	}

	get currentLanguage(): string
	{
		return this._currentLang;
	}

	/**
	 * Stellt sicher, dass für die aktuelle Sprache das Dictionary für application geladen ist.
	 */
	ensureDictLoaded(application: string): Promise<void>
	{
		return this.changeLanguage(this._currentLang, application);
	}

	async changeLanguage(lang: string, application?: string): Promise<void>
	{
		if (application === undefined && this._currentLang === lang)
			return;

		// Ich muss schauen, welche Applikationen es für die *aktuelle* Sprache gibt.
		// Für diese Applikationen muss ich dann die Dicts für die *neue* Sprache laden.
		// Danach dann ein notifyChanged().

		const apps = [...this._dictionaries.get(this._currentLang)!.keys()];
		if (application !== undefined && apps.indexOf(application) === -1)
			apps.push(application);

		let appDict = this._dictionaries.get(lang);
		if (appDict === undefined)
		{
			// Für diese Sprache haben wir bisher noch keine Dicts geladen.
			appDict = new Map();
			this._dictionaries.set(lang, appDict);
		}

		// Wir müssen ja nur die Dicts laden, die für die gewünschte Sprache noch nicht vorliegen
		// oder das Dictionary bereits geladen wird.
		const appsToFetch = apps.filter(app => !appDict!.has(app) && !this._inFlightMonitor.isInFlight(lang, app));

		await Promise.all(appsToFetch.map(app => this._fetchDict(lang, app)));

		this._currentLang = lang;
		this._languageChanged.notify(this, undefined);
	}

	translate(application: string, text: string): string
	{
		const apps = this._dictionaries.get(this._currentLang);
		Asserter.assert(apps !== undefined, 'inconsistency');

		const appDict = apps.get(application);
		if (appDict !== undefined)
			return appDict.translate(text);

		// Für diese App ist noch keine Übersetzung geladen worden.
		// Laden async triggern und vorest einfach die Identität returnen.
		// Wenn das Laden durch ist, wird der Aufrufer notifiziert und
		// wird translate() erneut aurufen.
		this._fetchDictAndNotify(this._currentLang, application);
		return text;
	}

	private async _fetchDictAndNotify(lang: string, application: string): Promise<void>
	{
		if (this._inFlightMonitor.isInFlight(lang, application))
			return;

		await this._fetchDict(lang, application);
		this._languageChanged.notify(this, undefined);
	}

	private async _fetchDict(lang: string, application: string): Promise<void>
	{
		this._inFlightMonitor.markInFlight(lang, application);

		const dictData = await this._backend.fetchTranslation(application, lang);

		this._inFlightMonitor.markDone(lang, application);

		const dict = new _TranslationTable(application, lang);
		for (const [word, translation] of Object.entries(dictData))
			dict.addEntry(word, translation);

		this._dictionaries.get(lang)!.set(application, dict);
	}

	private static _instance: Nullable<Translator> = null;
	private readonly _backend: IBackend;
	private readonly _languageChanged = new Event<Translator>();
	private _currentLang: string;
	private readonly _dictionaries: Map<string, Map<string, _TranslationTable>>; // lang => (application => _TranslationTable)
	private readonly _inFlightMonitor: _InFlightMonitor;

	// Wir brauchen Pro language und Applikation eine Übersetzung.
}


class _InFlightMonitor
{
	isInFlight(lang: string, app: string): boolean
	{
		const found = this._inFlight.find(item => areArraysEqual(item, [lang, app]));
		return found !== undefined;
	}

	markInFlight(lang: string, app: string): void
	{
		Asserter.assert(!this.isInFlight(lang, app), 'Dictionary is being fetched already');
		this._inFlight.push([lang, app]);
	}

	markDone(lang: string, app: string): void
	{
		const idx = this._inFlight.findIndex(item => areArraysEqual(item, [lang, app]));
		Asserter.assert(idx !== -1, 'Dictionary is not being fetched');
		this._inFlight.splice(idx, 1);
	}

	private _inFlight: [lang: string, app: string][] = [];
}


function TR(text: string): string
{
	const sepPos = text.indexOf('::');
	Asserter.assert(sepPos !== -1, 'missing seperator :: in translation string');
	const app = text.slice(0, sepPos);
	const words = text.slice(sepPos + 2);
	return Translator.instance().translate(app, words);
}


export { TR, Translator };
