import { createRef, PureComponent, ReactNode, RefObject } from 'react';
import { Optional } from '../common/Optional';
import { moveSubscription } from '../models/IEvent';
import { ITextModel } from '../models/ITextModel';
import { InputType, ValidatedLineEdit } from './ValidatedLineEdit';


type PropsWithModel =
{
	model: ITextModel;
	type?: InputType;
	placeholder?: string;
	isEnabled?: boolean;
	rows?: Optional<number>; // nur für textarea
}


class LineEditWithModel extends PureComponent<PropsWithModel>
{
	constructor(props: PropsWithModel)
	{
		super(props);

		this._onModelChanged = this._onModelChanged.bind(this);
		this._onUiChanged = this._onUiChanged.bind(this);

		this._ref = createRef<ValidatedLineEdit>();
	}

	render(): ReactNode
	{
		return (
			<ValidatedLineEdit
				ref={this._ref}
				value={this.props.model.text}
				type={this.props.type}
				placeholder={this.props.placeholder}
				onChanged={this._onUiChanged}
				isEnabled={this.props.isEnabled}
				rows={this.props.rows}
			/>
		);
	}

	focus(): void
	{
		this._ref.current!.focus();
	}

	componentDidMount(): void
	{
		this.props.model.onChanged.subscribe(this._onModelChanged);
	}

	componentWillUnmount(): void
	{
		this.props.model.onChanged.unsubscribe(this._onModelChanged);
	}

	componentDidUpdate(prevProps: PropsWithModel): void
	{
		moveSubscription(prevProps.model.onChanged, this.props.model.onChanged, this._onModelChanged);
	}

	private _onModelChanged(model: ITextModel): void
	{
		this.forceUpdate();
	}

	private _onUiChanged(text: string): void
	{
		this.props.model.setText(text);
	}

	private readonly _ref: RefObject<ValidatedLineEdit>;
}


export { LineEditWithModel };
