import { PureComponent, ReactNode } from 'react';
import { Asserter } from '../common/Asserter';
import { FetchGuard } from '../common/FetchGuard';
import { IBackend } from '../common/IBackend';
import { Nullable } from '../common/Optional';
import { ResourceProvider } from '../common/ResourceProvider';
import { TR, Translator } from '../common/Translator';
import { RouterData, withRouter } from '../common/withRouter';
import { BusyWithModel } from '../components/Busy';
import { CampRegistrationForm } from '../components/CampRegistrationForm';
import { HelpForm } from '../components/HelpForm';
import { LanguageSelectorForm } from '../components/LanguageSelectorForm';
import { ShortFormData } from '../data/RegistrationFormData';
import { CampRegistrationModel } from '../models/CampRegistrationModel';


type Props = {
	backend: IBackend;
	initialLang: 'en' | 'de';
	router: RouterData;
};


type State = {
	registration: Nullable<CampRegistrationModel>;
};


class CampRegistrationRoute extends PureComponent<Props, State>
{
	constructor(props: Props)
	{
		super(props);

		this.state = {
			registration: null // kann erst erzeugt werden, wenn formData geladen ist
		};

		this._renderContent = this._renderContent.bind(this);
		this._onSubmit = this._onSubmit.bind(this);
		this._fetchResouces = this._fetchResouces.bind(this);
	}

	render(): ReactNode
	{
		return <BusyWithModel isBusy={this._fetchGuard.isBusy} render={this._renderContent} />;
	}

	componentDidMount(): Promise<void>
	{
		return this._fetchGuard.fetch(this._fetchResouces);
	}

	private _renderContent(): ReactNode
	{
		Asserter.assert(this.state.registration !== null, 'must not be called when busy');

		return (
			<div className='container-lg mt-4'>
				<LanguageSelectorForm />
				<HelpForm labelText='CampRegistrationForm::Hilfe anzeigen' helpText='CampRegistrationForm::Hilfetext' />
				<CampRegistrationForm
					registration={this.state.registration!}
					withPreFill
					withDetails={false}
					onSubmit={this._onSubmit}
				/>
			</div>
		);
	}

	private async _fetchResouces(): Promise<void>
	{
		const formData = await ResourceProvider.instance().formData;
		await Translator.instance().changeLanguage(this.props.initialLang, 'CampRegistrationForm');
		const registration = CampRegistrationModel.createEmpty(this.props.backend.campRegistrations, this.props.backend.registrations, formData);

		if (this.props.initialLang === 'de')
			registration.nation.setSelected(_findGermany(formData.nations));

		this.setState({ registration });
	}

	private async _onSubmit(): Promise<void>
	{
		Asserter.assert(this.state.registration !== null, 'must not be called when busy');

		const registration = this.state.registration;

		// Damit die Meldung nicht mehr verändert werden kann und nicht erneut der Submit-Button
		// gedrückt werden kann, während die Meldung submitted wird.
		this.setState({ registration: null });

		try
		{
			await registration.save();
		}
		catch (e)
		{
			this.props.router.navigate('/error', { state: _buildErrorInfo(registration) });
			return;
		}

		// generatePath() (aus react-router-dom) kommt mit den Query-Params irgendwie nicht klar.
		// Genauer: Typescript meckert rum; Die Deklaration scheint nicht für Query-Params gemacht zu sein.
		const detailsUrl = `/camp-registration/${registration.id.text}?email=${registration.email.text}`;
		const info = TR('CampRegistrationForm::erfolgreich abgeschickt');
		this.props.router.navigate(detailsUrl, { state: info });
	}

	private readonly _fetchGuard = new FetchGuard();
}


function _buildErrorInfo(registration: CampRegistrationModel): string
{
	const registrationData = registration.toJSON();
	const registrationAsJson = `<br /><hr /><pre>${JSON.stringify(registrationData, null, 4)}</pre>`;
	return TR('RegistrationForm::Fehler beim Abschicken') + registrationAsJson;
}

function _findGermany(nations: ShortFormData[]): ShortFormData
{
	const found = nations.find(nation => nation.kurzform === 'GER');
	Asserter.assert(found !== undefined, 'GER not found');
	return found;
}


const CampRegistrationWithRouter = withRouter(CampRegistrationRoute);


export { CampRegistrationWithRouter as CampRegistrationRoute };
