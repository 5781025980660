import { Event } from './Event';
import { IEvent } from './IEvent';
import { IFlagModel } from './IFlagModel';


class InvertedFlagModel implements IFlagModel
{
	constructor(decorated: IFlagModel)
	{
		this._decorated = decorated;

		this._onDecoratedChanged = this._onDecoratedChanged.bind(this);

		this._decorated.onChanged.subscribe(this._onDecoratedChanged);
	}

	get onChanged(): IEvent<this>
	{
		return this._onChanged;
	}

	get value(): boolean
	{
		return !this._decorated.value;
	}

	setValue(value: boolean): void
	{
		this._decorated.setValue(!value);
	}

	toggle(): void
	{
		this._decorated.toggle();
	}

	private _onDecoratedChanged(model: IFlagModel): void
	{
		this._onChanged.notify(this, undefined);
	}

	private readonly _decorated: IFlagModel;
	private readonly _onChanged = new Event<this>();
}


export { InvertedFlagModel };
