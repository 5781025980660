import { PureComponent, ReactNode } from 'react';
import Container from 'react-bootstrap/Container';
import { FileUploadEntryModel } from '../models/FileUploadEntryModel';
import { FileUploadModel } from '../models/FileUploadModel';
import { moveSubscription } from '../models/IEvent';
import { IFlagModel } from '../models/IFlagModel';
import { IndicatorModel } from '../models/IndicatorModel';
import { Dropdown } from './Dropdown';
import { DropdownItem } from './DropdownItem';
import { FileUploadEntryForm } from './FileUploadEntryForm';
import { Indicator } from './Indicator';
import { Row } from './Row';


type Props = {
	model: FileUploadModel;
};


/**
 * Die Formular-Liste zum Editieren der FileUploads.
 */
class FileUploadForm extends PureComponent<Props>
{
	constructor(props: Props)
	{
		super(props);

		this._onCreateClicked = this._onCreateClicked.bind(this);
		this._onDeleteClicked = this._onDeleteClicked.bind(this);
		this._onChanged = this._onChanged.bind(this);
	}


	render(): ReactNode
	{
		const entries = this.props.model.entries.items.map(entryModel => (
			<FileUploadEntryForm
				key={entryModel.id.text}
				entry={entryModel}
				onDelete={this._onDeleteClicked}
			/>
		));

		return (
			<>
				<Container fluid="md" className="my-3">
					<Row className="mb-3">
						<div className="col-12 d-flex flex-row-reverse">
							<Dropdown label="Aktion" iconSize="1.3em">
								{this._renderButton('Neuer Eintrag', this._onCreateClicked)}
							</Dropdown>
						</div>
					</Row>
					<Indicator model={this._indicatorModel} />
				</Container>
				{entries}
			</>
		);
	}

	componentDidMount(): void
	{
		this.props.model.entries.onChanged.subscribe(this._onChanged);
	}

	componentWillUnmount(): void
	{
		this.props.model.entries.onChanged.unsubscribe(this._onChanged);
	}

	componentDidUpdate(prevProps: Props): void
	{
		moveSubscription(prevProps.model.entries.onChanged, this.props.model.entries.onChanged, this._onChanged);
	}

	private _renderButton(label: string, handler: () => void, isEnabled?: IFlagModel): ReactNode
	{
		return <DropdownItem label={label} onClicked={handler} isEnabled={isEnabled} />;
	}

	private _onCreateClicked(): void
	{
		this.props.model.create();
	}

	private _onDeleteClicked(entry: FileUploadEntryModel): Promise<void>
	{
		return this._indicatorModel.run(
			() => this.props.model.remove(entry),
			'Fehler beim Löschen des Eintrags',
			'Eintrag erfolgreich gelöscht'
		);
	}

	private _onChanged(): void
	{
		this.forceUpdate();
	}

	private readonly _indicatorModel = new IndicatorModel();
}


export { FileUploadForm };
