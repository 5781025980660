import { Asserter } from './Asserter';
import { Nullable } from './Optional';


class HttpClient
{
	constructor(baseUrl: URL)
	{
		this._baseUrl = baseUrl;
	}

	setToken(token: string)
	{
		this._token = token;
	}

	unsetToken()
	{
		this._token = null;
	}

	get(path: string, requestedContentType: SupportedContentType = 'application/json'): Promise<Response>
	{
		let headers = this._createHeaders([
			['Accept', requestedContentType]
		]);
		const settings: RequestInit = {
			method: 'GET',
			headers: headers,
			mode: 'cors', // cors ist default
		};
		return this._fetch(path, settings);
	}

	post(path: string, body: unknown): Promise<Response>
	{
		let headers = this._createHeaders([
			['Accept', 'application/json'],
			['Content-Type', 'application/json'],
		]);
		const settings: RequestInit = {
			method: 'POST',
			headers: headers,
			mode: 'cors', // cors ist default
			body: JSON.stringify(body)
		};
		return this._fetch(path, settings);
	}

	postFormData(path: string, formData: FormData): Promise<Response>
	{
		let headers = this._createHeaders([
			['Accept', 'application/json']
		]);
		// Content-Type wird automatisch richtig gesetzt ('multipart/form-data'),
		// wenn man als body eine FormData-Instanz übergibt.
		const settings: RequestInit = {
			method: 'POST',
			headers: headers,
			mode: 'cors', // cors ist default
			body: formData
		};
		return this._fetch(path, settings);
	}

	put(path: string, body: unknown): Promise<Response>
	{
		let headers = this._createHeaders([
			['Accept', 'application/json'],
			['Content-Type', 'application/json'],
		]);
		const settings: RequestInit = {
			method: 'PUT',
			headers: headers,
			mode: 'cors', // cors ist default
			body: JSON.stringify(body)
		};
		return this._fetch(path, settings);
	}

	/**
	 * Laravel unterstützt kein PUT von multipart/form-data.
	 * Hintegrund ist wohl, dass HTML-Formulare das nicht unterstützen.
	 * Als Workaround müssen wir das als POST absetzen und in den
	 * Daten einen speziellen Eintrag setzen: _method = PUT.
	 * Dann interpretiert Laravel das als PUT-Request.
	 */
	putFormData(path: string, formData: FormData): Promise<Response>
	{
		Asserter.assert(formData.get('_method') === null, '_method must not be set (yet)');
		formData.set('_method', 'PUT');
		return this.postFormData(path, formData);
	}

	delete(path: string): Promise<Response>
	{
		let headers = this._createHeaders([
			['Accept', 'application/json']
		]);
		const settings: RequestInit = {
			method: 'DELETE',
			headers: headers,
			mode: 'cors', // cors ist default
		};
		return this._fetch(path, settings);
	}

	checkResponse(response: Response, expectedContentType: SupportedContentType = 'application/json')
	{
		if (!response.ok)
			throw new Error('request failed');

		if (response.status === 204)
			return; // HTTP 204 No Content. In dem Fall brauchen wir den Content-Type nicht prüfen.

		const contentType = response.headers.get('content-type');
		if (contentType === null)
			throw new Error('server returned no content-type');

		const parts = contentType.split(';');
		if (parts.length > 2)
			throw new Error('server returned unexpected content-type');

		if (parts[0] !== expectedContentType)
			throw new Error('server returned unexpected content-type');
	}

	private _fetch(relativPath: string, settings: RequestInit): Promise<Response>
	{
		const url = new URL(relativPath, this._baseUrl);
		return fetch(url.href, settings);
	}

	private _createHeaders(additionalHeaders: [string, string][])
	{
		let headers = new Headers();
		if (this._token !== null)
			headers.append('Authorization', `Bearer ${this._token}`);

		for (const [headerName, headerValue] of additionalHeaders)
			headers.append(headerName, headerValue);

		return headers;
	}


	private _baseUrl: URL;
	private _token: Nullable<string> = null;
}


type SupportedContentType = 'application/json' | 'text/csv';


export type { SupportedContentType };
export { HttpClient };
