import { areArraysEqual } from '../common/utils';
import { Event } from './Event';


/**
 * Hilfsklasse für die Implementierung von Event.collectWhile().
 */
class EventAggregator<TSubject, TEventArg>
{
	constructor()
	{
		this.record = this.record.bind(this);
	}

	record(subject: TSubject, arg: TEventArg)
	{
		this._pushOrDrop([subject, arg]);
	}

	replay(event: Event<TSubject, TEventArg>)
	{
		this._notifications.forEach(args => event.notify(...args));
	}

	private _pushOrDrop(args: [TSubject, TEventArg])
	{
		if (this._notifications.length > 0 &&
			areArraysEqual(this._notifications[this._notifications.length - 1], args))
			return;

		this._notifications.push(args);
	}

	private _notifications: [TSubject, TEventArg][] = [];
}


export { EventAggregator };
