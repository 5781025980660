import { PureComponent, ReactNode } from 'react';
import BsDropdown from 'react-bootstrap/Dropdown';
import { moveSubscription } from '../models/IEvent';
import { IFlagModel } from '../models/IFlagModel';


type Props = {
	label: string;
	onClicked: () => void;
	isEnabled?: IFlagModel;
};


class DropdownItem extends PureComponent<Props>
{
	constructor(props: Props)
	{
		super(props);

		this._onIsEnabledChanged = this._onIsEnabledChanged.bind(this);
	}

	render(): ReactNode
	{
		const isDisabled = this.props.isEnabled?.value === false ? true : undefined;
		return (
			<BsDropdown.Item onClick={this.props.onClicked} disabled={isDisabled}>
				{this.props.label}
			</BsDropdown.Item>
		);
	}

	componentDidMount(): void
	{
		this.props.isEnabled?.onChanged.subscribe(this._onIsEnabledChanged);
	}

	componentWillUnmount(): void
	{
		this.props.isEnabled?.onChanged.unsubscribe(this._onIsEnabledChanged);
	}

	componentDidUpdate(prevProps: Props): void
	{
		moveSubscription(prevProps.isEnabled?.onChanged, this.props.isEnabled?.onChanged, this._onIsEnabledChanged);
	}

	private _onIsEnabledChanged(flagModel: IFlagModel)
	{
		this.forceUpdate();
	}
}


export { DropdownItem };
