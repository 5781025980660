import { PureComponent, ReactNode } from 'react';
import { IBackend } from '../common/IBackend';
import { MailForm } from '../components/MailForm';


type Props = {
	backend: IBackend;
};


class SendMailRoute extends PureComponent<Props>
{
	public render(): ReactNode
	{
		return <MailForm backend={this.props.backend} />;
	}
}


export { SendMailRoute };
