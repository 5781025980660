import { Event } from './Event';
import { IEvent } from './IEvent';


/**
 * Hilfsklasse, mit der man die Notificationen eines Source-Events an ein Target-Event
 * weiterleiten kann, wobei das Subject ausgetauscht wird.
 */
class EventForwarder<TSourceSubject, TTargetSubject, TEventArg = undefined>
{
	constructor(sourceEvent: IEvent<TSourceSubject, TEventArg>, targetSubject: TTargetSubject)
	{
		this._onNotification = this._onNotification.bind(this);

		this._sourceEvent = sourceEvent;
		this._targetSubject = targetSubject;

		this._sourceEvent.subscribe(this._onNotification);
	}

	get event(): IEvent<TTargetSubject, TEventArg>
	{
		return this._targetEvent;
	}

	private _onNotification(sourceSubject: TSourceSubject, args: TEventArg)
	{
		this._targetEvent.notify(this._targetSubject, args);
	}

	private _sourceEvent: IEvent<TSourceSubject, TEventArg>;
	private _targetEvent = new Event<TTargetSubject, TEventArg>();
	private _targetSubject: TTargetSubject;
}


export { EventForwarder };
