
import { PropsWithChildren, PureComponent, ReactNode } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import { moveSubscription } from '../models/IEvent';
import { IFlagModel } from '../models/IFlagModel';
import { ContentSwitch, ContentSwitchWithModel } from './ContentSwitch';


type Props = PropsWithChildren<{
	isShown: boolean;
}>;


class Hideable extends PureComponent<Props>
{
	public render(): ReactNode
	{
		return (
			<ContentSwitch
				isOn={this.props.isShown}
				contentWhenOn={this.props.children ?? null}
				contentWhenOff={null}
			/>
		);
	}
}


type PropsWithModel = PropsWithChildren<{
	isShown: IFlagModel;
}>;


class HideableWithModel extends PureComponent<PropsWithModel>
{
	public render(): ReactNode
	{
		return (
			<ContentSwitchWithModel
				isOn={this.props.isShown}
				contentWhenOn={this.props.children ?? null}
				contentWhenOff={null}
			/>
		);
	}
}


type PropsWithTransition = PropsWithChildren<{
	isShown: IFlagModel;
	timeout?: number;
}>;


/**
 * Bis Rev e4dc96e hatte ich die Transition mit Hilfe von CSSTransition von react-transition-group
 * noch selbst implementiert. Jetzt nutze ich Collapse von react-bootstrap.
 * Unter der Haube nutzt das aber auch CSSTransition.
 */
class HideableWithTransition extends PureComponent<PropsWithTransition>
{
	constructor(props: PropsWithTransition)
	{
		super(props);

		this._onModelChanged = this._onModelChanged.bind(this);
	}

	render(): ReactNode
	{
		return (
			<Collapse
				in={this.props.isShown.value}
				timeout={this.props.timeout ?? 300}
				mountOnEnter
				unmountOnExit
			>
				<div> {/* Ist für die Transition nötig. Da brauchen wir in jedem Fall nur ein Child-Element. */}
					{this.props.children}
				</div>
			</Collapse>
		);
	}

	componentDidMount(): void
	{
		this.props.isShown.onChanged.subscribe(this._onModelChanged);
	}

	componentWillUnmount(): void
	{
		this.props.isShown.onChanged.unsubscribe(this._onModelChanged);
	}

	componentDidUpdate(prevProps: PropsWithTransition): void
	{
		moveSubscription(prevProps.isShown.onChanged, this.props.isShown.onChanged, this._onModelChanged);
	}

	private _onModelChanged(flagModel: IFlagModel): void
	{
		this.forceUpdate();
	}
}


export { Hideable, HideableWithModel, HideableWithTransition };
