import { Asserter } from '../common/Asserter';
import { Nullable } from '../common/Optional';
import { IRegistrationClient } from '../common/RegistrationClient';
import { RegistrationIdGenerator } from '../common/RegistrationIdGenerator';
import { ResourceProvider } from '../common/ResourceProvider';
import { DummyValidator, EMailValidator, NameValidator, NotNullValidator, VereinsValidator } from '../common/Validators';
import { RegistrationFormType } from '../components/RegistrationForm';
import { RegistrationData } from '../data/RegistrationData';
import { RegistrationFormData, ShortFormData } from '../data/RegistrationFormData';
import { CompetitorModel } from './CompetitorModel';
import { ComputedFlagModel } from './ComputedFlagModel';
import { DateValidatorWhenPersisted } from './DateValidatorWhenPersisted';
import { Event } from './Event';
import { FlagModel } from './FlagModel';
import { ICompetitorModel } from './ICompetitorModel';
import { IEvent } from './IEvent';
import { IFlagModel } from './IFlagModel';
import { IObservableCollection } from './IObservableCollection';
import { INationChoiceModel, IRegistrationModel, IVerbandChoiceModel } from './IRegistrationModel';
import { IValidatedTextModel } from './IValidatedTextModel';
import { ObservableCollection } from './ObservableCollection';
import { GenericValidatedChoiceModel } from './ValidatedChoiceModel';
import { ValidatedTextModel } from './ValidatedTextModel';
import { WasChangedModel } from './WasChangedModel';


/**
 * Dient einfach nur dazu, eine Meldung einfach laden zu können.
 */
class Loader
{
	async load(client: IRegistrationClient, regId: string, email: Nullable<string> = null): Promise<IRegistrationModel>
	{
		const formData = await ResourceProvider.instance().formData;
		const registrationData = await client.fetch(regId, email);
		return new RegistrationModel(client, formData, registrationData);
	}
}


//==============================================================================


const GERMANY = 'Germany';


class RegistrationModel implements IRegistrationModel
{
	static get loader(): Loader
	{
		return _loader;
	}

	/**
	 * Erzeugt abhängig von der gewünschten Formularvariante eine leere Meldung.
	 */
	static createEmpty(client: IRegistrationClient, formData: RegistrationFormData, formType: RegistrationFormType): RegistrationModel
	{
		let adjustedFormData: RegistrationFormData;

		// Für die internationalen Meldungen Germany als Auswahlmöglichkeit entfernen.
		if (formType === 'InternationalClub' || formType === 'InternationalNationalTeam')
			adjustedFormData = {
				...formData,
				nations: formData.nations.filter(n => n.name !== GERMANY),
			};
		else
			adjustedFormData = formData;


		const germany = formData.nations.find(n => n.name === GERMANY);
		Asserter.assert(germany !== undefined, 'Germany is missing');

		const registration = new RegistrationModel(client, adjustedFormData);
		switch (formType)
		{
			case 'Admin':
			case 'InternationalClub':
				break;

			case 'GermanClub':
				registration.nation.setSelected(germany);
				break;

			case 'GermanKader':
				registration.nation.setSelected(germany);
				registration.verein.setText('Kader');
				break;

			case 'InternationalNationalTeam':
				registration.verein.setText('National Team');
				break;
		}

		return registration;
	}

	constructor(client: IRegistrationClient, formData: RegistrationFormData, registration?: RegistrationData)
	{
		// Kleiner Trick, damit man nicht immer bind() aufrufen muss.
		this._onSubmodelChanged = this._onSubmodelChanged.bind(this);
		this._onNationChanged = this._onNationChanged.bind(this);
		this._getIsValid = this._getIsValid.bind(this);
		this._getIsPersisted = this._getIsPersisted.bind(this);

		if (registration !== undefined)
		{
			Asserter.assert(registration.id !== undefined, 'must be set by server');
			Asserter.assert(registration.eingegangen !== undefined, 'must be set by server');
			Asserter.assert(registration.competitors.length > 0, 'must not be empty from server');
		}

		this._client = client;
		this._formData = formData;

		const id = registration?.id ?? _idGenerator.nextId();
		const vorname = registration?.meldender.vorname ?? '';
		const nachname = registration?.meldender.nachname ?? '';
		const email = registration?.meldender.email ?? '';
		const verein = registration?.verein ?? '';
		const nation = registration?.nation ?? null;
		const verband = registration?.verband ?? null;
		const eingegangen = registration?.eingegangen ?? '';
		const confirmed = registration?.confirmed ?? false;

		this._id = new ValidatedTextModel(id, _dummyValidator);
		this._vorname = new ValidatedTextModel(vorname, _nameValidator);
		this._vorname.onChanged.subscribe(this._onSubmodelChanged);
		this._nachname = new ValidatedTextModel(nachname, _nameValidator);
		this._nachname.onChanged.subscribe(this._onSubmodelChanged);
		this._verein = new ValidatedTextModel(verein, _vereinsValidator);
		this._verein.onChanged.subscribe(this._onSubmodelChanged);
		this._email = new ValidatedTextModel(email, _emailValidator);
		this._email.onChanged.subscribe(this._onSubmodelChanged);

		const nationChoices = [null, ...formData.nations];
		this._nation = new GenericValidatedChoiceModel(nationChoices, this._findNation(nation), _nationValidator);
		this._nation.onChanged.subscribe(this._onNationChanged);

		const federationChoices = [null, ...formData.federations];
		this._verband = new GenericValidatedChoiceModel(federationChoices, this._findVerband(verband), _verbandValidator);
		this._verband.onChanged.subscribe(this._onSubmodelChanged);

		this._eingegangen = new ValidatedTextModel(eingegangen, new DateValidatorWhenPersisted(this._id));
		this.eingegangen.onChanged.subscribe(this._onSubmodelChanged);

		this._isConfirmed = new FlagModel(confirmed);
		this._isConfirmed.onChanged.subscribe(this._onSubmodelChanged);

		this._competitors = new ObservableCollection<ICompetitorModel>(registration?.competitors.map(c => new CompetitorModel(formData.age_groups, c)) ?? [new CompetitorModel(formData.age_groups)]);
		this._competitors.onChanged.subscribe(this._onSubmodelChanged);

		this._isValid = new ComputedFlagModel<RegistrationModel>(this._getIsValid, this);
		this._isPersisted = new ComputedFlagModel<RegistrationModel>(this._getIsPersisted, this);

		this._wasChanged = new WasChangedModel(this);
		// Zirkelschluss
		this._wasChanged.onChanged.subscribe(this._onSubmodelChanged);
	}

	get onChanged(): IEvent<this>                              { return this._onChanged; }

	get id(): IValidatedTextModel                              { return this._id; }
	get vorname(): IValidatedTextModel                         { return this._vorname; }
	get nachname(): IValidatedTextModel                        { return this._nachname; }
	get verein(): IValidatedTextModel                          { return this._verein; }
	get email(): IValidatedTextModel                           { return this._email; }
	get nation(): INationChoiceModel                           { return this._nation; }
	get verband(): IVerbandChoiceModel                         { return this._verband; }
	get eingegangen(): IValidatedTextModel                     { return this._eingegangen; }
	get isConfirmed(): IFlagModel                              { return this._isConfirmed; }
	get competitors(): IObservableCollection<ICompetitorModel> { return this._competitors; }

	get isValid():     IFlagModel                              { return this._isValid; }
	get isPersisted(): IFlagModel                              { return this._isPersisted; }

	get wasChanged(): IFlagModel                               { return this._wasChanged; }

	clone(): RegistrationModel
	{
		const copy = new RegistrationModel(this._client, this._formData);

		copy.vorname.setText(this.vorname.text);
		copy.nachname.setText(this.nachname.text);
		copy.verein.setText(this.verein.text);
		copy.email.setText(this.email.text);
		copy.nation.setSelected(this.nation.selected);
		copy.verband.setSelected(this.verband.selected);
		copy.eingegangen.setText(this.eingegangen.text);

		copy.competitors.reset(this.competitors.items.map(c => c.clone()));

		return copy;
	}

	/**
	 * Speichert die Meldung (schickt sie zum Server) und updated die Meldung inkl.
	 * der Kämpfer mit den Werten, die vom Server zurückgekommen sind.
	 * Bei einer neuen Meldung weist der Server in jedem Fall die Ids der Meldung
	 * und der Kämpfer zu und das Datum des Eingangs der Meldung.
	 * Setzt auch das wasChanged-Flag zurück.
	 */
	async save()
	{
		Asserter.assert(this.isValid.value === true, 'not allowed when invalid');

		let registrationData: RegistrationData;
		if (this.isPersisted.value === true)
			registrationData = await this._client.update(this.toJSON());
		else
			registrationData = await this._client.post(this.toJSON());

		this._refresh(registrationData);
	}

	async refresh()
	{
		Asserter.assert(this.isPersisted.value === true, 'can only refresh a persisted registration');

		const registrationData = await this._client.fetch(this._id.text, null);
		this._refresh(registrationData);
	}

	/**
	 * Die Bestätigung der Meldung forcieren.
	 */
	async forceConfirm()
	{
		Asserter.assert(this.isPersisted.value === true, 'only allowed when persisted');
		await this._client.confirm(this._id.text, null, true);
		this._isConfirmed.setValue(true);
	}

	isGerman()
	{
		return this._nation.selected?.name === GERMANY;
	}

	toJSON(): RegistrationData
	{
		Asserter.assert(this.isValid.value === true, 'not allowed when invalid');

		const id = this.isPersisted.value === true ? this._id.text : undefined;
		const eingegangen = this._eingegangen.text.length > 0 ? this._eingegangen.text : undefined;

		return {
			id,
			meldender: {
				vorname: this._vorname.text,
				nachname: this._nachname.text,
				email: this._email.text
			},
			verein: this._verein.text,
			nation: this._nation.selected!.kurzform,
			verband: this._verband.selected?.kurzform ?? null,
			eingegangen,
			confirmed: this._isConfirmed.value,
			competitors: this._competitors.items.map(c => c.toJSON()),
		};
	}

	addCompetitor(): void
	{
		this._competitors.add(new CompetitorModel(this._formData.age_groups))
	}

	removeCompetitor(competitor: ICompetitorModel): void
	{
		this._competitors.remove(competitor);
	}

	private _getIsValid(): boolean
	{
		return this._vorname.isValid()
			&& this._nachname.isValid()
			&& this._verein.isValid()
			&& this._email.isValid()
			&& this._nation.isValid()
			&& (!this.isGerman() || this._verband.isValid())
			&& this._eingegangen.isValid()
			&& this._competitors.items.every(c => c.isValid.value);
	}

	private _getIsPersisted(): boolean
	{
		return RegistrationIdGenerator.isPermanentId(this._id.text);
	}

	private _onNationChanged(model: INationChoiceModel): void
	{
		const nation = this._nation.selected;
		if (nation?.name !== GERMANY)
			this._verband.setSelected(null);
		this._notifyChange();
	}

	private _onSubmodelChanged(): void
	{
		this._notifyChange();
	}

	private _refresh(data: RegistrationData): void
	{
		Asserter.assert(data.id !== null, 'only allowed with persisted data');
		Asserter.assert(data.eingegangen !== undefined, 'must be set by server');
		Asserter.assert(data.competitors.length > 0, 'must not be empty from server');

		this._onChanged.collectWhile(() => {
			this._id.setText(data.id!);
			this._vorname.setText(data.meldender.vorname);
			this._nachname.setText(data.meldender.nachname);
			this._verein.setText(data.verein);
			this._email.setText(data.meldender.email);
			this._nation.setSelected(this._findNation(data.nation));
			this._verband.setSelected(this._findVerband(data.verband));
			this._eingegangen.setText(data.eingegangen!);
			this._isConfirmed.setValue(data.confirmed);
			this._competitors.reset(data.competitors.map(c => new CompetitorModel(this._formData.age_groups, c)));
		});

		this._wasChanged.reset();
	}

	private _findVerband(verband: Nullable<string>): Nullable<ShortFormData>
	{
		if (verband === null)
			return null;

		const found = this._formData.federations.find(v => v.kurzform === verband);
		Asserter.assert(found !== undefined, 'inconsistency');
		return found;
	}

	private _findNation(nation: Nullable<string>): Nullable<ShortFormData>
	{
		if (nation === null)
			return null;

		const found = this._formData.nations.find(n => n.kurzform === nation);
		Asserter.assert(found !== undefined, 'inconsistency');
		return found;
	}

	private _notifyChange(): void
	{
		this._onChanged.notify(this, undefined);
	}

	private readonly _client: IRegistrationClient;
	private readonly _formData: RegistrationFormData;
	private readonly _id: ValidatedTextModel;
	private readonly _vorname: ValidatedTextModel;
	private readonly _nachname: ValidatedTextModel;
	private readonly _verein: ValidatedTextModel;
	private readonly _email: ValidatedTextModel;
	private readonly _nation: GenericValidatedChoiceModel<Nullable<ShortFormData>>;
	private readonly _verband: GenericValidatedChoiceModel<Nullable<ShortFormData>>;
	private readonly _eingegangen: ValidatedTextModel;
	private readonly _isConfirmed: FlagModel;
	private readonly _competitors: ObservableCollection<ICompetitorModel>;
	private readonly _isValid: ComputedFlagModel<RegistrationModel>;
	private readonly _isPersisted: ComputedFlagModel<RegistrationModel>;
	private readonly _onChanged = new Event<this>();
	private readonly _wasChanged: WasChangedModel<this>;
}


//==============================================================================


const _loader = new Loader();

const _dummyValidator = new DummyValidator();
const _nameValidator = new NameValidator();
const _vereinsValidator = new VereinsValidator();
const _emailValidator = new EMailValidator();
const _verbandValidator = new NotNullValidator<ShortFormData>();
const _nationValidator = new NotNullValidator<ShortFormData>();

const _idGenerator = new RegistrationIdGenerator();


//==============================================================================


export { RegistrationModel };

