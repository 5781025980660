import { PropsWithChildren, PureComponent, ReactNode } from 'react';
import { IChoiceModel } from '../models/IChoiceModel';
import { moveSubscription } from '../models/IEvent';


type CalloutProps = PropsWithChildren<{
	className?: string;
	variant: string; // '-success', '-danger', ''
}>;


class Callout extends PureComponent<CalloutProps>
{
	render(): ReactNode
	{
		if (this.props.children === undefined)
			return null;

		let classNames = this.props.className === undefined ? '' : `${this.props.className} `;
		classNames += `callout${this.props.variant}`;

		return (
			<div className={classNames}>
				{this.props.children}
			</div>
		);
	}
}


//==============================================================================


type CalloutPropsWithModel = PropsWithChildren<{
	className?: string;
	indicatorModel: IChoiceModel;
}>;


class CalloutWithModel extends PureComponent<CalloutPropsWithModel>
{
	constructor(props: CalloutPropsWithModel)
	{
		super(props);

		this._onIndicatorChanged = this._onIndicatorChanged.bind(this);
	}

	render(): ReactNode
	{
		return (
			<Callout className={this.props.className} variant={this.props.indicatorModel.selected}>
				{this.props.children}
			</Callout>
		);
	}

	componentDidMount(): void
	{
		this.props.indicatorModel.onChanged.subscribe(this._onIndicatorChanged);
	}

	componentWillUnmount(): void
	{
		this.props.indicatorModel.onChanged.unsubscribe(this._onIndicatorChanged);
	}

	componentDidUpdate(prevProps: CalloutPropsWithModel): void
	{
		moveSubscription(prevProps.indicatorModel.onChanged, this.props.indicatorModel.onChanged, this._onIndicatorChanged);
	}

	_onIndicatorChanged(): void
	{
		this.forceUpdate();
	}
}


export { Callout, CalloutWithModel };
