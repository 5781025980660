import { PureComponent, ReactNode } from 'react';
import { TR, Translator } from '../common/Translator';
import { FlagModel } from '../models/FlagModel';
import { IFlagModel } from '../models/IFlagModel';
import { CheckboxWithModel } from './Checkbox';
import { HideableWithModel } from './Hideable';


type Props = {
	labelText: string; // der Translation-Key
	helpText: string;  // dito
};


/**
 * Kleines Formular zur Anzeige der Hilfe, welches ich an mehreren Stellen verwende.
 */
class HelpForm extends PureComponent<Props>
{
	constructor(props: Props)
	{
		super(props);

		this._onLanguageChanged = this._onLanguageChanged.bind(this);
		this._onModelChanged = this._onModelChanged.bind(this);

		this._isShown = new FlagModel(false);
	}

	render(): ReactNode
	{
		return (
			<div className="card border-secondary my-3">
				<div className="card-header">Hilfe</div>
				<div className="card-body">
					<CheckboxWithModel label={TR(this.props.labelText)} isChecked={this._isShown} type="switch" />
					<HideableWithModel isShown={this._isShown}>
						<ul className="mt-2" dangerouslySetInnerHTML={{ __html: TR(this.props.helpText)}}/>
					</HideableWithModel>
				</div>
			</div>
		);
	}

	componentDidMount()
	{
		this._isShown.onChanged.subscribe(this._onModelChanged);
		Translator.instance().languageChanged.subscribe(this._onLanguageChanged);
	}

	componentWillUnmount()
	{
		this._isShown.onChanged.unsubscribe(this._onModelChanged);
		Translator.instance().languageChanged.unsubscribe(this._onLanguageChanged);
	}

	private _onLanguageChanged(translator: Translator)
	{
		this.forceUpdate();
	}

	private _onModelChanged(model: IFlagModel)
	{
		this.forceUpdate();
	}

	private _isShown: FlagModel;
}


export { HelpForm };
