import React, { PureComponent, ReactNode } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Asserter } from '../common/Asserter';
import { Backend } from '../common/Backend';
import { FetchGuard } from '../common/FetchGuard';
import { Nullable } from '../common/Optional';
import { Singletons } from '../common/Singletons';
import { ConfigData } from '../data/ConfigData';
import { AppUserModel } from '../models/AppUserModel';
import { ConfigModel } from '../models/ConfigModel';
import { CampRegistrationConfirmationRoute } from '../routes/CampRegistrationConfirmationRoute';
import { CampRegistrationDetailsRoute } from '../routes/CampRegistrationDetailsRoute';
import { CampRegistrationRoute } from '../routes/CampRegistrationRoute';
import { CampRegistrationsRoute } from '../routes/CampRegistrationsRoute';
import { ConfigRoute } from '../routes/ConfigRoute';
import { ErrorRoute } from '../routes/ErrorRoute';
import { FileUploadRoute } from '../routes/FileUploadRoute';
import { HomeRoute } from '../routes/HomeRoute';
import { ImpressumRoute } from '../routes/ImpressumRoute';
import { InvitationCamp } from '../routes/InvitationCamp';
import { InvitationU18 } from '../routes/InvitationU18';
import { InvitationU21 } from '../routes/InvitationU21';
import { LoginRoute } from '../routes/LoginRoute';
import { NewstickerRoute } from '../routes/NewstickerRoute';
import { NotFoundRoute } from '../routes/NotFoundRoute';
import { ProfileRoute } from '../routes/ProfileRoute';
import { RegistrationConfirmationRoute } from '../routes/RegistrationConfirmationRoute';
import { RegistrationDetailsRoute } from '../routes/RegistrationDetailsRoute';
import { RegistrationHub } from '../routes/RegistrationHub';
import { RegistrationRoute } from '../routes/RegistrationRoute';
import { RegistrationsRoute } from '../routes/RegistrationsRoute';
import { SendMailRoute } from '../routes/SendMailRoute';
import { TournamentsRoute } from '../routes/TournamentsRoute';
import { BusyWithModel } from './Busy';
import { HomeContainer } from './HomeContainer';
import { RegistrationDistribution } from './RegistrationDistribution';
import { RequireAuth } from './RequireAuth';
import { RequireRegistrationEnabled } from './RequireRegistrationEnabled';


/**
 * Lazy Loading.
 */
const TournamentResults = React.lazy(() => import('./TournamentResults'));
const TournamentStatistics = React.lazy(() => import('./TournamentStatistics'));
const SheetsRoute = React.lazy(() => import('../routes/SheetsRoute'));


type State = {
	config: Nullable<ConfigModel>;
}


class App extends PureComponent<{}, State>
{
	constructor(props: {})
	{
		super(props);

		this.state = { config: null };

		this._renderContent = this._renderContent.bind(this);

		this._appUser = new AppUserModel(this._backend);
		this._appUser.restore();

		if (App._singletons === null)
			App._singletons = new Singletons(this._backend);
	}

	render(): ReactNode
	{
		return <BusyWithModel isBusy={this._fetchGuard.isBusy} render={this._renderContent} className="my-4" />;
	}

	componentDidMount(): Promise<void>
	{
		return this._fetchGuard.fetch(() => this._fetchConfig());
	}

	private async _fetchConfig(): Promise<void>
	{
		let config: ConfigData;
		try
		{
			config = await this._backend.fetchConfig();
		}
		catch (e)
		{
			// Default Config
			config = {
				registrationEnabled: false,
				newstickerEnabled: false,
				tournamentDetailsEnabled: false,
				campDetailsEnabled: false,
			};
		}

		const configModel = new ConfigModel(this._backend, config);
		this.setState({ config: configModel });
	}

	private _renderContent(): ReactNode
	{
		Asserter.assert(this.state.config !== null, 'Must not be called when busy');

		return (
			<Routes>
				<Route element={<HomeContainer user={this._appUser} config={this.state.config} />}>
					<Route index element={<HomeRoute config={this.state.config} backend={this._backend} />} />

					<Route path="invitation">
						<Route path="u18" element={<InvitationU18 />} />
						<Route path="u21" element={<InvitationU21 />} />
						<Route path="camp" element={<InvitationCamp />} />
					</Route>

					<Route
						path="registrations/overview"
						element={
							<RequireAuth appUser={this._appUser}>
								<RegistrationsRoute client={this._backend.registrations} />
							</RequireAuth>
						}
					/>

					<Route
						path="registrations/distribution"
						element={
							<RequireAuth appUser={this._appUser}>
								<RegistrationDistribution client={this._backend.registrations} />
							</RequireAuth>
						}
					/>

					<Route
						path="camp-registrations/overview"
						element={
							<RequireAuth appUser={this._appUser}>
								<CampRegistrationsRoute campRegistrationClient={this._backend.campRegistrations} registrationClient={this._backend.registrations} />
							</RequireAuth>
						}
					/>


					<Route
						path="mail"
						element={
							<RequireAuth appUser={this._appUser}>
								<SendMailRoute backend={this._backend} />
							</RequireAuth>
						}
					/>


					<Route
						path="registration/hub"
						element={
							<RequireRegistrationEnabled model={this.state.config.registrationEnabled}>
								<RegistrationHub />
							</RequireRegistrationEnabled>
						}
					/>
					<Route
						path="registration/german-club"
						element={
							<RequireRegistrationEnabled model={this.state.config.registrationEnabled}>
								<RegistrationRoute formType="GermanClub" backend={this._backend} />
							</RequireRegistrationEnabled>
						}
					/>
					<Route
						path="registration/german-kader"
						element={
							<RequireRegistrationEnabled model={this.state.config.registrationEnabled}>
								<RegistrationRoute formType="GermanKader" backend={this._backend} />
							</RequireRegistrationEnabled>
						}
					/>
					<Route
						path="registration/international-club"
						element={
							<RequireRegistrationEnabled model={this.state.config.registrationEnabled}>
								<RegistrationRoute formType="InternationalClub" backend={this._backend}/>
							</RequireRegistrationEnabled>
						}
					/>
					<Route
						path="registration/national-team"
						element={
							<RequireRegistrationEnabled model={this.state.config.registrationEnabled}>
								<RegistrationRoute formType="InternationalNationalTeam" backend={this._backend} />
							</RequireRegistrationEnabled>
						}
					/>
					<Route
						path="registration/:id/confirm"
						element={<RegistrationConfirmationRoute client={this._backend.registrations} />}
					/>
					<Route
						path="registration/:id"
						element={<RegistrationDetailsRoute backend={this._backend} />}
					/>


					<Route
						path="camp-registration/german"
						element={
							<RequireRegistrationEnabled model={this.state.config.registrationEnabled}>
								<CampRegistrationRoute backend={this._backend} initialLang='de' />
							</RequireRegistrationEnabled>
						}
					/>
					<Route
						path="camp-registration/international"
						element={
							<RequireRegistrationEnabled model={this.state.config.registrationEnabled}>
								<CampRegistrationRoute backend={this._backend} initialLang='en' />
							</RequireRegistrationEnabled>
						}
					/>
					<Route
						path="camp-registration/:id/confirm"
						element={<CampRegistrationConfirmationRoute client={this._backend.campRegistrations} />}
					/>
					<Route
						path="camp-registration/:id"
						element={<CampRegistrationDetailsRoute backend={this._backend} />}
					/>


					<Route
						path="profile"
						element={
							<RequireAuth appUser={this._appUser}>
								<ProfileRoute user={this._appUser} />
							</RequireAuth>
						}
					/>
					<Route
						path="config"
						element={
							<RequireAuth appUser={this._appUser} role="admin">
								<ConfigRoute config={this.state.config} />
							</RequireAuth>
						}
					/>
					<Route
						path="newsticker"
						element={
							<RequireAuth appUser={this._appUser}>
								<NewstickerRoute newstickerClient={this._backend.newstickerEntries} />
							</RequireAuth>
						}
					/>
					<Route
						path="files"
						element={
							<RequireAuth appUser={this._appUser}>
								<FileUploadRoute filesClient={this._backend.files} />
							</RequireAuth>
						}
					/>

					<Route path="tournaments" element={<TournamentsRoute backend={this._backend} />} />
					<Route path="tournaments/:id/results" element={<TournamentResults backend={this._backend} />} />
					<Route path="tournaments/:id/sheets/:category/:sheetIndices" element={<SheetsRoute backend={this._backend} />} /> {/* sheetIndices kann einen oder mehrere Indices enthalten 'n' oder 'n,m'*/}

					<Route path="tournaments/:id/stats" element={<TournamentStatistics backend={this._backend} />} />

					<Route path="impressum" element={<ImpressumRoute  />} />
					<Route path="login" element={<LoginRoute user={this._appUser} />} />
					<Route path="not-found" element={<NotFoundRoute />} />
					<Route path="error" element={<ErrorRoute />} />
				</Route>
				<Route path="*" element={<Navigate to="/not-found" replace={true} />} />
			</Routes>
		);
	}

	private static _singletons: Nullable<Singletons> = null;
	private readonly _backend = new Backend(_getBackendBaseUrl());
	private readonly _appUser: AppUserModel;
	private readonly _fetchGuard = new FetchGuard();
}


/**
 * Im Allgemeinen und insbesondere auf dem Server (mastersbremen.com) ist die baseUrl
 * für das Backend gleich dem Origin. Für die Entwicklung zu Hause brauchen wir aber
 * eine besondere Behandlung:
 * Da läuft die SPA unter localhost:3000 und das Backend unter localhost:8000.
 * Dieser Sonderfall ist hier abgebildet.
 */
function _getBackendBaseUrl(): URL
{
	let baseUrl = new URL(window.location.origin);

	const isDevAtHome = baseUrl.port === '3000';
	if (isDevAtHome)
		baseUrl.port = '8000';

	return baseUrl;
}


export { App };
