import { CSSProperties, PropsWithChildren, PureComponent } from 'react';


type SpacerProps = PropsWithChildren<{
	size?: string; // muss mit Unit angegeben werden
	className?: string;
	style?: CSSProperties; // flex wird überschrieben, falls gesetzt
}>;


/**
 * Ein horizontaler oder vertikaler Spacer.
 * Ein Spacer ist ein Flex-Element; kann also nur innerhalb eines Flex-Containers verwendet werden.
 * Ein Spacer hat entweder feste Größe oder nimmt den verfügbaren Platz ein.
 * Ein Spacer wird als div-Element gerendert und kann Child-Elemente haben.
 */
class Spacer extends PureComponent<SpacerProps>
{
	render()
	{
		const flex = this._flexPropterty();
		return (
			<div className={this.props.className} style={{ ...this.props.style, flex }}>
				{this.props.children}
			</div>
		);
	}

	private _flexPropterty()
	{
		if (this.props.size !== undefined)
			return '0 0 ' + this.props.size

		return '1 0'; // grow shrink
	}
}


export { Spacer };
