import { Event } from './Event';
import { IEvent, moveSubscription } from './IEvent';
import { IModel } from './IModel';
import { IGenericValidatedChoiceModel, IValidatedChoiceModel } from './IValidatedChoiceModel';


/**
 * Ein Mapper, der die Strings im UI auf Daten-Objekte im Model abbildet -- und zurück.
 * Die Validität spielt hierbei erst mal keine Rolle.
 * Es muss eine eineindeutige Abbildung sein.
 *
 * Der Mapper ist selbst ein Model, denn der Mapper (bzw. sein Verhalten) kann sich zur Laufzeit ändern --
 * z.B. wenn sich die Übersetzung ändert.
 */
interface IUiModelDataMapper<TElement> extends IModel
{
	fromUi(userInput: string): TElement;
	toUi(modelData: TElement): string;
}


/**
 * Ein ValidatedChoiceModel für die direkte Verwendung im UI -- also mit ValidatedCombobox.
 * Zugrunde liegt jedoch ein Source-Model mit Element-Typen != string.
 * Hier wird also ein Mapping durchgeführt von Element-Typen im Source-Model und zurück.
 */
class MappedValidatedChoiceModel<TElement> implements IValidatedChoiceModel
{
	constructor(sourceModel: IGenericValidatedChoiceModel<TElement>, mapper: IUiModelDataMapper<TElement>)
	{
		this._onSubModelChanged = this._onSubModelChanged.bind(this);

		this._sourceModel = sourceModel;
		this._mapper = mapper;

		this._sourceModel.onChanged.subscribe(this._onSubModelChanged);
		this._mapper.onChanged.subscribe(this._onSubModelChanged);
	}

	get onChanged(): IEvent<this>
	{
		return this._onChanged;
	}

	isValid(): boolean
	{
		return this._sourceModel.isValid();
	}

	get choices(): Readonly<string[]>
	{
		return this._sourceModel.choices.map(choice => this._mapper.toUi(choice));
	}

	setChoices(choices: Readonly<string[]>): void
	{
		const _sourceChoices = choices.map(choice => this._mapper.fromUi(choice));
		this._sourceModel.setChoices(_sourceChoices);
	}

	get selected(): string
	{
		return this._mapper.toUi(this._sourceModel.selected);
	}

	setSelected(item: string): void
	{
		this._sourceModel.setSelected(this._mapper.fromUi(item));
	}

	/**
	 * Ändert das sourceModel und optional auch den mapper zur Laufzeit.
	 */
	setModels(sourceModel: IGenericValidatedChoiceModel<TElement>, mapper?: IUiModelDataMapper<TElement>): void
	{
		moveSubscription(this._sourceModel.onChanged, sourceModel.onChanged, this._onSubModelChanged);
		this._sourceModel = sourceModel;

		if (mapper !== undefined)
		{
			moveSubscription(this._mapper.onChanged, mapper.onChanged, this._onSubModelChanged);
			this._mapper = mapper;
		}

		this._onSubModelChanged();
	}

	private _onSubModelChanged(): void
	{
		this._onChanged.notify(this, undefined);
	}

	private _sourceModel: IGenericValidatedChoiceModel<TElement>;
	private _mapper: IUiModelDataMapper<TElement>;
	private _onChanged = new Event<this>();
}


export { MappedValidatedChoiceModel };
export type { IUiModelDataMapper };

