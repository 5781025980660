import { FormEvent, PureComponent } from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { IBackend } from '../common/IBackend';
import { ButtonWithModel } from '../components/Button';
import { CheckboxWithModel } from '../components/Checkbox';
import { ValidatedLineEditWithModel } from '../components/ValidatedLineEdit';
import { IndicatorModel } from '../models/IndicatorModel';
import { MailModel } from '../models/MailModel';
import { DateTimeEditWithModel } from './DateTimeEdit';
import { FileSelectWithModel } from './FileSelect';
import { HideableWithModel } from './Hideable';
import { Indicator } from './Indicator';
import { Label } from './Label';
import { Row } from './Row';


type Props = {
	backend: IBackend;
};


class MailForm extends PureComponent<Props>
{
	constructor(props: Props)
	{
		super(props);

		this._onSubmit = this._onSubmit.bind(this);

		this._mail = new MailModel(props.backend);
	}

	public render()
	{
		return (
			<Container fluid="md">
				<Form onSubmit={this._onSubmit} noValidate className="mb-2">
					<Card border="secondary" className="my-3" >
						<Card.Header>Recipients</Card.Header>
						<Card.Body>
							<Row className="align-items-center gy-1">
								<Label label="To:" className="col-12 col-md-2" />
								<div className="col-13 col-md-10">
									<ValidatedLineEditWithModel type="email" model={this._mail.to} placeholder="e-mail" />
								</div>
								<Label label="To:" className="col-2" />
								<div className="col-10">
									<CheckboxWithModel label="Germans" type="switch" isChecked={this._mail.toGermans} />
								</div>
								<Label label="To:" className="col-2" />
								<div className="col-10">
									<CheckboxWithModel label="Internationals" type="switch" isChecked={this._mail.toInternationals} />
								</div>
								<Label label="To:" className="col-2" />
								<div className="col-10">
									<CheckboxWithModel label="Registered" type="switch" isChecked={this._mail.toRegistered} />
								</div>

								<Label label="With Send Date:" className="col-2" />
								<div className="col-10">
									<CheckboxWithModel label="" type="switch" isChecked={this._mail.withSendDate} />
								</div>
								<HideableWithModel isShown={this._mail.withSendDate}>
									<Label label="Send at:" className="col-2" />
									<div className="col-10">
										<DateTimeEditWithModel model={this._mail.sendDate} />
									</div>
								</HideableWithModel>
							</Row>
						</Card.Body>
					</Card>

					<Card border="secondary" className="my-3">
						<Card.Header>Subject</Card.Header>
						<Card.Body>
							<Form.Control as={ValidatedLineEditWithModel} type="text" model={this._mail.subject} />
						</Card.Body>
					</Card>

					<Card border="secondary" className="my-3">
						<Card.Header>Text</Card.Header>
						<Card.Body>
							<Form.Control as={ValidatedLineEditWithModel} type="textarea" model={this._mail.body} rows={10} />
						</Card.Body>
					</Card>

					<Card border="secondary" className="my-3">
						<Card.Header>Attatchment (optional)</Card.Header>
						<Card.Body>
							<Form.Control as={FileSelectWithModel} model={this._mail.attachment} />
						</Card.Body>
					</Card>

					<ButtonWithModel
						className="btn btn-secondary rounded-pill my-3"
						isEnabled={this._mail.isValid}
						type="submit"
					>
						Send
					</ButtonWithModel>
				</Form>

				<Indicator model={this._backendResult} />
			</Container>
		);
	}

	private _onSubmit(e: FormEvent<HTMLFormElement>): Promise<void>
	{
		e.preventDefault();

		return this._backendResult.run(
			() => this._mail.send(),
			'Fehler beim Versenden der E-Mail',
			'E-Mail erfolgreich versandt'
		);
	}

	private _mail: MailModel;
	private _backendResult = new IndicatorModel();
}


export { MailForm };
