import { Asserter } from '../common/Asserter';
import { INewstickerClient } from '../common/NewstickerClient';
import { stringToInt } from '../common/utils';
import { NotEmptyValidator } from '../common/Validators';
import { NewstickerEntryData } from '../data/NewstickerData';
import { ComputedFlagModel } from './ComputedFlagModel';
import { DateTimeModel } from './DateTimeModel';
import { Event } from './Event';
import { IEvent } from './IEvent';
import { IFlagModel } from './IFlagModel';
import { IModel } from './IModel';
import { ITextModel } from './ITextModel';
import { IValidatedTextModel } from './IValidatedTextModel';
import { TextModel } from './TextModel';
import { ValidatedTextModel } from './ValidatedTextModel';
import { WasChangedModel } from './WasChangedModel';


/**
 * Dieses Model repräsentiert einen Eintrag im Newsticker.
 */
class NewstickerEntryModel implements IModel
{
	constructor(client: INewstickerClient, data?: NewstickerEntryData)
	{
		this._onSubmodelChanged = this._onSubmodelChanged.bind(this);
		this._getIsValid = this._getIsValid.bind(this);

		if (!data)
			data = _createEmptyEntry();

		this._client = client;
		this._id = new TextModel(data.id ?? NewstickerEntryModel._nextId());
		this._dateTime = new DateTimeModel(data.dateTime ?? undefined);
		this._text = new ValidatedTextModel(data.text, new NotEmptyValidator());
		this._isValid = new ComputedFlagModel(this._getIsValid, this);

		this._id.onChanged.subscribe(this._onSubmodelChanged);
		this._dateTime.onChanged.subscribe(this._onSubmodelChanged);
		this._text.onChanged.subscribe(this._onSubmodelChanged);

		this._wasChanged = new WasChangedModel(this);
		// Zirkelschluss
		this._wasChanged.onChanged.subscribe(this._onSubmodelChanged);
	}

	get onChanged(): IEvent<this>   { return this._onChanged; }

	get isValid(): IFlagModel       { return this._isValid; }
	get id(): ITextModel            { return this._id; }
	get dateTime(): DateTimeModel   { return this._dateTime; }
	get text(): IValidatedTextModel { return this._text; }
	get wasChanged(): IFlagModel    { return this._wasChanged; }

	get isPersisted(): boolean
	{
		return this._hasPermanentId();
	}

	async save(): Promise<void>
	{
		Asserter.assert(this.isValid.value === true, 'not allowed when invalid');

		let savedData: NewstickerEntryData;
		if (this._hasPermanentId())
			savedData = await this._client.update(this._toJSON());
		else
			savedData = await this._client.post(this._toJSON());

		this._refresh(savedData);
	}

	async refresh(): Promise<void>
	{
		Asserter.assert(this._hasPermanentId(), 'only allowed when persisted');

		const idAsInt = stringToInt(this._id.text);
		const refreshedData = await this._client.fetch(idAsInt);
		this._refresh(refreshedData);
	}

	private _refresh(newData: NewstickerEntryData): void
	{
		Asserter.assert(newData.id !== null, 'only allowed with persisted data');
		Asserter.assert(newData.dateTime !== null, 'persisted data must have dateTime');

		this._onChanged.collectWhile(() => {
			this._id.setText(newData.id!);
			this._dateTime.setValue(newData.dateTime!);
			this._text.setText(newData.text);
		});

		this._wasChanged.reset();
	}

	private _onSubmodelChanged(): void
	{
		this._notifyChange();
	}

	private _notifyChange(): void
	{
		this._onChanged.notify(this, undefined);
	}

	private _getIsValid(): boolean
	{
		return this._dateTime.isValid() && this._text.isValid();
	}

	private _toJSON(): NewstickerEntryData
	{
		Asserter.assert(this.isValid.value === true, 'not allowed when invalid');

		const id = this._hasPermanentId() ? this._id.text : null;

		return {
			id: id,
			dateTime: this._dateTime.value!,
			text: this._text.text
		};
	}

	private _hasPermanentId(): boolean
	{
		return _isPermanentId(this._id.text);
	}

	private static _nextId(): string
	{
		return (NewstickerEntryModel._registrationId--).toString();
	}

	private static _registrationId = -1;

	private readonly _client: INewstickerClient;
	private readonly _onChanged = new Event<this>();
	private readonly _id: TextModel;
	private readonly _dateTime: DateTimeModel;
	private readonly _text: ValidatedTextModel;
	private readonly _isValid: ComputedFlagModel<this>;
	private readonly _wasChanged: WasChangedModel<this>;
}


function _createEmptyEntry(): NewstickerEntryData
{
	return {
		id: null,
		dateTime: null,
		text: ''
	};
}


function _isPermanentId(id: string): boolean
{
	return stringToInt(id) > 0;
}


export { NewstickerEntryModel };
