import { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { Spacer } from './Sheet/Spacer';


class Footer extends PureComponent
{
	render()
	{
		const year = new Date().getFullYear();
		return (
			<Container fluid className="bg-primary bg-gradient mt-4 p-3">
				<div className="d-flex flex-column flex-md-row align-items-center justify-content-md-center">
					<div className="text-dark">
						© {year} Bremer Judo-Club e.V.
					</div>
					<Spacer size="3rem" className="d-none d-md-block" />
					<div className="">
						<a href="mailto:contact@mastersbremen.com" className="link-dark">
							contact@mastersbremen.com
						</a>
					</div>
					<Spacer size="3rem" className="d-none d-md-block" />
					<div className="">
						<Link to='/impressum' className="link-dark">
							Impressum
						</Link>
					</div>
				</div>
			</Container>
		);
	}
}


export { Footer };
