/**
 * Detektion, ob wir im Browser/in Node laufen. Hab ich hier her:
 * https://github.com/flexdinesh/browser-or-node
 * Das ist wegen der Tests notwendig. Dort laufen wir in der Node-Umgebung
 * und haben da z.B. kein alert().
 */
const _isNode = typeof process !== 'undefined' && process.versions !== null && process.versions.node !== null;

/**
 * Asserter ist ein Namespace für die statischen Assertions.
 */
class Asserter
{
	static assert(condition: unknown, message: string, ...toLog: unknown[]): asserts condition
	{
		if (condition)
			return;
		const userMessage = 'assert fehlgeschlagen: ' + message;
		Asserter._emitError(userMessage, ...toLog);
	}

	static fail(message: string, ...toLog: unknown[]): never
	{
		const userMessage = 'fail: ' + message;
		Asserter._emitError(userMessage, ...toLog);
	}

	static unknown(condition: unknown, message: string, ...toLog: unknown[]): asserts condition
	{
		if (condition)
			return;
		const userMessage = 'unknown: ' + message;
		Asserter._emitError(userMessage, ...toLog);
	}

	private static _emitError(message: string, ...toLog: unknown[]): never
	{
		console.assert(false, message, ...toLog);
		if (!_isNode)
			alert(message);
		throw new Error(message);
	}
}

export { Asserter };
