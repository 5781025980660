import { PureComponent, ReactNode, RefObject, createRef } from 'react';
import { TR, Translator } from '../common/Translator';
import { ComputedFlagModel } from '../models/ComputedFlagModel';
import { ICampCompetitorModel } from '../models/ICampCompetitorModel';
import { ICampRegistrationModel } from '../models/ICampRegistrationModel';
import { moveSubscription } from '../models/IEvent';
import { IObservableCollection } from '../models/IObservableCollection';
import { Button } from './Button';
import { CampCompetitorEdit } from './CampCompetitorEdit';
import { Label } from './Label';
import { Row } from './Row';


class CompetitorHeader extends PureComponent
{
	constructor(props: {})
	{
		super(props);

		this._onLanguageChanged = this._onLanguageChanged.bind(this);
	}

	render()
	{
		return (
			<Row className="mb-1">
				<Label label={TR(`CampRegistrationForm::Vorname`)} className='d-none d-md-block col-md-3' />
				<Label label={TR(`CampRegistrationForm::Nachname`)} className='d-none d-md-block col-md-3' />
				<Label label={TR(`CampRegistrationForm::Geburtsjahr`)} className='d-none d-md-block col-md-2' />
				<Label label={TR(`CampRegistrationForm::U18/U21`)} className='d-none d-md-block col-md-2' />
			</Row>
		);
	}

	componentDidMount(): void
	{
		Translator.instance().languageChanged.subscribe(this._onLanguageChanged);
	}

	componentWillUnmount(): void
	{
		Translator.instance().languageChanged.unsubscribe(this._onLanguageChanged);
	}

	private _onLanguageChanged(): void
	{
		this.forceUpdate();
	}
}


class Computed
{
	constructor(registration: ICampRegistrationModel)
	{
		this._isDeleteVisible = new ComputedFlagModel(competitors => competitors.items.length > 1, registration.competitors);
	}

	get isDeleteVisible()
	{
		return this._isDeleteVisible;
	}

	setRegistration(registration: ICampRegistrationModel)
	{
		this._isDeleteVisible.setModel(registration.competitors);
	}

	private _isDeleteVisible: ComputedFlagModel<IObservableCollection<ICampCompetitorModel>>;
}


type Props = {
	registration: ICampRegistrationModel;
};


class CampCompetitorForm extends PureComponent<Props>
{
	constructor(props: Props)
	{
		super(props);

		this._onCompetitorAdded = this._onCompetitorAdded.bind(this);
		this._onCompetitorRemoved = this._onCompetitorRemoved.bind(this);
		this._onCompetitorsChanged = this._onCompetitorsChanged.bind(this);
		this._onLanguageChanged = this._onLanguageChanged.bind(this);

		this._computed = new Computed(this.props.registration);
	}

	render(): ReactNode
	{
		const lastIdx = this.props.registration.competitors.items.length - 1;
		const competitors = this.props.registration.competitors.items.map((c, idx) => {
			// Die Ref nur an das letzte Element übergeben.
			const ref = idx === lastIdx ? this._focusTarget : undefined;
			return (
				<Row key={c.id.text} className="gy-1 mb-1 align-items-center">
					<CampCompetitorEdit ref={ref} competitor={c} withDelete={this._computed.isDeleteVisible} onDeleted={this._onCompetitorRemoved} />
				</Row>
			);
		});

		return (
			<div className='card border-secondary my-3'>
				<div className='card-header'>{TR('CampRegistrationForm::Angaben zu den Teilnehmern')}</div>
				<div className='card-body'>
					<CompetitorHeader />
					{competitors}
					<Button className='btn btn-outline-secondary' onClicked={this._onCompetitorAdded}>
						{TR('CampRegistrationForm::Teilnehmer hinzufügen')}
					</Button>
				</div>
			</div>
		);
	}

	componentDidMount(): void
	{
		this.props.registration.competitors.onChanged.subscribe(this._onCompetitorsChanged);
		Translator.instance().languageChanged.subscribe(this._onLanguageChanged);
	}

	componentWillUnmount(): void
	{
		this.props.registration.competitors.onChanged.unsubscribe(this._onCompetitorsChanged);
		Translator.instance().languageChanged.unsubscribe(this._onLanguageChanged);
	}

	componentDidUpdate(prevProps: Props)
	{
		this._computed.setRegistration(this.props.registration);
		moveSubscription(prevProps.registration.competitors.onChanged, this.props.registration.competitors.onChanged, this._onCompetitorsChanged);

		if (this._focusLastOnNextUpdate)
		{
			this._focusTarget.current!.focus();
			this._focusLastOnNextUpdate = false;
		}
	}

	private _onCompetitorAdded()
	{
		this.props.registration.addCompetitor();
		this._focusLastOnNextUpdate = true;
	}

	private _onCompetitorRemoved(competitor: ICampCompetitorModel)
	{
		this.props.registration.removeCompetitor(competitor);
		this._focusLastOnNextUpdate = true;
	}

	private _onCompetitorsChanged(competitors: IObservableCollection<ICampCompetitorModel>)
	{
		this.forceUpdate();
	}

	private _onLanguageChanged(translator: Translator)
	{
		this.forceUpdate();
	}

	private readonly _focusTarget: RefObject<CampCompetitorEdit> = createRef<CampCompetitorEdit>();;
	private _focusLastOnNextUpdate: boolean = false;
	private readonly _computed: Computed;
}


export { CampCompetitorForm };
