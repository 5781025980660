import { Asserter } from '../common/Asserter';
import { Nullable, Optional } from '../common/Optional';
import { stringToInt } from '../common/utils';
import { DummyValidator, MultiAgeGroupValidator, NameValidator, NotNullValidator } from '../common/Validators';
import { CompetitorData } from '../data/CompetitorData';
import { AgeGroupServerData, AgeGroupsServerData } from '../data/RegistrationFormData';
import { ComputedFlagModel } from './ComputedFlagModel';
import { Event } from './Event';
import { IAltersklasseChoiceModel, ICompetitorModel, IWeightCategoryChoiceModel } from './ICompetitorModel';
import { IEvent } from './IEvent';
import { IFlagModel } from './IFlagModel';
import { IValidatedTextModel } from './IValidatedTextModel';
import { GenericValidatedChoiceModel } from './ValidatedChoiceModel';
import { ValidatedTextModel } from './ValidatedTextModel';


class CompetitorModel implements ICompetitorModel
{
	constructor(ageGroups: AgeGroupsServerData, competitor?: CompetitorData)
	{
		Asserter.assert(competitor === undefined || competitor.id !== undefined, 'must be set by server');

		// Kleiner Trick, damit man nicht immer bind() aufrufen muss.
		this._onSubmodelChanged = this._onSubmodelChanged.bind(this);
		this._onAltersklasseChanged = this._onAltersklasseChanged.bind(this);
		this._onGeburtsjahrChanged = this._onGeburtsjahrChanged.bind(this);
		this._getIsValid = this._getIsValid.bind(this);

		this._ageGroups = new Map(Object.entries(ageGroups));

		this._jahrgangValidator = new MultiAgeGroupValidator(new Map(Object.entries(ageGroups)));

		const id = competitor?.id!.toString() ?? CompetitorModel._nextCompetitorId();
		const vorname = competitor?.vorname ?? '';
		const nachname = competitor?.nachname ?? '';
		const geburtsjahr = competitor?.geburtsjahr.toString() ?? '';

		this._id = new ValidatedTextModel(id, _dummyValidator);
		this._vorname = new ValidatedTextModel(vorname, _nameValidator);
		this._vorname.onChanged.subscribe(this._onSubmodelChanged);
		this._nachname = new ValidatedTextModel(nachname, _nameValidator);
		this._nachname.onChanged.subscribe(this._onSubmodelChanged);
		this._geburtsjahr = new ValidatedTextModel(geburtsjahr.toString(), this._jahrgangValidator);
		this._geburtsjahr.onChanged.subscribe(this._onGeburtsjahrChanged);

		this._createAkGk(competitor?.altersklasse, competitor?.gewichtsklasse);

		this._isValid = new ComputedFlagModel(this._getIsValid, this);
	}

	private _createAkGk(altersklasse: Optional<string>, weightCategory: Optional<number>)
	{
		let akChoices: Nullable<string>[] = [null];
		let weightCategories: number[] = [];

		// Die möglichen Alters- und Gewichtsklassen hängen vom Jahrgang ab.
		if (this._geburtsjahr.isValid())
		{
			Asserter.assert(altersklasse !== undefined, 'Must be set when year of birth is valid.');
			akChoices = this._jahrgangValidator.possibleAgeGroups();
			weightCategories = this._ageGroups.get(altersklasse)!.weight_categories;
		}

		this._altersklasse = new GenericValidatedChoiceModel(akChoices, altersklasse ?? null, _akValidator);
		this._altersklasse.onChanged.subscribe(this._onAltersklasseChanged);

		this._gewichtsklasse = new GenericValidatedChoiceModel([null, ...weightCategories], weightCategory ?? null, _gkValidator);
		this._gewichtsklasse.onChanged.subscribe(this._onSubmodelChanged);
	}

	get onChanged(): IEvent<this>
	{
		return this._onChanged;
	}

	get id(): IValidatedTextModel                    { return this._id; }
	get vorname(): IValidatedTextModel               { return this._vorname; }
	get nachname(): IValidatedTextModel              { return this._nachname; }
	get geburtsjahr(): IValidatedTextModel           { return this._geburtsjahr; }
	get altersklasse(): IAltersklasseChoiceModel     { return this._altersklasse; }
	get gewichtsklasse(): IWeightCategoryChoiceModel { return this._gewichtsklasse; }
	get isValid(): IFlagModel                        { return this._isValid; }

	clone(): CompetitorModel
	{
		// blöd, dass man das nicht automatisch machen kann
		const ageGroups: AgeGroupsServerData = {
			U18: this._ageGroups.get('U18')!,
			U21: this._ageGroups.get('U21')!
		};

		const copy = new CompetitorModel(ageGroups);
		copy.vorname.setText(this.vorname.text);
		copy.nachname.setText(this.nachname.text);
		copy.geburtsjahr.setText(this.geburtsjahr.text);
		copy.altersklasse.setSelected(this.altersklasse.selected);
		copy.gewichtsklasse.setSelected(this.gewichtsklasse.selected);

		return copy;
	}


	toJSON(): CompetitorData
	{
		Asserter.assert(this.isValid.value, 'not allowed when invalid');

		const idAsInt = stringToInt(this._id.text);

		return {
			id: idAsInt > 0 ? idAsInt : undefined,
			vorname: this._vorname.text,
			nachname: this._nachname.text,
			geburtsjahr: stringToInt(this._geburtsjahr.text),
			altersklasse: this._altersklasse.selected!,
			gewichtsklasse: this._gewichtsklasse.selected!
		};
	}

	private _getIsValid(): boolean
	{
		return this._vorname.isValid()
			&& this._nachname.isValid()
			&& this._geburtsjahr.isValid()
			&& this._altersklasse.isValid()
			&& this._gewichtsklasse.isValid();
	}

	private _onAltersklasseChanged()
	{
		const altersklasse = this._altersklasse.selected;

		// In jedem Fall das Model für die Gewichtsklasse neu erzeugen, da sich auch
		// die Auswahl der Gewichtsklassen ändert.
		let weightCategories: number[] = [];
		if (this._altersklasse.isValid())
			weightCategories = this._ageGroups.get(altersklasse!)!.weight_categories;

		this._gewichtsklasse.setChoices([null, ...weightCategories]);

		this._notifyChange();
	}

	private _onGeburtsjahrChanged()
	{
		if (this._geburtsjahr.isValid())
		{
			const akChoices = this._jahrgangValidator.possibleAgeGroups();
			const altersklasse = akChoices[0];
			const weightCategories = this._ageGroups.get(altersklasse)!.weight_categories;

			this._altersklasse.setChoices(akChoices);
			this._altersklasse.setSelected(altersklasse);
			this._gewichtsklasse.setChoices([null, ...weightCategories]);
		}
		else
		{
			this._altersklasse.setChoices([null]);
			this._gewichtsklasse.setChoices([null]);
		}

		this._notifyChange();
	}

	private _onSubmodelChanged()
	{
		this._notifyChange();
	}

	private _notifyChange()
	{
		this._onChanged.notify(this, undefined);
	}

	/**
	 * Um in React eine Liste von Elementen anzeigen zu können, braucht es eine Id.
	 * Im Prinzip hat ein Competitor eine Id, jedoch erst nachdem er im System gemeldet wurde.
	 * Während der Registrierung brauchen wir also auch schon eine vorläufige Id.
	 * Das ist diese hier. Und damit wir sie von der echten Id unterscheiden können,
	 * wählen wir eine negative Zahl.
	 */
	private static _nextCompetitorId(): string
	{
		 return (CompetitorModel._competitorId--).toString();
	}

	private static _competitorId = -1;

	private _ageGroups: Map<string, AgeGroupServerData>;
	private _jahrgangValidator: MultiAgeGroupValidator;
	private _id: ValidatedTextModel;
	private _vorname: ValidatedTextModel;
	private _nachname: ValidatedTextModel;
	private _geburtsjahr: ValidatedTextModel;
	private _altersklasse: GenericValidatedChoiceModel<Nullable<string>> = null!;
	private _gewichtsklasse: GenericValidatedChoiceModel<Nullable<number>> = null!;
	private _isValid: ComputedFlagModel<this>;
	private _onChanged = new Event<this>();
}


//==============================================================================


const _dummyValidator = new DummyValidator();
const _nameValidator = new NameValidator();
const _gkValidator = new NotNullValidator<number>();
const _akValidator = new NotNullValidator<string>();


export { CompetitorModel };
