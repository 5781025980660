import { PureComponent } from 'react';
import { ProfileForm } from '../components/ProfileForm';
import { AppUserModel } from '../models/AppUserModel';


type Props = {
	user: AppUserModel;
};


class ProfileRoute extends PureComponent<Props>
{
	public render()
	{
		return <ProfileForm user={this.props.user} />;
	}
}


export { ProfileRoute };
