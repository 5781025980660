import { AllTournamentsData } from '../data/AllTournamentsData';
import { ConfigData } from '../data/ConfigData';
import { DictionaryData } from '../data/DictionaryData';
import { LoginRequestData, LoginResponseData } from '../data/LoginData';
import { MailData } from '../data/MailData';
import { SheetData } from '../data/SheetData';
import { TournamentResultsData } from '../data/TournamentResultsData';
import { TournamentStatisticsData } from '../data/TournamentStatisticsData';
import { CampRegistrationClient, ICampRegistrationClient } from './CampRegistrationClient';
import { FilesClient, IFilesClient } from './FilesClient';
import { HttpClient } from './HttpClient';
import { IBackend } from './IBackend';
import { INewstickerClient, NewstickerClient } from './NewstickerClient';
import { Nullable } from './Optional';
import { IRegistrationClient, RegistrationClient } from './RegistrationClient';

/**
 * Kommunikation mit dem Backend.
 */
class Backend implements IBackend
{
	/**
	 * An den Origin kommt man via window.location.origin
	 */
	constructor(baseUrl: URL)
	{
		this._client = new HttpClient(baseUrl);
		this._registrationClient = new RegistrationClient(this._client);
		this._campRegistrationClient = new CampRegistrationClient(this._client);
		this._newstickerClient = new NewstickerClient(this._client);
		this._filesClient = new FilesClient(this._client);
	}

	setToken(token: string)
	{
		this._client.setToken(token);
	}

	unsetToken()
	{
		this._client.unsetToken();
	}

	async login(email: string, password: string): Promise<Nullable<LoginResponseData>>
	{
		const body: LoginRequestData = {
			email: email,
			password: password
		};

		const response = await this._client.post('api/login', body);

		if (!response.ok)
			return null;

		const data = await response.json();
		if (data.token === undefined)
			return null;

		return {
			token: data.token,
			isAdmin: data.isAdmin
		}
	}

	async fetchConfig(): Promise<ConfigData>
	{
		const response = await this._client.get('api/config');
		this._client.checkResponse(response);
		return await response.json() as ConfigData;
	}

	async updateConfig(data: ConfigData): Promise<void>
	{
		const response = await this._client.put('api/config', data);
		this._client.checkResponse(response);
	}

	async fetchTranslation(app: string, lang: string): Promise<DictionaryData>
	{
		const response = await this._client.get(`api/translations/${app}/${lang}`);
		this._client.checkResponse(response);
		const data = await response.json() as DictionaryData;
		return data;
	}

	async fetchAllTournaments(): Promise<AllTournamentsData>
	{
		const response = await this._client.get('api/tournaments');
		this._client.checkResponse(response);
		const data = await response.json() as AllTournamentsData;
		return data;
	}

	async fetchTournamentResults(tournamentId: number): Promise<TournamentResultsData>
	{
		const response = await this._client.get(`api/tournaments/${tournamentId}/results`);
		this._client.checkResponse(response);
		const data = await response.json() as TournamentResultsData;
		return data;
	}

	async fetchTournamentStatistics(tournamentId: number): Promise<TournamentStatisticsData>
	{
		const response = await this._client.get(`api/tournaments/${tournamentId}/stats`);
		this._client.checkResponse(response);
		const data = await response.json() as TournamentStatisticsData;
		return data;
	}

	async fetchSheet(tournamentId: number, category: number, sheetIndex: number): Promise<SheetData>
	{
		const response = await this._client.get(`api/sheets/${tournamentId}/${category}/${sheetIndex}`);
		this._client.checkResponse(response);
		const data = await response.json() as SheetData;
		return data;
	}

	get registrations(): IRegistrationClient
	{
		return this._registrationClient;
	}

	get campRegistrations(): ICampRegistrationClient
	{
		return this._campRegistrationClient;
	}

	get newstickerEntries(): INewstickerClient
	{
		return this._newstickerClient;
	}

	get files(): IFilesClient
	{
		return this._filesClient;
	}

	async sendMail(mailData: MailData): Promise<void>
	{
		const formData = new FormData();
		formData.set('to', mailData.to);
		formData.set('toGermans', _boolToString(mailData.toGermans));
		formData.set('toInternationals', _boolToString(mailData.toInternationals));
		formData.set('toRegistered', _boolToString(mailData.toRegistered));
		formData.set('subject', mailData.subject);
		formData.set('body', mailData.body);
		if (mailData.attachment !== undefined)
			formData.set('attachment', mailData.attachment);
		if (mailData.sendDate !== undefined)
			formData.set('sendDate', mailData.sendDate);
		// formData.set('sendDate', '2022-06-12T19:13:00+02:00');

		const response = await this._client.postFormData('api/mail', formData);
		this._client.checkResponse(response);
	}

	private readonly _client: HttpClient;
	private readonly _registrationClient: RegistrationClient;
	private readonly _campRegistrationClient: CampRegistrationClient;
	private readonly _newstickerClient: NewstickerClient;
	private readonly _filesClient: FilesClient;
}


/**
 * Hilfsmethode, die wir brauchen, wenn wir bool'sche Werte in einem FormRequest
 * verpackt an das Backend schicken wollen. Dort werden die Werte immer als String
 * kodiert und laravel erwartet dann entweder '0' oder '1'.
 * Siehe: https://laravel.com/docs/8.x/validation#rule-boolean
 */
function _boolToString(value: boolean)
{
	return value ? '1' : '0';
}


export { Backend };
