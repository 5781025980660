import { PureComponent, ReactNode } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import BsNavbar from 'react-bootstrap/Navbar';
import { FaSignInAlt, FaUser } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import { AppUserModel } from '../models/AppUserModel';
import { ConfigModel } from '../models/ConfigModel';
import { moveSubscription } from '../models/IEvent';
import { ContentSwitch } from './ContentSwitch';
import { HideableWithModel } from './Hideable';


type Props = {
	user: AppUserModel;
	config: ConfigModel;
};


/**
 * Nach mehreren Versuchen hab funktioniert jetzt endlich die collapsable Navbar --
 * die mit Burgermenü.
 * Erforderlich dafür sind (zumindest nach bisheriger Recherge):
 * - Das Bootstrap JavaScript-Modul collapse.js ('bootstrap/js/dist/collapse')
 * - Die CSS-Module nav, navbar und transitions.
 * Popper.js scheint dafür laut Doku nicht notwendig zu sein, verwende ich jedoch mittlerweile eh.
 */
class Navbar extends PureComponent<Props>
{
	constructor(props: Props)
	{
		super(props);

		this._renderUserDropdown = this._renderUserDropdown.bind(this);
		this._renderLogin = this._renderLogin.bind(this);
		this._onUserChanged = this._onUserChanged.bind(this);
	}

	render(): ReactNode
	{
		return (
			<BsNavbar bg="primary" variant="light" expand="md" sticky="top" className="fw-bold bg-gradient">
				<Container fluid>
					<BsNavbar.Toggle aria-controls="masters-nav-collapse" />

					<BsNavbar.Collapse id="masters-nav-collapse" className="justify-content-center">
						<Nav>
							<Nav.Link as={NavLink} to="/">Home</Nav.Link>
							<HideableWithModel isShown={this.props.config.registrationEnabled}>
								<Nav.Link as={NavLink} to='/registration/hub'>Registration</Nav.Link>
							</HideableWithModel>
							<Nav.Link as={NavLink} to='/tournaments'>Archive (Results)</Nav.Link>
							<ContentSwitch
								isOn={this.props.user.isLoggedIn}
								contentWhenOn={this._renderUserDropdown}
								contentWhenOff={null} // Hier nicht mehr Login rendern; das soll für Nicht-User unsichtbar sein.
							/>
						</Nav>
					</BsNavbar.Collapse>
				</Container>
			</BsNavbar>
		);
	}

	componentDidMount(): void
	{
		this.props.user.onChanged.subscribe(this._onUserChanged);
	}

	componentWillUnmount(): void
	{
		this.props.user.onChanged.unsubscribe(this._onUserChanged);
	}

	componentDidUpdate(prevProps: Props): void
	{
		moveSubscription(prevProps.user.onChanged, this.props.user.onChanged, this._onUserChanged);
	}

	private _renderLogin(): ReactNode
	{
		return (
			<Nav.Link as={NavLink} to="/login">
				<span className="text-nowrap">
					<FaSignInAlt size="1.1em" className="mb-1 align-bottom" /> Login
				</span>
			</Nav.Link>
		);
	}

	private _renderUserDropdown(): ReactNode
	{
		const title = (
			<span className="text-nowrap">
				<FaUser size="0.9em" className="mb-1 align-bottom" /> {this.props.user.email}
			</span>
		);

		return (
			<NavDropdown title={title}>
				<NavDropdown.Item as={NavLink} to="/profile">Profil</NavDropdown.Item>
				<NavDropdown.Item as={NavLink} to="/registrations/overview">Meldungen</NavDropdown.Item>
				<NavDropdown.Item as={NavLink} to="/camp-registrations/overview">Camp-Meldungen</NavDropdown.Item>
				<ContentSwitch
					isOn={this.props.user.isAdmin}
					contentWhenOn={<NavDropdown.Item as={NavLink} to="/config">Konfiguration</NavDropdown.Item>}
					contentWhenOff={null}
				/>
				<NavDropdown.Item as={NavLink} to="/newsticker">Newsticker</NavDropdown.Item>
				<NavDropdown.Item as={NavLink} to="/files">Files</NavDropdown.Item>
				<NavDropdown.Item as={NavLink} to="/mail">E-Mail senden</NavDropdown.Item>
			</NavDropdown>
		);
	}

	private _onUserChanged(appUser: AppUserModel): void
	{
		this.forceUpdate();
	}
}


export { Navbar };
