import { PropsWithChildren, PureComponent, ReactNode } from 'react';
import { Asserter } from '../common/Asserter';
import { IFlagModel } from '../models/IFlagModel';
import { IModel } from '../models/IModel';
import { RequireModelState } from './RequireModelState';


type Props = PropsWithChildren<{
	model: IFlagModel;
}>;


class RequireRegistrationEnabled extends PureComponent<Props>
{
	constructor(props: Props)
	{
		super(props);

		this._isEnabled = this._isEnabled.bind(this);
	}

	render(): ReactNode
	{
		return (
			<RequireModelState model={this.props.model} isAllowed={this._isEnabled}>
				{this.props.children}
			</RequireModelState>
		);
	}

	private _isEnabled(model: IModel)
	{
		Asserter.assert(model === this.props.model, 'inconsistency');
		return this.props.model.value;
	}
}


export { RequireRegistrationEnabled };
