import { ITextModel } from './ITextModel';
import { TextModel } from './TextModel';
import { Asserter } from '../common/Asserter';
import { IModel } from './IModel';
import { IEvent, moveSubscription } from './IEvent';
import { EventForwarder } from './EventForwarder';


/**
 * Funktion, die zum Model den Text liefert.
 */
type Compute<TModel> = (model: TModel) => string;


/**
 * Ein TextModel, dessen Wert auf Basis eines anderen Models berechnet werden kann.
 * Die Funktion dafür muss im Konstruktor übergeben werden.
 * Das Model kann zur Laufzeit geändert werden.
 */
class ComputedTextModel<TModel extends IModel> implements ITextModel
{
	constructor(compute: Compute<TModel>, sourceModel: TModel)
	{
		this._onModelChanged = this._onModelChanged.bind(this);

		this._sourceModel = sourceModel;
		this._compute = compute;
		this._textModel = new TextModel(compute(sourceModel));
		this._onChanged = new EventForwarder(this._textModel.onChanged, this);

		this._sourceModel.onChanged.subscribe(this._onModelChanged);
	}

	get onChanged(): IEvent<this>
	{
		return this._onChanged.event;
	}

	get text()
	{
		return this._textModel.text;
	}

	setText(text: string)
	{
		Asserter.fail('readonly');
	}

	setModel(model: TModel)
	{
		if (model === this._sourceModel)
			return;

		moveSubscription(this._sourceModel.onChanged, model.onChanged, this._onModelChanged);
		this._sourceModel = model;
		this._onModelChanged(this._sourceModel);
	}

	private _onModelChanged(model: IModel)
	{
		this._textModel.setText(this._compute(this._sourceModel));
	}

	private _sourceModel: TModel;
	private _compute: Compute<TModel>;
	private _textModel: TextModel;
	private _onChanged: EventForwarder<TextModel, this>;
}


export { ComputedTextModel };
