import { PureComponent, ReactNode } from 'react';
import { ICampRegistrationModel } from '../models/ICampRegistrationModel';
import { moveSubscription } from '../models/IEvent';
import { IObservableCollection } from '../models/IObservableCollection';
import { ITextModel } from '../models/ITextModel';
import { TextModel } from '../models/TextModel';
import { Label, LabelWithModel } from './Label';
import { Row } from './Row';


class Computed
{
	constructor(registrations: IObservableCollection<ICampRegistrationModel>)
	{
		this._updateStatus = this._updateStatus.bind(this);

		this._registrations = registrations;
		this._registrations.onChanged.subscribe(this._updateStatus);

		this._updateStatus(this._registrations);
	}

	get registrationCount(): ITextModel  { return this._registrationCount; }
	get nationCount(): ITextModel        { return this._nationCount; }
	get nationCountU18(): ITextModel     { return this._nationCountU18; }
	get nationCountU21(): ITextModel     { return this._nationCountU21; }
	get competitorCount(): ITextModel    { return this._competitorCount; }
	get competitorCountU18(): ITextModel { return this._competitorCountU18; }
	get competitorCountU21(): ITextModel { return this._competitorCountU21; }
	get betreuerCount(): ITextModel { return this._betreuerCount; }

	setRegistrations(registrations: IObservableCollection<ICampRegistrationModel>): void
	{
		moveSubscription(this._registrations.onChanged, registrations.onChanged, this._updateStatus);
		this._registrations = registrations;
		this._updateStatus(this._registrations);
	}

	private _updateStatus(registrations: IObservableCollection<ICampRegistrationModel>): void
	{
		let competitorCount = 0;
		let betreuerCount = 0;
		let countU18 = 0;
		let countU21 = 0;
		let nations = new Map<string, number>();
		let nationsU18 = new Map<string, number>();
		let nationsU21 = new Map<string, number>();

		registrations.items.forEach(r => {
			competitorCount += r.competitors.items.length;
			betreuerCount += r.betreuer.items.length;

			let localCountU18 = 0;
			let localCountU21 = 0;
			r.competitors.items.forEach(c => {
				if (!c.altersklasse.isValid())
					return;
				if (c.altersklasse.selected === 'U18')
					localCountU18 += 1;
				if (c.altersklasse.selected === 'U21')
					localCountU21 += 1;
			});

			countU18 += localCountU18;
			countU21 += localCountU21;

			if (!r.nation.isValid())
				return;

			const nation = r.nation.selected;
			Computed._incNation(nations, nation!.name);
			if (localCountU18 > 0)
				Computed._incNation(nationsU18, nation!.name);
			if (localCountU21 > 0)
				Computed._incNation(nationsU21, nation!.name);
		});

		this._registrationCount.setText(registrations.items.length.toString());
		this._nationCount.setText(nations.size.toString());
		this._nationCountU18.setText(nationsU18.size.toString());
		this._nationCountU21.setText(nationsU21.size.toString());
		this._competitorCount.setText(competitorCount.toString());
		this._competitorCountU18.setText(countU18.toString());
		this._competitorCountU21.setText(countU21.toString());
		this._betreuerCount.setText(betreuerCount.toString());
	}

	private static _incNation(map: Map<string, number>, nation: string): void
	{
		const currentCount = map.get(nation) ?? 0;
		map.set(nation, currentCount + 1);
	}

	private _registrations: IObservableCollection<ICampRegistrationModel>;
	private readonly _registrationCount = new TextModel();
	private readonly _nationCount = new TextModel();
	private readonly _nationCountU18 = new TextModel();
	private readonly _nationCountU21 = new TextModel();
	private readonly _competitorCount = new TextModel();
	private readonly _competitorCountU18 = new TextModel();
	private readonly _competitorCountU21 = new TextModel();
	private readonly _betreuerCount = new TextModel();
}


//==============================================================================


type Props = {
	registrations: IObservableCollection<ICampRegistrationModel>;
};


class CampRegistrationsStatusLine extends PureComponent<Props>
{
	constructor(props: Props)
	{
		super(props);

		this._computed = new Computed(this.props.registrations);
	}

	render(): ReactNode
	{
		return (
			<>
				<Row className="mt-2">
					<div className="col-12 col-lg-6 d-flex">
						<Label label="total:" className="fw-bold" />
						{this._renderStatus('Meldungen:', this._computed.registrationCount)}
						{this._renderStatus('Nationen:', this._computed.nationCount)}
						{this._renderStatus('Teilnehmer:', this._computed.competitorCount)}
						{this._renderStatus('Betreuer:', this._computed.betreuerCount)}
					</div>
				</Row>
				<Row>
					<div className="col-12 col-lg-3 d-flex">
						<Label label="U18:" className="fw-bold" />
						{this._renderStatus('Nationen:', this._computed.nationCountU18)}
						{this._renderStatus('Teilnehmer:', this._computed.competitorCountU18)}
					</div>
				</Row>
				<Row>
					<div className="col-12 col-lg-3 d-flex">
						<Label label="U21:" className="fw-bold" />
						{this._renderStatus('Nationen:', this._computed.nationCountU21)}
						{this._renderStatus('Teilnehmer:', this._computed.competitorCountU21)}
					</div>
				</Row>
			</>
		);
	}

	componentDidUpdate(prevProps: Props): void
	{
		this._computed.setRegistrations(this.props.registrations);
	}

	_renderStatus(label: string, status: ITextModel): ReactNode
	{
		return (
			<div className="d-flex">
				<Label label={label} className="ps-1" />
				<LabelWithModel model={status} className="ps-1" />
			</div>
		)
	}

	private readonly _computed: Computed;
}


export { CampRegistrationsStatusLine };
