import { PureComponent, ReactNode } from 'react';
import { Asserter } from '../common/Asserter';
import { FetchGuard } from '../common/FetchGuard';
import { IBackend } from '../common/IBackend';
import { Nullable } from '../common/Optional';
import { ResourceProvider } from '../common/ResourceProvider';
import { TR, Translator } from '../common/Translator';
import { RouterData, withRouter } from '../common/withRouter';
import { BusyWithModel } from '../components/Busy';
import { HelpForm } from '../components/HelpForm';
import { LanguageSelectorForm } from '../components/LanguageSelectorForm';
import { RegistrationForm, RegistrationFormType } from '../components/RegistrationForm';
import { RegistrationModel } from '../models/RegistrationModel';


type Props = {
	formType: RegistrationFormType;
	backend: IBackend;
	router: RouterData;
};


type State = {
	registration: Nullable<RegistrationModel>;
};


class RegistrationRoute extends PureComponent<Props, State>
{
	constructor(props: Props)
	{
		super(props);

		this.state = {
			registration: null // kann erst erzeugt werden, wenn formData geladen ist
		};

		this._onSubmit = this._onSubmit.bind(this);
		this._renderContent = this._renderContent.bind(this);
	}

	render(): ReactNode
	{
		// Achtung: hier keine bereits gebundene Funktion übergeben sondern immer eine
		// neue anonyme Funktion erzeugen.
		// Hintergrund: Im React-StrictMode werden im Devel-Mode einige Lifecycle-Funktionen
		// mehrfach gerufen. Seit Version 18 wird auch componentDidMount() mehrfach gerufen.
		// In componentDidMount() erzeugen wir eine neue Meldung und rufen dann setState().
		// Daher wird auch render() mit unterschiedlichem State (unterschiedlichen Meldungen) gerufen.
		// Die Props von Busy würden sich jedoch bei nachfolgenden Aufrufen mit
		// unterschiedlichen Meldungen nicht ändern, wenn man eine gebundene Funktion verwenden würde,
		// sodass _render() dann nicht aufgerufen werden würde.
		// Hier ist es jetzt so, dass jeder Aufruf von render() auch zum Aufruf von _render() führt
		// (sofern registration !== null).
		// Siehe https://reactjs.org/docs/strict-mode.html#ensuring-reusable-state
		//
		// 2023-10-23: Ist jetzt anders gelöst: Mit Model und Integration mit FetchGuard.
		return <BusyWithModel isBusy={this._fetchGuard.isBusy} render={this._renderContent} className="my-4" />
	}

	async componentDidMount(): Promise<void>
	{
		return this._fetchGuard.fetch(() => this._fetchResources());
	}

	private async _fetchResources(): Promise<void>
	{
		// Wir machen das hier nacheinander und nicht parallel, weil die parallele Variante bei Netcup
		// teilweise wesentlich langsamer ist.
		const formData = await ResourceProvider.instance().formData;
		await Translator.instance().changeLanguage(this._initialLanguage(), 'RegistrationForm');

		const registration = RegistrationModel.createEmpty(this.props.backend.registrations, formData, this.props.formType);

		this.setState({ registration });
	}

	private _renderContent(): ReactNode
	{
		Asserter.assert(this.state.registration !== null, 'must not be called when busy');

		return (
			<div className="container-lg mt-4">
				<LanguageSelectorForm />
				<HelpForm labelText='RegistrationForm::Hilfe anzeigen' helpText='RegistrationForm::Hilfetext' />
				<RegistrationForm
					registration={this.state.registration!}
					formType={this.props.formType}
					withDetails={false}
					onSubmit={this._onSubmit}
				/>
			</div>
		);
	}

	private async _onSubmit(): Promise<void>
	{
		Asserter.assert(this.state.registration !== null, 'must not be called when busy');

		const registration = this.state.registration;

		// Damit die Meldung nicht mehr verändert werden kann und nicht erneut der Submit-Button
		// gedrückt werden kann, während die Meldung submitted wird.
		this.setState({ registration: null });

		try
		{
			await registration.save();
		}
		catch (e)
		{
			this.props.router.navigate('/error', { state: _buildErrorInfo(registration) });
			return;
		}

		// generatePath() (aus react-router-dom) kommt mit den Query-Params irgendwie nicht klar.
		// Genauer: Typescript meckert rum; Die Deklaration scheint nicht für Query-Params gemacht zu sein.
		const detailsUrl = `/registration/${registration.id.text}?email=${registration.email.text}`;
		const info = TR('RegistrationForm::erfolgreich abgeschickt');
		this.props.router.navigate(detailsUrl, { state: info });
	}

	private _initialLanguage(): 'de' | 'en'
	{
		switch (this.props.formType)
		{
			case 'Admin':
			case 'InternationalNationalTeam':
			case 'InternationalClub':
				return 'en';
			case 'GermanClub':
			case 'GermanKader':
				return 'de';
		}
	}

	private readonly _fetchGuard = new FetchGuard();
}


function _buildErrorInfo(registration: RegistrationModel): string
{
	const registrationData = registration.toJSON();
	const registrationAsJson = `<br /><hr /><pre>${JSON.stringify(registrationData, null, 4)}</pre>`;
	return TR('RegistrationForm::Fehler beim Abschicken') + registrationAsJson;
}


const RegistrationWithRouter = withRouter(RegistrationRoute);


export { RegistrationWithRouter as RegistrationRoute };
