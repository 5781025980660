import { PureComponent, ReactNode } from 'react';
import { TR, Translator } from '../common/Translator';
import { IRegistrationModel } from '../models/IRegistrationModel';
import { ButtonWithModel } from './Button';
import { CompetitorForm } from './CompetitorForm';
import { Hideable } from './Hideable';
import { RegistreeForm } from './RegistreeForm';


type RegistrationFormType = 'GermanClub' | 'GermanKader' | 'InternationalClub' | 'InternationalNationalTeam' | 'Admin';


type Props = {
	registration: IRegistrationModel;
	formType: RegistrationFormType;
	withDetails: boolean;
	onSubmit?: () => void; // wenn undefined, wird der Submit-Button nicht gerendert
};


class RegistrationForm extends PureComponent<Props>
{
	constructor(props: Props)
	{
		super(props);

		this._onLanguageChanged = this._onLanguageChanged.bind(this);
	}

	render(): ReactNode
	{
		return (
			<>
				<RegistreeForm
					registration={this.props.registration}
					formType={this.props.formType}
					withDetails={this.props.withDetails}
				/>
				<CompetitorForm registration={this.props.registration} formType={this.props.formType} />
				<Hideable isShown={this.props.onSubmit !== undefined}>
					<ButtonWithModel
						className="btn btn-secondary rounded-pill"
						isEnabled={this.props.registration.isValid}
						onClicked={this.props.onSubmit}
					>
						{TR('RegistrationForm::Abschicken')}
					</ButtonWithModel>
				</Hideable>
			</>
		);
	}

	componentDidMount(): void
	{
		Translator.instance().languageChanged.subscribe(this._onLanguageChanged);
	}

	componentWillUnmount(): void
	{
		Translator.instance().languageChanged.unsubscribe(this._onLanguageChanged);
	}

	private _onLanguageChanged(translator: Translator)
	{
		this.forceUpdate();
	}
}


export { RegistrationForm };
export type { RegistrationFormType };
