import { ICampRegistrationClient } from '../common/CampRegistrationClient';
import { IRegistrationClient } from '../common/RegistrationClient';
import { ResourceProvider } from '../common/ResourceProvider';
import { AllCampRegistrationsData } from '../data/AllCampRegistrationsData';
import { RegistrationFormData } from '../data/RegistrationFormData';
import { CampRegistrationModel } from './CampRegistrationModel';
import { Event } from './Event';
import { ICampRegistrationModel } from './ICampRegistrationModel';
import { ICampRegistrationsModel } from './ICampRegistrationsModel';
import { IEvent } from './IEvent';
import { IObservableCollection } from './IObservableCollection';
import { ObservableCollection } from './ObservableCollection';


/**
 * Der Loader für die Meldungen.
 * Die Singleton-Instanz ist über RegistrationsModel::loader() zugreifbar.
 */
class Loader
{
	async load(campRegistrationClient: ICampRegistrationClient, registrationClient: IRegistrationClient): Promise<ICampRegistrationsModel>
	{
		const formData = await ResourceProvider.instance().formData;
		const allRegistrationsData = await campRegistrationClient.fetchAll();
		return new CampRegistrationsModel(campRegistrationClient, registrationClient, formData, allRegistrationsData);
	}
}


class CampRegistrationsModel implements ICampRegistrationsModel
{
	constructor(campRegistrationClient: ICampRegistrationClient, registrationClient: IRegistrationClient, formData: RegistrationFormData, data: AllCampRegistrationsData)
	{
		this._onCollectionChanged = this._onCollectionChanged.bind(this);

		this._formData = formData;
		this._campRegistrationClient = campRegistrationClient;
		this._registrationClient = registrationClient;
		this._registrations = new ObservableCollection<ICampRegistrationModel>(data.map(r => new CampRegistrationModel(campRegistrationClient, registrationClient, formData, r)));

		this._registrations.onChanged.subscribe(this._onCollectionChanged);
	}

	static get loader(): Loader
	{
		return CampRegistrationsModel._loader;
	}

	get onChanged(): IEvent<this>
	{
		return this._onChanged;
	}

	get registrations(): IObservableCollection<ICampRegistrationModel>
	{
		return this._registrations;
	}

	create(): ICampRegistrationModel
	{
		const registration = new CampRegistrationModel(this._campRegistrationClient, this._registrationClient, this._formData);
		this._registrations.add(registration);
		return registration;
	}

	async remove(registration: ICampRegistrationModel): Promise<void>
	{
		if (registration.isPersisted.value === true)
			await this._campRegistrationClient.delete(registration.id.text);

		this._registrations.remove(registration);
	}

	async refresh(): Promise<void>
	{
		const allRegistrationsData = await this._campRegistrationClient.fetchAll();
		this._registrations.reset(allRegistrationsData.map(r => new CampRegistrationModel(this._campRegistrationClient, this._registrationClient, this._formData, r)));
	}

	private _onCollectionChanged(collection: ObservableCollection<ICampRegistrationModel>): void
	{
		this._onChanged.notify(this, undefined);
	}

	private readonly _formData: RegistrationFormData;
	private readonly _campRegistrationClient: ICampRegistrationClient;
	private readonly _registrationClient: IRegistrationClient;
	private readonly _registrations: ObservableCollection<ICampRegistrationModel>;
	private readonly _onChanged = new Event<this>();
	private static readonly _loader = new Loader();
}


export { CampRegistrationsModel };
