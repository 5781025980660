import { PureComponent, ReactNode } from 'react';
import Container from 'react-bootstrap/Container';
import { Navigate } from 'react-router-dom';
import { Nullable } from '../common/Optional';
import { RouterData, withRouter } from '../common/withRouter';


type ConnectedProps = {
	router: RouterData;
};


class ConnectedErrorRoute extends PureComponent<ConnectedProps>
{
	render(): ReactNode
	{
		// Zugriff auf zusätzlichen State, den man beim Link oder Redirect mitgeben kann.
		const info = this.props.router.location.state as Nullable<string>;
		if (info === null)
			return <Navigate to="/not-found" replace />;

		return (
			<Container fluid="md" className="my-4">
				<h1 className="text-center">
					Oops... an Error occurred!
				</h1>
				<div dangerouslySetInnerHTML={{ __html: info }} className="callout-danger rounded bg-white p-2 my-3" />
			</Container>
		);
	}
}


const ErrorRoute = withRouter(ConnectedErrorRoute);


export { ErrorRoute };
