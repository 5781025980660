import { Nullable } from '../common/Optional';
import { areArraysEqual } from '../common/utils';
import { DummyValidator } from '../common/Validators';
import { EventForwarder } from './EventForwarder';
import { IEvent, moveSubscription } from './IEvent';
import { IModel } from './IModel';
import { IObservableCollection } from './IObservableCollection';
import { IValidatedTextModel } from './IValidatedTextModel';
import { ObservableCollection } from './ObservableCollection';
import { ValidatedTextModel } from './ValidatedTextModel';


type FilterPredicate<TItem> = (item: TItem, filter: string) => boolean;


class FilteredCollection<TItem extends IModel> implements IObservableCollection<TItem>
{
	constructor(source: IObservableCollection<TItem>, filterPredicate: FilterPredicate<TItem>)
	{
		this._onFilterChanged = this._onFilterChanged.bind(this);
		this._onSourceChanged = this._onSourceChanged.bind(this);
		this._applyFilter = this._applyFilter.bind(this);

		this._source = source;
		this._filterPredicate = filterPredicate;
		this._filter = new ValidatedTextModel('', new DummyValidator());
		this._filtered = new ObservableCollection<TItem>(this._getFilteredItems());

		this._onChanged = new EventForwarder(this._filtered.onChanged, this);
		this._onAdded = new EventForwarder(this._filtered.onAdded, this);
		this._onRemoved = new EventForwarder(this._filtered.onRemoved, this);

		this._filter.onChanged.subscribe(this._onFilterChanged);
		this._source.onChanged.subscribe(this._onSourceChanged);
	}

	get onChanged(): IEvent<this>
	{
		return this._onChanged.event;
	}

	get onAdded(): IEvent<this, TItem>
	{
		return this._onAdded.event;
	}

	get onRemoved(): IEvent<this, TItem>
	{
		return this._onRemoved.event;
	}

	get items(): Readonly<TItem[]>
	{
		return this._filtered.items;
	}

	get filter(): IValidatedTextModel
	{
		return this._filter;
	}

	setSource(newSource: IObservableCollection<TItem>): void
	{
		moveSubscription(this._source.onChanged, newSource.onChanged, this._onSourceChanged);
		this._source = newSource;
		this._applyFilter();
	}

	add(item: TItem): void
	{
		this._source.add(item);
	}

	remove(item: TItem): void
	{
		this._source.remove(item);
	}

	clear(): void
	{
		this._source.clear();
	}

	reset(items: Readonly<TItem[]>): void
	{
		this._source.reset(items);
	}

	private _onFilterChanged(filter: ValidatedTextModel): void
	{
		// Der Filter soll nicht unmittelbar angewandt werden.
		if (this._timerId)
			window.clearTimeout(this._timerId);
		this._timerId = window.setTimeout(this._applyFilter, 500);

		// Wollen wir hier auch ein onChanged-Event auslösen?
	}

	private _onSourceChanged(source: IObservableCollection<TItem>): void
	{
		this._applyFilter();
	}

	private _applyFilter(): void
	{
		this._timerId = null;

		const filtered = this._getFilteredItems();
		if (areArraysEqual(filtered, this._filtered.items))
			return;

		this._filtered.reset(filtered);
	}

	private _getFilteredItems(): Readonly<TItem[]>
	{
		return this._source.items.filter(item => this._filterPredicate(item, this._filter.text));
	}

	private _source: IObservableCollection<TItem>;
	private readonly _filterPredicate: FilterPredicate<TItem>;
	private readonly _filter: ValidatedTextModel;
	private readonly _filtered: ObservableCollection<TItem>;
	private _timerId: Nullable<number> = null;
	private readonly _onChanged: EventForwarder<ObservableCollection<TItem>, this>;
	private readonly _onAdded: EventForwarder<ObservableCollection<TItem>, this, TItem>;
	private readonly _onRemoved: EventForwarder<ObservableCollection<TItem>, this, TItem>;
}


export { FilteredCollection };
