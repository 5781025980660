import { ChangeEvent, PureComponent, ReactNode, RefObject, createRef } from 'react';
import { Optional } from '../common/Optional';
import { moveSubscription } from '../models/IEvent';
import { IValidatedTextModel } from '../models/IValidatedTextModel';


type InputType = 'text' | 'password' | 'email' | 'url' | 'search' | 'textarea' | 'datetime-local';

type Props = {
	value: string;
	type?: InputType; // text ist default
	placeholder?: string;
	isValid?: boolean; // wenn === undefined, wird der Indikator nicht angezeigt
	isEnabled?: boolean;
	onChanged: (value: string) => void;
	rows?: Optional<number>; // nur für textarea
};


class ValidatedLineEdit extends PureComponent<Props>
{
	constructor(props: Props)
	{
		super(props);

		this._onChanged = this._onChanged.bind(this);

		this._inputRef = createRef<HTMLInputElement>();
		this._textAreaRef = createRef<HTMLTextAreaElement>();
	}

	render(): ReactNode
	{
		let classNames = ['form-control'];
		if (this.props.isValid === true)
			classNames.unshift('is-valid');
		else if (this.props.isValid === false)
			classNames.unshift('is-invalid');

		const isDisabled = this.props.isEnabled === false ? true : undefined;

		if (this.props.type === 'textarea')
			return (
				<textarea
					ref={this._textAreaRef}
					className={classNames.join(' ')}
					value={this.props.value}
					placeholder={this.props.placeholder}
					onChange={this._onChanged}
					disabled={isDisabled}
					rows={this.props.rows}
				/>
			);
		else
			return (
				<input
					ref={this._inputRef}
					type={this.props.type ?? 'text'}
					className={classNames.join(' ')}
					value={this.props.value}
					placeholder={this.props.placeholder}
					onChange={this._onChanged}
					disabled={isDisabled}
				/>
			);
	}

	focus(): void
	{
		this._inputRef.current?.select(); // alles selektieren *und* fokussieren
		this._textAreaRef.current?.select(); // alles selektieren *und* fokussieren
	}

	private _onChanged(e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>): void
	{
		this.props.onChanged(e.target.value)
	}

	private readonly _inputRef: RefObject<HTMLInputElement>;
	private readonly _textAreaRef: RefObject<HTMLTextAreaElement>;
}


type PropsWithModel = {
	model: IValidatedTextModel;
	type?: InputType; // text ist default
	placeholder?: string;
	isEnabled?: boolean;
	rows?: Optional<number>;
};


class ValidatedLineEditWithModel extends PureComponent<PropsWithModel>
{
	constructor(props: PropsWithModel)
	{
		super(props);

		this._onModelChanged = this._onModelChanged.bind(this);
		this._onUiChanged = this._onUiChanged.bind(this);

		this._ref = createRef<ValidatedLineEdit>();
	}

	render(): ReactNode
	{
		return (
			<ValidatedLineEdit
				ref={this._ref}
				value={this.props.model.text}
				type={this.props.type}
				placeholder={this.props.placeholder}
				isValid={this.props.model.isValid()}
				onChanged={this._onUiChanged}
				isEnabled={this.props.isEnabled}
				rows={this.props.rows}
			/>
		);
	}

	focus(): void
	{
		this._ref.current!.focus();
	}

	componentDidMount(): void
	{
		this.props.model.onChanged.subscribe(this._onModelChanged);
	}

	componentWillUnmount(): void
	{
		this.props.model.onChanged.unsubscribe(this._onModelChanged);
	}

	componentDidUpdate(prevProps: PropsWithModel): void
	{
		moveSubscription(prevProps.model.onChanged, this.props.model.onChanged, this._onModelChanged);
	}

	private _onModelChanged(model: IValidatedTextModel): void
	{
		this.forceUpdate();
	}

	private _onUiChanged(text: string): void
	{
		this.props.model.setText(text);
	}

	private readonly _ref: RefObject<ValidatedLineEdit>;
}


export { ValidatedLineEdit, ValidatedLineEditWithModel };
export type { InputType };

