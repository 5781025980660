import { IBackend } from './IBackend';
import { ResourceProvider } from './ResourceProvider';
import { Translator } from './Translator';


class Singletons
{
	constructor(backend: IBackend)
	{
		this._translator = new Translator('de', backend);
		this._resourceProvider = new ResourceProvider(backend);
	}

	get translator()
	{
		return this._translator;
	}

	get resourceProvider()
	{
		return this._resourceProvider;
	}

	private _translator: Translator;
	private _resourceProvider: ResourceProvider;
}


export { Singletons };
