import { Asserter } from '../common/Asserter';
import { ICampRegistrationClient } from '../common/CampRegistrationClient';
import { Nullable } from '../common/Optional';
import { IRegistrationClient } from '../common/RegistrationClient';
import { RegistrationIdGenerator } from '../common/RegistrationIdGenerator';
import { ResourceProvider } from '../common/ResourceProvider';
import { DummyValidator, EMailValidator, NameValidator, NotNullValidator, VereinsValidator } from '../common/Validators';
import { CampRegistrationData } from '../data/CampRegistrationData';
import { RegistrationFormData, ShortFormData } from '../data/RegistrationFormData';
import { CampBetreuerModel } from './CampBetreuerModel';
import { CampCompetitorModel } from './CampCompetitorModel';
import { ComputedFlagModel } from './ComputedFlagModel';
import { DateValidatorWhenPersisted } from './DateValidatorWhenPersisted';
import { Event } from './Event';
import { FlagModel } from './FlagModel';
import { ICampBetreuerModel } from './ICampBetreuerModel';
import { ICampCompetitorModel } from './ICampCompetitorModel';
import { ICampRegistrationModel } from './ICampRegistrationModel';
import { IEvent } from './IEvent';
import { IFlagModel } from './IFlagModel';
import { IObservableCollection } from './IObservableCollection';
import { INationChoiceModel, IRegistrationModel } from './IRegistrationModel';
import { IValidatedTextModel } from './IValidatedTextModel';
import { ObservableCollection } from './ObservableCollection';
import { RegistrationModel } from './RegistrationModel';
import { GenericValidatedChoiceModel } from './ValidatedChoiceModel';
import { ValidatedTextModel } from './ValidatedTextModel';
import { WasChangedModel } from './WasChangedModel';


/**
 * Dient einfach nur dazu, eine CampMeldung einfach laden zu können.
 */
class Loader
{
	async load(campRegistrationClient: ICampRegistrationClient, registrationClient: IRegistrationClient, regId: string, email: Nullable<string> = null): Promise<ICampRegistrationModel>
	{
		const formData = await ResourceProvider.instance().formData;
		const registrationData = await campRegistrationClient.fetch(regId, email);
		return new CampRegistrationModel(campRegistrationClient, registrationClient, formData, registrationData);
	}
}


//==============================================================================


class CampRegistrationModel implements ICampRegistrationModel
{
	static get loader(): Loader
	{
		return _loader;
	}

	static createEmpty(campRegistrationClient: ICampRegistrationClient, registrationClient: IRegistrationClient, formData: RegistrationFormData): CampRegistrationModel
	{
		return new CampRegistrationModel(campRegistrationClient, registrationClient, formData);
	}

	/**
	 * Kann man hier nicht ohne den registrationClient leben?
	 * Den brauceh ich nur für preFill(). Dort könnte man ihn dann auch explizit übergeben.
	 */
	constructor(campRegistrationClient: ICampRegistrationClient, registrationClient: IRegistrationClient, formData: RegistrationFormData, registration?: CampRegistrationData)
	{
		this._getIsValid = this._getIsValid.bind(this);
		this._getIsPersisted = this._getIsPersisted.bind(this);
		this._onSubmodelChanged = this._onSubmodelChanged.bind(this);

		Asserter.assert(registration === undefined || registration.id !== undefined, 'must be set');

		this._campRegistrationClient = campRegistrationClient;
		this._registrationClient = registrationClient;
		this._formData = formData;

		const id = registration?.id ?? _idGenerator.nextId();
		this._id = new ValidatedTextModel(id, _dummyValidator);
		this._vorname = new ValidatedTextModel(registration?.meldender.vorname ?? '', _nameValidator);
		this._vorname.onChanged.subscribe(this._onSubmodelChanged);
		this._nachname = new ValidatedTextModel(registration?.meldender.nachname ?? '', _nameValidator);
		this._nachname.onChanged.subscribe(this._onSubmodelChanged);
		this._email = new ValidatedTextModel(registration?.meldender.email ?? '', _emailValidator);
		this._email.onChanged.subscribe(this._onSubmodelChanged);
		this._verein = new ValidatedTextModel(registration?.verein ?? '', _vereinsValidator);
		this._verein.onChanged.subscribe(this._onSubmodelChanged);
		const nationChoices = [null, ...formData.nations];
		this._nation = new GenericValidatedChoiceModel(nationChoices, this._findNation(registration?.nation ?? null), _nationValidator);
		this._nation.onChanged.subscribe(this._onSubmodelChanged);

		this._eingegangen = new ValidatedTextModel(registration?.eingegangen ?? '', new DateValidatorWhenPersisted(this._id));
		this._eingegangen.onChanged.subscribe(this._onSubmodelChanged);
		this._isConfirmed = new FlagModel(registration?.confirmed ?? false);
		this._isConfirmed.onChanged.subscribe(this._onSubmodelChanged);
		this._competitors = new ObservableCollection<ICampCompetitorModel>(registration?.competitors.map(c => new CampCompetitorModel(formData.age_groups, c)) ?? []);
		if (this._competitors.items.length === 0)
			this.addCompetitor()
		this._competitors.onChanged.subscribe(this._onSubmodelChanged);
		this._betreuer = new ObservableCollection<ICampBetreuerModel>(registration?.betreuer.map(b => new CampBetreuerModel(formData, b)) ?? []);
		if (registration === undefined)
			this.addBetreuer();
		this._betreuer.onChanged.subscribe(this._onSubmodelChanged);

		this._isValid = new ComputedFlagModel<CampRegistrationModel>(this._getIsValid, this);
		this._isPersisted = new ComputedFlagModel<CampRegistrationModel>(this._getIsPersisted, this);

		this._wasChanged = new WasChangedModel(this);
		// Zirkelschluss
		this._wasChanged.onChanged.subscribe(this._onSubmodelChanged);
	}

	get onChanged(): IEvent<this>                                  { return this._onChanged; };

	get id(): IValidatedTextModel                                  { return this._id; }
	get vorname(): IValidatedTextModel                             { return this._vorname; }
	get nachname(): IValidatedTextModel                            { return this._nachname; }
	get email(): IValidatedTextModel                               { return this._email; }
	get verein(): IValidatedTextModel                              { return this._verein; }
	get nation(): INationChoiceModel                               { return this._nation; }
	get eingegangen(): IValidatedTextModel                         { return this._eingegangen; }
	get isConfirmed(): IFlagModel                                  { return this._isConfirmed; }
	get competitors(): IObservableCollection<ICampCompetitorModel> { return this._competitors; }
	get betreuer(): IObservableCollection<ICampBetreuerModel>      { return this._betreuer; }

	get isValid(): IFlagModel                                      { return this._isValid; }
	get isPersisted(): IFlagModel                                  { return this._isPersisted; }

	get wasChanged(): IFlagModel                                   { return this._wasChanged; }

	clone(): CampRegistrationModel
	{
		const copy = new CampRegistrationModel(this._campRegistrationClient, this._registrationClient, this._formData);

		copy.vorname.setText(this.vorname.text);
		copy.nachname.setText(this.nachname.text);
		copy.email.setText(this.email.text);
		copy.verein.setText(this.verein.text);
		copy.nation.setSelected(this.nation.selected);
		copy.eingegangen.setText(this.eingegangen.text);

		copy.competitors.reset(this.competitors.items.map(c => c.clone()));
		copy.betreuer.reset(this.betreuer.items.map(b => b.clone()));

		return copy;
	}

	/**
	 * In dem Fall, wo die Meldung nicht geladen werden konnte, wird eine Exception geschmissen.
	 * Die muss vom Aufrufer behandelt werden.
	 */
	async preFill(regId: string, email: string): Promise<void>
	{
		let registration: IRegistrationModel;

		registration = await RegistrationModel.loader.load(this._registrationClient, regId, email);

		this._onChanged.collectWhile(() => {
			this._vorname.setText(registration.vorname.text);
			this._nachname.setText(registration.nachname.text);
			// E-Mail nicht vorausfüllen, denn evtl. soll nicht dieselbe E-Mail-Adresse verwendet werden, wie bei der Turnier-Meldung.
			// this._email.setText(registration.email.text);
			this._verein.setText(registration.verein.text);
			this._nation.setSelected(registration.nation.selected);

			this._competitors.reset(registration.competitors.items.map(c => CampCompetitorModel.create(this._formData.age_groups, c)));
		});
	}

	async save(): Promise<void>
	{
		Asserter.assert(this.isValid.value === true, 'not allowed when invalid');

		let registrationData: CampRegistrationData;
		if (this.isPersisted.value === true)
			registrationData = await this._campRegistrationClient.update(this.toJSON());
		else
			registrationData = await this._campRegistrationClient.post(this.toJSON());

		this._refresh(registrationData);
	}

	async refresh(): Promise<void>
	{
		Asserter.assert(this.isPersisted.value === true, 'can only refresh a persisted registration');

		const registrationData = await this._campRegistrationClient.fetch(this._id.text, null);
		this._refresh(registrationData);
	}

	async forceConfirm(): Promise<void>
	{
		Asserter.assert(this.isPersisted.value === true, 'only allowed when persisted');
		await this._campRegistrationClient.confirm(this._id.text, null, true);
		this._isConfirmed.setValue(true);
	}

	toJSON(): CampRegistrationData
	{
		Asserter.assert(this.isValid.value === true, 'not allowed when invalid');

		const id = this.isPersisted.value === true ? this._id.text : undefined;
		const eingegangen = this._eingegangen.text.length > 0 ? this._eingegangen.text : undefined;

		return {
			id,
			verein: this._verein.text,
			nation: this._nation.selected!.kurzform,
			eingegangen,
			confirmed: this._isConfirmed.value,
			meldender: {
				vorname: this._vorname.text,
				nachname: this._nachname.text,
				email: this._email.text
			},
			competitors: this._competitors.items.map(c => c.toJSON()),
			betreuer: this._betreuer.items.map(b => b.toJSON())
		};
	}

	addCompetitor(): void
	{
		this._competitors.add(new CampCompetitorModel(this._formData.age_groups))
	}

	removeCompetitor(competitor: ICampCompetitorModel): void
	{
		this._competitors.remove(competitor);
	}

	addBetreuer(): void
	{
		this._betreuer.add(new CampBetreuerModel(this._formData));
	}

	removeBetreuer(betreuer: ICampBetreuerModel): void
	{
		this._betreuer.remove(betreuer);
	}

	private _getIsValid(): boolean
	{
		return this._vorname.isValid()
			&& this._nachname.isValid()
			&& this._email.isValid()
			&& this._verein.isValid()
			&& this._nation.isValid()
			&& this._eingegangen.isValid()
			&& this._competitors.items.every(c => c.isValid.value)
			&& this._betreuer.items.every(b => b.isValid.value);
	}

	private _getIsPersisted(): boolean
	{
		return RegistrationIdGenerator.isPermanentId(this._id.text);
	}

	private _refresh(data: CampRegistrationData): void
	{
		Asserter.assert(data.id !== undefined, 'only allowed with persisted data');

		this._onChanged.collectWhile(() => {
			this._id.setText(data.id!);
			this._verein.setText(data.verein);
			this._nation.setSelected(this._findNation(data.nation));
			this._eingegangen.setText(data.eingegangen ?? '');
			this._isConfirmed.setValue(data.confirmed);
			this._vorname.setText(data.meldender.vorname);
			this._nachname.setText(data.meldender.nachname);
			this._email.setText(data.meldender.email);
			this._competitors.reset(data.competitors.map(c => new CampCompetitorModel(this._formData.age_groups, c)));
			this._betreuer.reset(data.betreuer.map(b => new CampBetreuerModel(this._formData, b)));
		});

		this._wasChanged.reset();
	}

	private _onSubmodelChanged()
	{
		this._notifyChange();
	}

	private _notifyChange()
	{
		this._onChanged.notify(this, undefined);
	}

	private _findNation(nation: Nullable<string>): Nullable<ShortFormData>
	{
		if (nation === null)
			return null;

		const found = this._formData.nations.find(n => n.kurzform === nation);
		Asserter.assert(found !== undefined, 'inconsistency');
		return found;
	}

	private readonly _campRegistrationClient: ICampRegistrationClient;
	private readonly _registrationClient: IRegistrationClient;
	private readonly _formData: RegistrationFormData;
	private readonly _id: ValidatedTextModel;
	private readonly _vorname: ValidatedTextModel;
	private readonly _nachname: ValidatedTextModel;
	private readonly _email: ValidatedTextModel;
	private readonly _verein: ValidatedTextModel;
	private readonly _nation: GenericValidatedChoiceModel<Nullable<ShortFormData>>;
	private readonly _eingegangen: ValidatedTextModel;
	private readonly _isConfirmed: FlagModel;
	private readonly _competitors: ObservableCollection<ICampCompetitorModel>;
	private readonly _betreuer: ObservableCollection<ICampBetreuerModel>;
	private readonly _isValid: ComputedFlagModel<CampRegistrationModel>;
	private readonly _isPersisted: ComputedFlagModel<CampRegistrationModel>;
	private readonly _onChanged = new Event<this>();
	private readonly _wasChanged: WasChangedModel<this>;
}


//==============================================================================


const _loader = new Loader();

const _dummyValidator = new DummyValidator();
const _nameValidator = new NameValidator();
const _vereinsValidator = new VereinsValidator();
const _emailValidator = new EMailValidator();
const _nationValidator = new NotNullValidator<ShortFormData>();

const _idGenerator = new RegistrationIdGenerator();


export { CampRegistrationModel };
