import { PureComponent, ReactNode } from 'react';
import { Overwrite } from '../common/Overwrite';
import { ElementOrRenderProp, render as renderElementOrProp } from '../common/RenderProp';
import { moveSubscription } from '../models/IEvent';
import { IFlagModel } from '../models/IFlagModel';


type Props = {
	isOn: boolean;
	contentWhenOn: ElementOrRenderProp;
	contentWhenOff: ElementOrRenderProp;
};


/**
 * Abhängig davon, ob isOn true oder false ist, wird entweder contentWhenOn
 * oder contentWhenOff gerendert.
 */
class ContentSwitch extends PureComponent<Props>
{
	render(): ReactNode
	{
		const content = this.props.isOn ? this.props.contentWhenOn : this.props.contentWhenOff;
		return renderElementOrProp(content);
	}
}


//==============================================================================


type PropsWithModel = Overwrite<Props, {
	isOn: IFlagModel;
}>;


class ContentSwitchWithModel extends PureComponent<PropsWithModel>
{
	constructor(props: PropsWithModel)
	{
		super(props);

		this._onModelChanged = this._onModelChanged.bind(this);
	}

	render(): ReactNode
	{
		return <ContentSwitch {...this.props} isOn={this.props.isOn.value} />;
	}

	componentDidMount(): void
	{
		this.props.isOn.onChanged.subscribe(this._onModelChanged);
	}

	componentWillUnmount(): void
	{
		this.props.isOn.onChanged.unsubscribe(this._onModelChanged);
	}

	componentDidUpdate(prevProps: PropsWithModel): void
	{
		moveSubscription(prevProps.isOn.onChanged, this.props.isOn.onChanged, this._onModelChanged);
	}

	private _onModelChanged(flagModel: IFlagModel): void
	{
		this.forceUpdate();
	}
}


export { ContentSwitch, ContentSwitchWithModel };
