import { PureComponent, ReactNode } from 'react';


class NotFoundRoute extends PureComponent
{
	public render(): ReactNode
	{
		return (
			<main className="text-center my-4">
				<h1>404 Not Found</h1>
			</main>
		);
	}
}


export { NotFoundRoute };
