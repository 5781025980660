import { PureComponent } from 'react';
import { LoginForm } from '../components/LoginForm';
import { AppUserModel } from '../models/AppUserModel';


type Props = {
	user: AppUserModel;
};


class LoginRoute extends PureComponent<Props>
{
	public render()
	{
		return <LoginForm user={this.props.user} />;
	}
}


export { LoginRoute };
