import { Event } from './Event';
import { IEvent } from './IEvent';
import { IModel } from './IModel';


/**
 * Eine Sammlung von Models.
 * Sie dient im Prinzip nur dazu, die onChanged-Events der einzelnen Models
 * im onChanged-Event der Collection zu aggregieren.
 * So muss man als Benutzer nicht jedes einzelne Model überwachen sondern es reicht,
 * die Collection zu überwachen.
 * Das kann z.B. bei einem Computed-Model sinnvoll sein, das von mehr als einem Model abhängt.
 */
class ModelCollection implements IModel
{
	constructor(models: IModel[])
	{
		this._onSubModelChanged = this._onSubModelChanged.bind(this);

		this._models = [...models];
		this._subscribe();
	}

	/**
	 * Implementiert IModel.
	 * Über dieses Event bekommt man mit, dass ich ein Model der Collection geändert hat.
	 * weiß dann aber nicht, welches Model das konkret war.
	 * Wenn man diese Information benötigt, muss man stattdessen das onConcreteChanged-Event verwenden.
	 */
	get onChanged(): IEvent<this>
	{
		return this._onChanged;
	}

	/**
	 * Das zweite Argument ist konkret das Model der Collection, das sich geändert hat.
	 */
	get onConcreteChanged(): IEvent<this, IModel>
	{
		return this._onConcreteChanged;
	}

	setModels(models: IModel[])
	{
		this._unsubscribe();
		this._models = [...models];
		this._subscribe();
	}

	private _subscribe(): void
	{
		this._models.forEach(m => m.onChanged.subscribe(this._onSubModelChanged));
	}

	private _unsubscribe(): void
	{
		this._models.forEach(m => m.onChanged.unsubscribe(this._onSubModelChanged));
	}

	private _onSubModelChanged(model: IModel): void
	{
		this._onChanged.notify(this, undefined);
		this._onConcreteChanged.notify(this, model);
	}

	private readonly _onChanged = new Event<this>();
	private readonly _onConcreteChanged = new Event<this, IModel>();
	private _models: IModel[];
}


export { ModelCollection };
