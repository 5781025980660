import { PureComponent } from 'react';
import { OneToOneMapping } from '../common/OneToOneMapping';
import { Translator } from '../common/Translator';
import { DummyValidator } from '../common/Validators';
import { IValidatedChoiceModel } from '../models/IValidatedChoiceModel';
import { ValidatedChoiceModel } from '../models/ValidatedChoiceModel';
import { ValidatedComboboxWithModel } from './ValidatedCombobox';


type Props = {
	translator: Translator
}


class LanguageSelector extends PureComponent<Props>
{
	constructor(props: Props)
	{
		super(props);

		this._onLangSelectionChanged = this._onLangSelectionChanged.bind(this);

		const currentLang = this._displayMapping.toTarget(this.props.translator.currentLanguage);
		this._langChoices = new ValidatedChoiceModel(
			['Deutsch', 'English'],
			currentLang,
			new DummyValidator()
		);
	}

	render()
	{
		return <ValidatedComboboxWithModel model={this._langChoices} />
	}

	componentDidMount()
	{
		this._langChoices.onChanged.subscribe(this._onLangSelectionChanged);
	}

	componentWillUnmount()
	{
		this._langChoices.onChanged.unsubscribe(this._onLangSelectionChanged);
	}

	private _onLangSelectionChanged(model: IValidatedChoiceModel)
	{
		const selected = this._displayMapping.toSource(this._langChoices.selected)
		this.props.translator.changeLanguage(selected);
	}

	private _langChoices: ValidatedChoiceModel;
	private _displayMapping = new OneToOneMapping([
		['de', 'Deutsch'],
		['en', 'English'],
	]);
}


export { LanguageSelector };
