import { PureComponent, ReactNode } from 'react';
import { Asserter } from '../common/Asserter';
import { FetchGuard } from '../common/FetchGuard';
import { IFilesClient } from '../common/FilesClient';
import { Nullable } from '../common/Optional';
import { BusyWithModel } from '../components/Busy';
import { FileUploadForm } from '../components/FileUploadForm';
import { FileUploadModel } from '../models/FileUploadModel';


type Props = {
	filesClient: IFilesClient;
}


type State = {
	fileUploadModel: Nullable<FileUploadModel>;
}


/**
 * Die Route zum Anlegen und Updaten von FileUploads.
 */
class FileUploadRoute extends PureComponent<Props, State>
{
	constructor(props: Props)
	{
		super(props);

		this.state = {
			fileUploadModel: null
		};

		this._renderContent = this._renderContent.bind(this);
	}

	render(): ReactNode
	{
		return <BusyWithModel isBusy={this._fetchGuard.isBusy} render={this._renderContent} className="my-4" />;
	}

	componentDidMount(): void
	{
		this._fetchGuard.fetch(() => this._fetchFileUploads());
	}

	private _renderContent(): ReactNode
	{
		Asserter.assert(this.state.fileUploadModel !== null, 'must not be called when busy');

		return <FileUploadForm model={this.state.fileUploadModel} />;
	}

	private async _fetchFileUploads(): Promise<void>
	{
		const data = await this.props.filesClient.fetchAll();
		const model = new FileUploadModel(this.props.filesClient, data);
		this.setState({ fileUploadModel: model });
	}

	private readonly _fetchGuard = new FetchGuard();
}


export { FileUploadRoute };
