import { PureComponent, ReactNode } from 'react';
import { Asserter } from '../common/Asserter';
import { TR, Translator } from '../common/Translator';
import { CampPreFillModel } from '../models/CampPreFillModel';
import { ICampRegistrationModel } from '../models/ICampRegistrationModel';
import { IValidatedTextModel } from '../models/IValidatedTextModel';
import { IndicatorModel } from '../models/IndicatorModel';
import { ButtonWithModel } from './Button';
import { Indicator } from './Indicator';
import { Label } from './Label';
import { Row } from './Row';
import { ValidatedLineEditWithModel } from './ValidatedLineEdit';


type Props = {
	registration: ICampRegistrationModel;
};


class CampPreFillForm extends PureComponent<Props>
{
	constructor(props: Props)
	{
		super(props);

		this._onSubmit = this._onSubmit.bind(this);
		this._preFill = this._preFill.bind(this);
		this._onLanguageChanged = this._onLanguageChanged.bind(this);
	}

	render(): ReactNode
	{
		const hint = (
			<div className="callout-warning rounded bg-light p-2 mb-3" dangerouslySetInnerHTML={{ __html: TR('CampRegistrationForm::Hinweis Prefill')}} />
		);

		return (
			<div className="card border-secondary my-3">
				<div className='card-header'>{TR('CampRegistrationForm::Formular Vorausfüllen')}</div>
				<div className="card-body">
					{hint}

					<Row className="mb-1">
						<div className="col-md-6">
							{_renderLineEdit(`${TR('CampRegistrationForm::ID')}:`, this._model.id, TR('CampRegistrationForm::Beispiel-ID'))}
						</div>
					</Row>
					<Row className="mb-1">
						<div className="col-md-6">
							{_renderLineEdit(`${TR('CampRegistrationForm::E-Mail')}:`, this._model.email)}
						</div>
					</Row>

					<ButtonWithModel className='btn btn-outline-secondary' onClicked={this._onSubmit} isEnabled={this._model.isValid}>
						{TR('CampRegistrationForm::Vorausfüllen')}
					</ButtonWithModel>

					<Indicator model={this._indicatorModel} className="mt-3" />
				</div>
			</div>
		);
	}

	componentDidMount(): void
	{
		Translator.instance().languageChanged.subscribe(this._onLanguageChanged);
	}

	componentWillUnmount(): void
	{
		Translator.instance().languageChanged.unsubscribe(this._onLanguageChanged);
	}

	private _onSubmit(): Promise<void>
	{
		Asserter.assert(this._model.isValid.value, 'May only be called when valid');

		return this._indicatorModel.run(this._preFill, this._prefillErrorText);
	}

	private _preFill(): Promise<void>
	{
		Asserter.assert(this._model.isValid.value, 'May only be called when valid');

		return this.props.registration.preFill(this._model.id.text, this._model.email.text);
	}

	private _onLanguageChanged(translator: Translator): void
	{
		this.forceUpdate();

		if (this._indicatorModel.isShown.value)
			this._indicatorModel.setError(this._prefillErrorText);
	}

	private get _prefillErrorText(): string
	{
		return TR('CampRegistrationForm::Fehler beim Vorausfüllen');
	}

	private readonly _model: CampPreFillModel = new CampPreFillModel();
	private readonly _indicatorModel = new IndicatorModel();
}


//==============================================================================


function _renderLineEdit(label: string, model: IValidatedTextModel, placeholder?: string): ReactNode
{
	return (
		<Row className="align-items-center">
			<Label label={label} className="col-4" />
			<div className="col">
				<ValidatedLineEditWithModel model={model} placeholder={placeholder} />
			</div>
		</Row>
	);
}

export { CampPreFillForm };
