import { Asserter } from '../common/Asserter';
import { Nullable } from '../common/Optional';
import { isoStringToDate, stringToInt } from '../common/utils';


type NewstickerEntryData = {
	id: Nullable<string>;
	dateTime: Nullable<Date>;
	text: string;
};


type NewstickerData = NewstickerEntryData[];


//==============================================================================


type NewstickerEntryServerData =
{
	id: Nullable<number>;
	date_time: string; // ISO, z.B. '2022-07-07 14:48Z' oder '2022-07-07T14:48Z'
	text: string;
}

type NewstickerServerData = NewstickerEntryServerData[];


//==============================================================================


class NewstickerDataConverter
{
	static fromServer(serverData: NewstickerServerData): NewstickerData
	{
		return serverData.map(e => NewstickerDataConverter.entryFromServer(e))
	}

	static toServer(data: NewstickerData): NewstickerServerData
	{
		return data.map(e => NewstickerDataConverter.entryToServer(e));
	}

	static entryFromServer(serverData: NewstickerEntryServerData): NewstickerEntryData
	{
		Asserter.assert(serverData.id !== null, 'data from server must have an id');

		return {
			id: serverData.id.toString(),
			dateTime: isoStringToDate(serverData.date_time),
			text: serverData.text
		};
	}

	static entryToServer(data: NewstickerEntryData): NewstickerEntryServerData
	{
		Asserter.assert(data.dateTime !== null, 'data must have a date');

		let id: Nullable<number> = null;
		if (data.id !== null)
			id = stringToInt(data.id);

		return {
			id: id,
			date_time: _dateToString(data.dateTime),
			text: data.text
		};
	}
}


//==============================================================================


function _dateToString(date: Date): string
{
	return date.toISOString().slice(0, 16) + 'Z';
}


export type { NewstickerData, NewstickerEntryData, NewstickerServerData, NewstickerEntryServerData };
export { NewstickerDataConverter };
