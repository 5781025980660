import { PureComponent, ReactNode } from 'react';
import Container from 'react-bootstrap/Container';
import { moveSubscription } from '../models/IEvent';
import { IFlagModel } from '../models/IFlagModel';
import { IndicatorModel } from '../models/IndicatorModel';
import { NewstickerEntryModel } from '../models/NewstickerEntryModel';
import { NewstickerModel } from '../models/NewstickerModel';
import { Dropdown } from './Dropdown';
import { DropdownItem } from './DropdownItem';
import { Indicator } from './Indicator';
import { NewstickerEntryForm } from './NewstickerEntryForm';
import { Row } from './Row';


type Props = {
	model: NewstickerModel;
}


class NewstickerForm extends PureComponent<Props>
{
	constructor(props: Props)
	{
		super(props);

		this._onCreateClicked = this._onCreateClicked.bind(this);
		this._onSaveAllClicked = this._onSaveAllClicked.bind(this);
		this._onRefreshAllClicked = this._onRefreshAllClicked.bind(this);
		this._onDelete = this._onDelete.bind(this);
		this._onChanged = this._onChanged.bind(this);
	}

	render(): ReactNode
	{
		const entries = this.props.model.entries.items.map(e => {
			const key = e.id.text;
			return <NewstickerEntryForm key={key} entry={e} onDelete={this._onDelete} />
		});

		return (
			<>
				<Container fluid="md" className="my-3">
					<Row className="mb-3">
						<div className="col-12 d-flex flex-row-reverse">
							<Dropdown label="Aktion" iconSize="1.3em">
								{this._renderButton('Neuer Eintrag', this._onCreateClicked)}
								{this._renderButton('Alle Speichern', this._onSaveAllClicked, this.props.model.isValid)}
								{this._renderButton('Refresh', this._onRefreshAllClicked)}
							</Dropdown>
						</div>
					</Row>
					<Indicator model={this._indicatorModel} />
				</Container>
				{entries}
			</>
		);
	}

	componentDidMount(): void
	{
		this.props.model.entries.onChanged.subscribe(this._onChanged);
	}

	componentWillUnmount(): void
	{
		this.props.model.entries.onChanged.unsubscribe(this._onChanged);
	}

	componentDidUpdate(prevProps: Props): void
	{
		moveSubscription(prevProps.model.entries.onChanged, this.props.model.entries.onChanged, this._onChanged);
	}

	private _renderButton(label: string, handler: () => void, isEnabled?: IFlagModel): ReactNode
	{
		return <DropdownItem label={label} onClicked={handler} isEnabled={isEnabled} />;
	}

	private _onCreateClicked(): void
	{
		this.props.model.create();
	}

	private _onSaveAllClicked(): Promise<void>
	{
		return this._indicatorModel.run(
			() => this.props.model.save(),
			'Fehler beim Speichern der Einträge',
			'Alle Einträg erfolgreich gespeichert'
		);
	}

	private _onRefreshAllClicked(): Promise<void>
	{
		return this._indicatorModel.run(
			() => this.props.model.refresh(),
			'Fehler beim Aktualisieren der Einträge',
			'Alle Einträge erfolgreich aktualisiert'
		);
	}

	private _onDelete(entry: NewstickerEntryModel): Promise<void>
	{
		return this._indicatorModel.run(
			() => this.props.model.remove(entry),
			'Fehler beim Löschen des Eintrags',
			'Eintrag erfolgreich gelöscht'
		);
	}

	private _onChanged(): void
	{
		this.forceUpdate();
	}

	private readonly _indicatorModel = new IndicatorModel();
}


export { NewstickerForm };
