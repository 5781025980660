import { Asserter } from '../common/Asserter';
import { IBackend } from '../common/IBackend';
import { dateToIsoTz } from '../common/utils';
import { EMailValidator, FunctionValidator } from '../common/Validators';
import { MailData } from '../data/MailData';
import { ComputedFlagModel } from './ComputedFlagModel';
import { DateTimeModel } from './DateTimeModel';
import { Event } from './Event';
import { FileModel } from './FileModel';
import { FlagModel } from './FlagModel';
import { IEvent } from './IEvent';
import { IFlagModel } from './IFlagModel';
import { IModel } from './IModel';
import { IValidatedTextModel } from './IValidatedTextModel';
import { ValidatedTextModel } from './ValidatedTextModel';


/**
 * Repräsentiert das Modell zum Versenden von E-Mails.
 * Das Fronend bietet ein Formular an (für User), über das man E-Mails an einen Verteiler
 * schicken kann. Das Modell repräsentiert den Inhalt des Formulars.
 * Und über das Modell werden die Daten entsprechend an das Backend geschickt,
 * welches dann die E-Mail rendert und rausschickt.
 */
class MailModel implements IModel
{
	constructor(backend: IBackend)
	{
		this._onSubmodelChanged = this._onSubmodelChanged.bind(this);
		this._getIsValid = this._getIsValid.bind(this);

		this._backend = backend;

		this._to.onChanged.subscribe(this._onSubmodelChanged);
		this._toGermans.onChanged.subscribe(this._onSubmodelChanged);
		this._toInternationals.onChanged.subscribe(this._onSubmodelChanged);
		this._toRegistered.onChanged.subscribe(this._onSubmodelChanged);
		this._subject.onChanged.subscribe(this._onSubmodelChanged);
		this._body.onChanged.subscribe(this._onSubmodelChanged);
		this._attachment.onChanged.subscribe(this._onSubmodelChanged);
		this._withSendDate.onChanged.subscribe(this._onSubmodelChanged);
		this._sendDate.onChanged.subscribe(this._onSubmodelChanged);

		this._isValid = new ComputedFlagModel<MailModel>(this._getIsValid, this);
	}

	get onChanged(): IEvent<this>      { return this._onChanged; }

	get to(): IValidatedTextModel      { return this._to; }
	get toGermans(): IFlagModel        { return this._toGermans; }
	get toInternationals(): IFlagModel { return this._toInternationals; }
	get toRegistered(): IFlagModel     { return this._toRegistered; }
	get subject(): IValidatedTextModel { return this._subject; }
	get body(): IValidatedTextModel    { return this._body; }
	get attachment(): FileModel        { return this._attachment; }
	get withSendDate(): IFlagModel     { return this._withSendDate; }
	get sendDate(): DateTimeModel      { return this._sendDate; }

	get isValid(): IFlagModel          { return this._isValid; }

	send(): Promise<void>
	{
		Asserter.assert(this.isValid.value, 'not allowed when invalid');

		const mailData: MailData = {
			to: this._to.text,
			toGermans: this._toGermans.value,
			toInternationals: this._toInternationals.value,
			toRegistered: this._toRegistered.value,
			subject: this._subject.text,
			body: this._body.text,
			attachment: this._attachment.file,
			sendDate: this._withSendDate.value ? dateToIsoTz(this._sendDate.value!) : undefined
		};

		return this._backend.sendMail(mailData);
	}

	private _onSubmodelChanged(): void
	{
		this._notifyChange();
	}

	private _notifyChange(): void
	{
		this._onChanged.notify(this, undefined);
	}

	private _getIsValid(): boolean
	{
		return this._to.isValid() === true
			&& this._subject.isValid() === true
			&& this._body.isValid() === true
			&& (!this._withSendDate.value === true || this._sendDate.isValid() === true);
	}

	private readonly _backend: IBackend;
	private readonly _to = new ValidatedTextModel('', new EMailValidator());
	private readonly _toGermans = new FlagModel(false);
	private readonly _toInternationals = new FlagModel(false);
	private readonly _toRegistered = new FlagModel(false);
	private readonly _subject = new ValidatedTextModel('', _notEmptyValidator);
	private readonly _body =  new ValidatedTextModel('', _notEmptyValidator);
	private readonly _attachment = new FileModel();
	private readonly _withSendDate = new FlagModel(false);
	private readonly _sendDate = new DateTimeModel(undefined);
	private readonly _isValid: ComputedFlagModel<MailModel>;
	private readonly _onChanged = new Event<this>();
}


const _notEmptyValidator = new FunctionValidator((userInput: string) => userInput.trim().length > 0);


export { MailModel };
