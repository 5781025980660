import { Asserter } from '../common/Asserter';
import { Nullable } from '../common/Optional';
import { stringToInt } from '../common/utils';
import { DummyValidator, NameValidator, NotNullValidator } from '../common/Validators';
import { CampBetreuerData } from '../data/CampBetreuerData';
import { RegistrationFormData } from '../data/RegistrationFormData';
import { ComputedFlagModel } from './ComputedFlagModel';
import { Event } from './Event';
import { ICampBetreuerChoiceModel, ICampBetreuerModel } from './ICampBetreuerModel';
import { IEvent } from './IEvent';
import { IFlagModel } from './IFlagModel';
import { IValidatedTextModel } from './IValidatedTextModel';
import { GenericValidatedChoiceModel } from './ValidatedChoiceModel';
import { ValidatedTextModel } from './ValidatedTextModel';


class CampBetreuerModel implements ICampBetreuerModel
{
	constructor(formData: RegistrationFormData, betreuer?: CampBetreuerData)
	{
		this._getIsValid = this._getIsValid.bind(this);
		this._onSubmodelChanged = this._onSubmodelChanged.bind(this);

		Asserter.assert(betreuer === undefined || betreuer.id !== undefined, 'must be set');

		this._formData = formData;
		const id = betreuer?.id ?? CampBetreuerModel._nextBetreuerId();
		this._id = new ValidatedTextModel(id.toString(), _dummyValidator);
		this._vorname = new ValidatedTextModel(betreuer?.vorname ?? '', _nameValidator);
		this._vorname.onChanged.subscribe(this._onSubmodelChanged);
		this._nachname = new ValidatedTextModel(betreuer?.nachname ?? '', _nameValidator);
		this._nachname.onChanged.subscribe(this._onSubmodelChanged);

		const rolleChoices = ([null] as Nullable<string>[]).concat(formData.rollen);
		this._rolle = new GenericValidatedChoiceModel(rolleChoices, betreuer?.rolle ?? null, _rolleValidator);
		this._rolle.onChanged.subscribe(this._onSubmodelChanged);

		this._isValid = new ComputedFlagModel(this._getIsValid, this);
	}

	get onChanged(): IEvent<this> { return this._onChanged; }

	get id(): IValidatedTextModel         { return this._id; }
	get vorname(): IValidatedTextModel    { return this._vorname; }
	get nachname(): IValidatedTextModel   { return this._nachname; }
	get rolle(): ICampBetreuerChoiceModel { return this._rolle; }
	get isValid(): IFlagModel             { return this._isValid; }

	clone(): CampBetreuerModel
	{
		const copy = new CampBetreuerModel(this._formData);

		copy.vorname.setText(this.vorname.text);
		copy.nachname.setText(this.nachname.text);
		copy.rolle.setSelected(this.rolle.selected);

		return copy;
	}

	toJSON(): CampBetreuerData
	{
		Asserter.assert(this.isValid.value, 'not allowed when invalid');

		const idAsInt = stringToInt(this._id.text);

		return {
			id: idAsInt > 0 ? idAsInt : undefined,
			vorname: this._vorname.text,
			nachname: this._nachname.text,
			rolle: this._rolle.selected!
		};
	}

	private _getIsValid(): boolean
	{
		return this._vorname.isValid()
			&& this._nachname.isValid()
			&& this._rolle.isValid();
	}

	private _onSubmodelChanged(): void
	{
		this._notifyChange();
	}

	private _notifyChange(): void
	{
		this._onChanged.notify(this, undefined);
	}

	private static _nextBetreuerId(): number
	{
		 return CampBetreuerModel._betreuerId--;
	}

	private static _betreuerId = -1;

	private _formData: RegistrationFormData;
	private _id: ValidatedTextModel;
	private _vorname: ValidatedTextModel;
	private _nachname: ValidatedTextModel;
	private _rolle: GenericValidatedChoiceModel<Nullable<string>>;
	private _isValid: ComputedFlagModel<this>;
	private _onChanged = new Event<this>();
}


//==============================================================================


const _dummyValidator = new DummyValidator();
const _nameValidator = new NameValidator();
const _rolleValidator = new NotNullValidator<string>();


export { CampBetreuerModel };
