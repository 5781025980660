import { ComponentClass, FunctionComponent } from 'react';
import { NavigateFunction, Params, useLocation, useNavigate, useParams, Location } from 'react-router-dom';


type RouterData = {
	location: Location;
	navigate: NavigateFunction;
	params: Readonly<Params<string>>;
}


// type PropsWithRouter<P> = P & { router: RouterData };

type PropsWithRouter_ = { router: RouterData };
type PropsWithoutRouter<P extends PropsWithRouter_> = Omit<P, 'router'>;

/**
 * An die Properties des Routers kommt man nur via Function-Components und Hooks.
 * Daher hier der Umweg über eine HOC.
 * Siehe https://reactrouterdotcom.fly.dev/docs/en/v6/faq#what-happened-to-withrouter-i-need-it
 *
 * Leider scheint es so zu sein, dass der Type-Parameter P nicht richtig
 * deduziert wird. Hier wird einfach der Type PropsWithRouter<P> selbst deduziert.
 * Daher muss man withRouter() mit explizitem Type-Parameter aufrufen.
 *
 * Ich hab jetzt noch mal einen neuen Anlauf genommen.
 * Jetzt klappt es mit der Deduzierung. Jedoch muss ich dafür in der Implementierung einen
 * expliziten Cast machen.
 */
// function withRouter<P>(Component: ComponentClass<PropsWithRouter<P>> | FunctionComponent<PropsWithRouter<P>>): FunctionComponent<P>
function withRouter<P extends PropsWithRouter_>(Component: ComponentClass<P> | FunctionComponent<P>): FunctionComponent<PropsWithoutRouter<P>>
{
	function ComponentWithRouterProp(props: PropsWithoutRouter<P>)
	{
		const routerData: RouterData = {
			location: useLocation(),
			navigate: useNavigate(),
			params: useParams(),
		};

		const propsWithRouter: P = { ...props, router: routerData } as P; // geht nur mit explizitem Cast, hmm...
		return <Component {...propsWithRouter} />;
	}
	return ComponentWithRouterProp;
}


export type { RouterData };
export { withRouter };
