import { PureComponent, ReactNode } from 'react';
import { Asserter } from '../common/Asserter';
import { FetchGuard } from '../common/FetchGuard';
import { INewstickerClient } from '../common/NewstickerClient';
import { Nullable } from '../common/Optional';
import { BusyWithModel } from '../components/Busy';
import { NewstickerForm } from '../components/NewstickerForm';
import { NewstickerModel } from '../models/NewstickerModel';


type Props = {
	newstickerClient: INewstickerClient;
}


type State = {
	newstickerModel: Nullable<NewstickerModel>;
}


/**
 * Die Route zum Editieren des Newstickers.
 */
class NewstickerRoute extends PureComponent<Props, State>
{
	constructor(props: Props)
	{
		super(props);

		this.state = {
			newstickerModel: null
		};

		this._renderContent = this._renderContent.bind(this);
	}

	render(): ReactNode
	{
		return <BusyWithModel isBusy={this._fetchGuard.isBusy} render={this._renderContent} className="my-4" />;
	}

	componentDidMount(): Promise<void>
	{
		return this._fetchGuard.fetch(() => this._fetchNewsticker());
	}

	private _renderContent(): ReactNode
	{
		Asserter.assert(this.state.newstickerModel !== null, 'must not be called when busy');

		return <NewstickerForm model={this.state.newstickerModel} />;
	}

	private async _fetchNewsticker(): Promise<void>
	{
		const data = await this.props.newstickerClient.fetchAll();
		const model = new NewstickerModel(this.props.newstickerClient, data);

		this.setState({
			newstickerModel: model
		});
	}

	private readonly _fetchGuard = new FetchGuard();
}

export { NewstickerRoute };
