import { PropsWithChildren, PureComponent } from 'react';


type Props = PropsWithChildren<{
	className?: string;
}>;


/**
 * Ich hatte versucht, shouldComponentUpate() selbst mit Hilfe von
 * arePropsEqual() zu implementieren. Das hat jedoch nicht hingehauen.
 * Vermutlich liegt es daran, dass Row children hat.
 * Row kann aber scheinbar eine PureComponent sein. Laut Doku macht
 * die jedoch auch nichts anderes als einen shallow-Vergleich der Props.
 * Hmm...
 */
class Row extends PureComponent<Props>
{
	public render()
	{
		let className = 'row';
		if (this.props.className !== undefined)
			className += ` ${this.props.className}`;

		return (
			<div className={className}>
				{this.props.children}
			</div>
		);
	}
}


export { Row };
