import { IGenericValidator } from '../common/IValidator';
import { GenericChoiceModel } from './ChoiceModel';
import { EventForwarder } from './EventForwarder';
import { IEvent } from './IEvent';
import { IGenericValidatedChoiceModel } from './IValidatedChoiceModel';


class GenericValidatedChoiceModel<TElement> implements IGenericValidatedChoiceModel<TElement>
{
	constructor(choices: Readonly<TElement[]>, selected: TElement, validator: IGenericValidator<TElement>)
	{
		this._choiceModel = new GenericChoiceModel(choices, selected);
		this._validator = validator;
		this._onChanged = new EventForwarder(this._choiceModel.onChanged, this);
	}

	get onChanged(): IEvent<this>
	{
		return this._onChanged.event;
	}

	get choices(): Readonly<TElement[]>
	{
		return this._choiceModel.choices;
	}

	setChoices(choices: Readonly<TElement[]>)
	{
		this._choiceModel.setChoices(choices);
	}

	get selected(): TElement
	{
		return this._choiceModel.selected;
	}

	setSelected(item: TElement)
	{
		this._choiceModel.setSelected(item);
	}

	isValid(): boolean
	{
		return this._validator.isValid(this.selected);
	}

	private readonly _choiceModel: GenericChoiceModel<TElement>;
	private readonly _validator: IGenericValidator<TElement>;
	private readonly _onChanged: EventForwarder<typeof this._choiceModel, this>;
}


/**
 * Man kann aktuell keinen ordentlich funktionierenden Type-Alias machen.
 * Folgendes funktioniert daher nicht:
 * ```
 * type ValidatedChoiceModel = GenericValidatedChoiceModel<string>;
 * ```
 *
 * Die Alternative ist Ableitung.
 * @see https://github.com/microsoft/TypeScript/issues/2552
 */
class ValidatedChoiceModel extends GenericValidatedChoiceModel<string>
{}


export { GenericValidatedChoiceModel, ValidatedChoiceModel };

