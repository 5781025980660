import { isoStringToDate } from '../common/utils';


/**
 * Wird verwendet, um Files zum Backend zu Senden als POST (store) bzw. PUT (update).
 * Die Daten werden dann aber als multipart/form-data codiert gesendet.
 */
type FileStorageData = {
	id?: number; // muss bei update gesetzt sein
	name: string;
	file: File;
};


//==============================================================================


type FileEntryData = {
	id: string;
	name: string;
	created: Date;
	updated: Date;
};


type FileData = FileEntryData[];


//==============================================================================


type FileEntryServerData = {
	id: number;
	name: string;
	created: string; // ATOM (in Laravel), z.B. 2022-10-23T12:16:10+02:00
	updated: string; // dito
};


type FileServerData = FileEntryServerData[];


//==============================================================================


class FileDataConverter
{
	static fromServer(serverData: FileServerData): FileData
	{
		return serverData.map(FileDataConverter.entryFromServer);
	}

	static entryFromServer(serverData: FileEntryServerData): FileEntryData
	{
		const converted: FileEntryData = {
			id: serverData.id.toString(),
			name: serverData.name,
			created: isoStringToDate(serverData.created),
			updated: isoStringToDate(serverData.updated)
		};

		return converted;
	}
}


export type { FileStorageData, FileEntryData, FileData, FileEntryServerData, FileServerData };
export { FileDataConverter };

