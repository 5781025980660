import { PureComponent, ReactNode } from 'react';
import { Translator } from '../common/Translator';
import { Label } from './Label';
import { LanguageSelector } from './LanguageSelector';
import { Row } from './Row';


/**
 * Ein Wrapper um den eigentlichen LanguageSelector, damit ich diese Component
 * in RegistrationRoute und CampRegistrationRoute wiederverwenden kann.
 */
class LanguageSelectorForm extends PureComponent
{
	render(): ReactNode
	{
		return (
			<div className="card border-secondary my-3">
				<div className="card-body">
					<Row>
						<Label label="Language" className="col-auto align-self-center" />
						<div className="col-auto">
							<LanguageSelector translator={Translator.instance()} />
						</div>
					</Row>
				</div>
			</div>
		);
	}
}


export { LanguageSelectorForm };
