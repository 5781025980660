import { IFilesClient } from '../common/FilesClient';
import { stringToInt } from '../common/utils';
import { FileData } from '../data/FileData';
import { Event } from './Event';
import { FileUploadEntryModel } from './FileUploadEntryModel';
import { IEvent } from './IEvent';
import { IModel } from './IModel';
import { IObservableCollection } from './IObservableCollection';
import { ObservableCollection } from './ObservableCollection';


/**
 * Model für die Liste aller FileUploads.
 */
class FileUploadModel implements IModel
{
	constructor(client: IFilesClient, data: FileData)
	{
		this._onSubmodelChanged = this._onSubmodelChanged.bind(this);

		this._client = client;
		this._entries = new ObservableCollection(_createEntries(client, data));
	}

	get onChanged(): IEvent<this>
	{
		return this._onChanged;
	}

	get entries(): IObservableCollection<FileUploadEntryModel>
	{
		return this._entries;
	}

	create(): FileUploadEntryModel
	{
		const newEntry = new FileUploadEntryModel(this._client);
		this._entries.add(newEntry);
		return newEntry;
	}

	async remove(entry: FileUploadEntryModel): Promise<void>
	{
		if (entry.isPersisted)
			await this._client.delete(stringToInt(entry.id.text));
		this._entries.remove(entry);
	}

	private _onSubmodelChanged()
	{
		this._onChanged.notify(this, undefined);
	}

	private readonly _client: IFilesClient;
	private readonly _onChanged = new Event<this>();
	private readonly _entries: ObservableCollection<FileUploadEntryModel>;
}


function _createEntries(client: IFilesClient, data: FileData): FileUploadEntryModel[]
{
	return data.map(e => new FileUploadEntryModel(client, e));
}


export { FileUploadModel };
