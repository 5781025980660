import { PureComponent, ReactNode } from 'react';
import { Asserter } from '../common/Asserter';
import { FetchGuard } from '../common/FetchGuard';
import { Nullable } from '../common/Optional';
import { IRegistrationClient } from '../common/RegistrationClient';
import { RegistrationDistributionData } from '../data/RegistrationDistributionData';
import { BusyWithModel } from './Busy';
import { CompetitorDistribution } from './CompetitorDistribution';


type Props = {
	distribution: RegistrationDistributionData;
};


/**
 * Die Verteilung der Gemeldeten für beide Altersklassen.
 */
class RegistrationDistribution extends PureComponent<Props>
{
	render(): ReactNode
	{
		return (
			<>
				<div className="container-md">
					<CompetitorDistribution data={this.props.distribution.U18} ageGroup="U18" />
					<CompetitorDistribution data={this.props.distribution.U21} ageGroup="U21" />
				</div>
			</>
		);
	}
}


//==============================================================================


type ConnectedProps = {
	client: IRegistrationClient;
};


type ConnectedState = {
	distribution: Nullable<RegistrationDistributionData>
};


class ConnectedRegistrationDistribution extends PureComponent<ConnectedProps, ConnectedState>
{
	constructor(props: ConnectedProps)
	{
		super(props);

		this.state = {
			distribution: null,
		};

		this._renderContent = this._renderContent.bind(this);
	}

	render(): ReactNode
	{
		return <BusyWithModel isBusy={this._fetchGuard.isBusy} render={this._renderContent} className="my-4" />;
	}

	componentDidMount(): Promise<void>
	{
		return this._fetchGuard.fetch(() => this._fetchDistribution());
	}

	private _renderContent(): ReactNode
	{
		Asserter.assert(this.state.distribution !== null, 'must not be called when busy');

		return <RegistrationDistribution distribution={this.state.distribution} />;
	}

	private async _fetchDistribution(): Promise<void>
	{
		const distribution = await this.props.client.distribution();
		this.setState({ distribution });
	}

	private readonly _fetchGuard = new FetchGuard();
}


export { ConnectedRegistrationDistribution as RegistrationDistribution };
