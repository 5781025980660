import { Asserter } from '../common/Asserter';
import { Event } from './Event';
import { IGenericChoiceModel } from './IChoiceModel';
import { IEvent } from './IEvent';


class GenericChoiceModel<TElement> implements IGenericChoiceModel<TElement>
{
	constructor(choices: Readonly<TElement[]>, selected: TElement)
	{
		Asserter.assert(choices.length > 0, 'need a choice');
		Asserter.assert(choices.indexOf(selected) !== -1, 'invalid choice');

		this._choices = [...choices];
		this._selected = selected;
	}

	get onChanged(): IEvent<this>
	{
		return this._onChanged;
	}

	get choices(): Readonly<TElement[]>
	{
		return this._choices;
	}

	/**
	 * In dem Fall, wo der aktuell selektierte Eintrag nicht mehr zur Auswahl steht,
	 * selektieren wir einfach den ersten Eintrag.
	 */
	setChoices(choices: Readonly<TElement[]>)
	{
		Asserter.assert(choices.length > 0, 'need a choice');
		if (choices.indexOf(this._selected) === -1)
			this._selected = choices[0];
		this._choices = [...choices];
		this._onChanged.notify(this, undefined);
	}

	get selected()
	{
		return this._selected;
	}

	setSelected(selected: TElement)
	{
		Asserter.assert(this._choices.indexOf(selected) !== -1, 'invalid choice');

		if (selected === this._selected)
			return;

		this._selected = selected;
		this._onChanged.notify(this, undefined);
	}

	private _choices: TElement[];
	private _selected: TElement;
	private _onChanged = new Event<this>();
}


/**
 * Man kann aktuell keinen ordentlich funktionierenden Type-Alias machen.
 * Folgendes funktioniert daher nicht:
 * ```
 * type ChoiceModel = GenericChoiceModel<string>;
 * ```
 *
 * Die Alternative ist Ableitung.
 * @see https://github.com/microsoft/TypeScript/issues/2552
 */
class ChoiceModel extends GenericChoiceModel<string>
{}


export { ChoiceModel, GenericChoiceModel };
