import { PureComponent, ReactNode } from 'react';
import { ElementOrRenderProp } from '../common/RenderProp';
import { IFlagModel } from '../models/IFlagModel';
import { ContentSwitchWithModel } from './ContentSwitch';
import { Spinner } from './Spinner';


type PropsWithModel = {
	isBusy: IFlagModel;
	render: ElementOrRenderProp;
	className?: string;
};


/**
 * Ein Container für beliebigen Content, der jedoch nur dann gerendert wird,
 * wenn isBusy == false. Wir verwenden hier nicht this.props.children sondern
 * ein render-prop, denn die Child-Components können üblicherweise erst initialisiert werden,
 * wenn isBusy == false.
 */
class BusyWithModel extends PureComponent<PropsWithModel>
{
	constructor(props: PropsWithModel)
	{
		super(props);

		this._renderSpinner = this._renderSpinner.bind(this);
	}

	render(): ReactNode
	{
		return (
			<ContentSwitchWithModel
				isOn={this.props.isBusy}
				contentWhenOn={this._renderSpinner}
				contentWhenOff={this.props.render}
			/>
		);
	}

	private _renderSpinner(): ReactNode
	{
		return _renderSpinner(this.props.className);
	}
}


//==============================================================================


function _renderSpinner(className?: string): ReactNode
{
	let classNames = 'd-flex justify-content-center ';
	if (className !== undefined)
		classNames += className;

	return (
		<div className={classNames}>
			<Spinner />
		</div>
	);
}


export { BusyWithModel };
